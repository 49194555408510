import React, { memo, useState } from 'react';
import { Tabs } from 'antd';
import Section3Secondary from '../tabs/secondary/Section3Secondary';
import Section3Primary from '../tabs/primary/Section3Primary';
import CheckboxCms from '../../../../components/checkbox-cms';
import { useTranslation } from 'react-i18next';

const CmsHomepageSection3 = ({ countryCodeExtension, translations, formSection3, handleInputChangeFormSection3, setHpImages, hpImages, hpImagesPreview, formSection3Errors, hpImagesErrors }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const [activeTabKey, setActiveTabKey] = useState('1');

  const onChangeTabSection3 = (key) => {
    setActiveTabKey(key);
  };

  const sectionProps = {
    formSection3,
    handleInputChangeFormSection3,
    setHpImages,
    hpImages,
    hpImagesPreview,
    formSection3Errors,
    hpImagesErrors,
    translations,
    activeTabKey,
  };
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSection3}>
        <TabPane key="1" tab="EN">
          <Section3Secondary {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab={countryCodeExtension?.toUpperCase()}>
          <Section3Primary {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName="section3_hide" isChecked={formSection3.hide} handleChange={handleInputChangeFormSection3} label={activeTabKey === '2' ? translations?.hideSection : 'Hide entire section on website'} tooltipText={activeTabKey === '2' ? translations?.hideSectionTooltip : 'Hide entire section on website'} />
    </>
  );
};
export default memo(CmsHomepageSection3);
