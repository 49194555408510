import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const SectionHeaderSecondary = ({ handleInputChangeFormSectionHeader, formSectionHeader, auImages, auImagesErrors, auImagesPreview, setAuImages, formSectionHeaderErrors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <p style={{ fontSize: '18px', fontWeight: '500' }}>Background Images</p>
          <div className="cms-form-head-inner" style={{ display: 'flex', gap: '30px', border: 'none', marginBottom: '0', paddingBottom: '20px' }}>
            <div>
              <div className="images-card-header">
                <span>Desktop</span>
                <span className="ml-6">
                  <TooltipDash acceptFormatText={true} />
                </span>
              </div>
              <div className="upload-wrapper">
                <ImageUpload auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="sectionHeaderImageSecondaryDesktop" />
              </div>
            </div>

            <div>
              <div className="images-card-header">
                <span>Tablet</span>
                <span className="ml-6">
                  <TooltipDash acceptFormatText={true} />
                </span>
              </div>
              <div className="upload-wrapper">
                <ImageUpload auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="sectionHeaderImageSecondaryTablet" />
              </div>
            </div>

            <div>
              <div className="images-card-header">
                <span>Mobile</span>
                <span className="ml-6">
                  <TooltipDash acceptFormatText={true} />
                </span>
              </div>
              <div className="upload-wrapper">
                <ImageUpload auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="sectionHeaderImageSecondaryMobile" />
              </div>
            </div>
          </div>
          <hr />
          <div style={{ paddingTop: '20px' }}>
            <p style={{ fontSize: '18px', fontWeight: '500' }}>
              Hero image{' '}
              <span className="ml-3">
                <TooltipDash acceptFormatText={true} />
              </span>
            </p>
            {/* <div className="images-card-header">
              <span>Desktop</span>
              <span className="ml-6">
                <TooltipDash acceptFormatText={true} />
              </span>
            </div> */}
            <div className="upload-wrapper">
              <ImageUpload auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="sectionHeroHeaderImageSecondary" />
            </div>
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*secondary_headMainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_headMainHeadingPart1">
            <b>Main heading part 1:</b>
          </label>
          <input id="secondary_headMainHeadingPart1" type="text" placeholder="Main heading part 1" name="secondary_headMainHeadingPart1" value={formSectionHeader.secondary.headMainHeadingPart1} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.secondary.headMainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_headMainHeadingPart1*/}
        {/*secondary_headMainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_headMainHeadingPart2">
            <b>Main heading part 2:</b>
          </label>
          <input id="secondary_headMainHeadingPart2" type="text" placeholder="Main heading part 2" name="secondary_headMainHeadingPart2" value={formSectionHeader.secondary.headMainHeadingPart2} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.secondary.headMainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_headMainHeadingPart2*/}
      </div>
    </>
  );
};
export default SectionHeaderSecondary;
