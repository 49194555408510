import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const SectionHeaderPrimary = ({ translations, activeTabKey, handleInputChangeFormSectionHeader, formSectionHeader, clImages, clImagesErrors, clImagesPreview, setClImages, formSectionHeaderErrors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="cms-form-head-inner" style={{ display: 'flex', gap: '30px', border: 'none', marginBottom: '0', paddingBottom: '0' }}>
            <div>
              <div className="images-card-header">
                <span>{translations?.desktop}</span>
                <span className="ml-6">
                  <TooltipDash acceptFormatText={true} />
                </span>
              </div>
              <div className="upload-wrapper">
                <ImageUpload translations={translations} activeTabKey={activeTabKey} clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="sectionHeaderImagePrimaryDesktop" />
              </div>
            </div>
            <div>
              <div className="images-card-header">
                <span>{translations?.tablet}</span>
                <span className="ml-6">
                  <TooltipDash acceptFormatText={true} />
                </span>
              </div>
              <div className="upload-wrapper">
                <ImageUpload translations={translations} activeTabKey={activeTabKey} clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="sectionHeaderImagePrimaryTablet" />
              </div>
            </div>
            <div>
              <div className="images-card-header">
                <span>{translations?.mobile}</span>
                <span className="ml-6">
                  <TooltipDash acceptFormatText={true} />
                </span>
              </div>
              <div className="upload-wrapper">
                <ImageUpload translations={translations} activeTabKey={activeTabKey} clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="sectionHeaderImagePrimaryMobile" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_headMainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_headMainHeadingPart1">
            <b>{translations?.mainHeadingLine} 1:</b>
          </label>
          <input id="primary_headMainHeadingPart1" type="text" placeholder={`${translations?.mainHeadingLine} 1`} name="primary_headMainHeadingPart1" value={formSectionHeader.primary.headMainHeadingPart1} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.primary.headMainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_headMainHeadingPart1*/}
        {/*primary_headMainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_headMainHeadingPart2">
            <b>{translations?.mainHeadingLine} 2:</b>
          </label>
          <input id="primary_headMainHeadingPart2" type="text" placeholder={`${translations?.mainHeadingLine} 2`} name="primary_headMainHeadingPart2" value={formSectionHeader.primary.headMainHeadingPart2} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.primary.headMainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_headMainHeadingPart2*/}
        {/*primary_headText*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_headText">
            <b>{translations?.text}:</b>
          </label>
          <input id="primary_headText" type="text" placeholder={`${translations?.text}`} name="primary_headText" value={formSectionHeader.primary.headText} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.primary.headText ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_headText*/}
      </div>
    </>
  );
};
export default SectionHeaderPrimary;
