import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const PrimaryLangSlideData = ({ translations, activeTabKey, handleInputChangeSlider, form, images, imagesPreview, setImages, formErrors, imagesErrors }) => {
  return (
    <div className="cms-form-flex">
      <div className="cms-form-flex-col">
        <p className="card-top">{translations?.sliderImages}:</p>
        <div className="cms-form-head-inner" style={{ display: 'flex', gap: '30px' }}>
          <div className="images-card-header">
            <span>{translations?.desktop}</span>
            <span className="ml-2">{/* <TooltipDash text={`${translations?.i} 1/1 (150x150)px`} acceptFormatText={true} /> */}</span>
            <div className="upload-wrapper mt-2">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} images={images} imagesPreview={imagesPreview} setImages={setImages} imagesErrors={imagesErrors} toStoreImg="desktopImagePrimary" />
            </div>
          </div>
          <div className="images-card-header">
            <span>{translations?.tablet}</span>
            <span className="ml-2">{/* <TooltipDash text="Icon aspect ratio 1/1 (150x150)px" acceptFormatText={true} /> */}</span>
            <div className="upload-wrapper mt-2">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} images={images} imagesPreview={imagesPreview} setImages={setImages} imagesErrors={imagesErrors} toStoreImg="tabletImagePrimary" />
            </div>
          </div>

          <div className="images-card-header">
            <span>{translations?.mobile}</span>
            <span className="ml-2">{/* <TooltipDash text="Icon aspect ratio 1/1 (150x150)px" acceptFormatText={true} /> */}</span>
            <div className="upload-wrapper mt-2">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} images={images} imagesPreview={imagesPreview} setImages={setImages} imagesErrors={imagesErrors} toStoreImg="mobileImagePrimary" />
            </div>
          </div>
        </div>

        {/*sliderTitleLine1Primary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderTitleLine1Primary">
            <b>{translations?.titleLine} 1:</b>
          </label>
          <input id="sliderTitleLine1Primary" type="text" placeholder={`${translations?.titleLine} 1`} name="sliderTitleLine1Primary" value={form.sliderTitleLine1Primary} onChange={handleInputChangeSlider} className={`${formErrors.sliderTitleLine1Primary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderTitleLine1Primary*/}

        {/*sliderTitleLine2Primary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderTitleLine2Primary">
            <b>{translations?.titleLine} 2:</b>
          </label>
          <input id="sliderTitleLine2Primary" type="text" placeholder={`${translations?.titleLine} 2`} name="sliderTitleLine2Primary" value={form.sliderTitleLine2Primary} onChange={handleInputChangeSlider} className={`${formErrors.sliderTitleLine2Primary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderTitleLine2Primary*/}

        {/*sliderTitleLine3Primary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderTitleLine3Primary">
            <b>{translations?.titleLine} 3:</b>
          </label>
          <input id="sliderTitleLine3Primary" type="text" placeholder={`${translations?.titleLine} 3`} name="sliderTitleLine3Primary" value={form.sliderTitleLine3Primary} onChange={handleInputChangeSlider} className={`${formErrors.sliderTitleLine3Primary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderTitleLine3Primary*/}

        {/*sliderSubTitleLine1Primary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderSubTitleLine1Primary">
            <b>{translations?.subtitleLine} 1:</b>
          </label>
          <input id="sliderSubTitleLine1Primary" type="text" placeholder={`${translations?.subtitleLine} 1`} name="sliderSubTitleLine1Primary" value={form.sliderSubTitleLine1Primary} onChange={handleInputChangeSlider} className={`${formErrors.sliderSubTitleLine1Primary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderSubTitleLine1Primary*/}

        {/*sliderSubTitleLine2Primary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderSubTitleLine2Primary">
            <b>{translations?.subtitleLine} 2:</b>
          </label>
          <input id="sliderSubTitleLine2Primary" type="text" placeholder={`${translations?.subtitleLine} 2`} name="sliderSubTitleLine2Primary" value={form.sliderSubTitleLine2Primary} onChange={handleInputChangeSlider} className={`${formErrors.sliderSubTitleLine2Primary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderSubTitleLine2Primary*/}

        {/*sliderSubTitleLine3Primary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderSubTitleLine3Primary">
            <b>{translations?.subtitleLine} 3:</b>
          </label>
          <input id="sliderSubTitleLine3Primary" type="text" placeholder={`${translations?.subtitleLine} 3`} name="sliderSubTitleLine3Primary" value={form.sliderSubTitleLine3Primary} onChange={handleInputChangeSlider} className={`${formErrors.sliderSubTitleLine3Primary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderSubTitleLine3Primary*/}

        {/*sliderBtnTextPrimary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderBtnTextPrimary">
            <b>{translations?.btnText}:</b>
          </label>
          <input id="sliderBtnTextPrimary" type="text" placeholder={`${translations?.btnText}`} name="sliderBtnTextPrimary" value={form.sliderBtnTextPrimary} onChange={handleInputChangeSlider} className={`${formErrors.sliderBtnTextPrimary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderBtnTextPrimary*/}

        {/*sliderUrlRedirectPrimary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderUrlRedirectPrimary">
            <b>{translations?.btnUrl}:</b>
          </label>
          <input id="sliderUrlRedirectPrimary" type="text" placeholder={`${translations?.btnUrl}`} name="sliderUrlRedirectPrimary" value={form.sliderUrlRedirectPrimary} onChange={handleInputChangeSlider} className={`${formErrors.sliderUrlRedirectPrimary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderUrlRedirectPrimary*/}
      </div>
    </div>
  );
};
export default PrimaryLangSlideData;
