export const cmsTranslations = {
  cg: {
    card: 'Kartica',
    icon: 'Ikona',
    title: 'Naslov',
    titleLine: 'Naslov linija',
    subtitleLine: 'Podnaslov linija',
    btnText: 'Tekst dugmeta',
    btnUrl: 'URL dugmeta',
    iconProportions: 'Proporcije ikone',
    imageProportions: 'Proporcije slike',
    format: 'Format',
    hideSection: 'Sakrij celu sekciju na sajtu',
    hideSectionTooltip: 'Ovo će sakriti celu sekciju na sajtu',
    mainHeading: 'Glavni naslov',
    button: 'Dugme',
    text: 'Tekst',
    list: 'Lista',
    image: 'Slika',
    item: 'Stavka',
    stepsVideoURL: 'Koraka video URL',
    stepsTitlePart: 'Koraka naslov deo',
    stepsBtnText: 'Koraka tekst dugmeta',
    stepsBtnUrl: 'Koraka URL dugmeta',
    step: 'Korak',
    uploadImage: 'Uvezi sliku',
    replaceImage: 'Zameni sliku',
    noImagePicked: 'Nije izabrana slika',
    introImage: 'Intro slika',
    desktop: 'Desktop',
    tablet: 'Tablet',
    mobile: 'Mobilni',
    mainHeadingLine: 'Glavni naslov linija',
    row: 'Red',
    videoUrl: 'Video Url',
    mainHeadingPart: 'Glavni naslov deo',
    benefit: 'Benefit',
    resource: 'Resurs',
    advantage: 'Prednost',
    logo: 'Logo',
    step: 'Korak',
    info: 'Info',
    subTitleLine: 'Podnaslov linija',
    sliderImages: 'Slike slajdera',
    hideSlideOnPage: 'Sakrij slajd na stranici',
    backgroundImages: 'Pozadinske slike',
    heroImage: 'Hero slika',
  },
  sr: {
    card: 'Kartica',
    icon: 'Ikona',
    title: 'Naslov',
    titleLine: 'Naslov linija',
    subtitleLine: 'Podnaslov linija',
    btnText: 'Tekst dugmeta',
    btnUrl: 'URL dugmeta',
    iconProportions: 'Proporcije ikone',
    imageProportions: 'Proporcije slike',
    format: 'Format',
    hideSection: 'Sakrij celu sekciju na sajtu',
    hideSectionTooltip: 'Ovo će sakriti celu sekciju na sajtu',
    mainHeading: 'Glavni naslov',
    button: 'Dugme',
    text: 'Tekst',
    list: 'Lista',
    image: 'Slika',
    item: 'Stavka',
    stepsVideoURL: 'Koraka video URL',
    stepsTitlePart: 'Koraka naslov deo',
    stepsBtnText: 'Koraka tekst dugmeta',
    stepsBtnUrl: 'Koraka URL dugmeta',
    step: 'Korak',
    uploadImage: 'Uvezi sliku',
    replaceImage: 'Zameni sliku',
    noImagePicked: 'Nije izabrana slika',
    introImage: 'Intro slika',
    desktop: 'Desktop',
    tablet: 'Tablet',
    mobile: 'Mobilni',
    mainHeadingLine: 'Glavni naslov linija',
    row: 'Red',
    videoUrl: 'Video Url',
    mainHeadingPart: 'Glavni naslov deo',
    benefit: 'Benefit',
    resource: 'Resurs',
    advantage: 'Prednost',
    logo: 'Logo',
    step: 'Korak',
    info: 'Info',
    subTitleLine: 'Podnaslov linija',
    sliderImages: 'Slike slajdera',
    hideSlideOnPage: 'Sakrij slajd na stranici',
    backgroundImages: 'Pozadinske slike',
    heroImage: 'Hero slika',
  },
};
