import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import { PERMISSIONS } from '../../../utils/permissions';
import { useAuth } from '../../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { Section, Main } from '../../../components/content';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import SlideModal from './SlideModal';
import Message from '../../../components/message/Message';
import { cmsTranslations } from '../../../components/cmsTranslations';

const CmsSliderPage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const navigate = useNavigate();
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);
  const [currentSlideData, setCurrentSlideData] = useState(null);
  const [newSlideModalActive, setNewSlideModalActive] = useState(false);
  const [slidesData, setSlidesData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  let countryCodeExtension = null;
  const getCountryExtensionFromCountryCode = (countryCode) => {
    switch (countryCode) {
      case '66a76a9f198a9e71fd572334':
        countryCodeExtension = 'sr';
        break;
      case '6740868b48686db16c24f818':
        countryCodeExtension = 'cg';
        break;
    }
  };
  const countryExtension = getCountryExtensionFromCountryCode(user?.countryCodes[0]) || null;
  const translations = cmsTranslations[countryCodeExtension];

  const [images, setImages] = useState({
    desktopImagePrimary: '',
    tabletImagePrimary: '',
    mobileImagePrimary: '',
    desktopImageSecondary: '',
    tabletImageSecondary: '',
    mobileImageSecondary: '',
  });
  const [imagesErrors, setImagesErrors] = useState({
    desktopImagePrimary: false,
    tabletImagePrimary: false,
    mobileImagePrimary: false,
    desktopImageSecondary: false,
    tabletImageSecondary: false,
    mobileImageSecondary: false,
  });
  const [imagesPreview, setImagesPreview] = useState({
    desktopImagePrimary: false,
    tabletImagePrimary: false,
    mobileImagePrimary: false,
    desktopImageSecondary: false,
    tabletImageSecondary: false,
    mobileImageSecondary: false,
  });
  const [form, setForm] = useState({
    sliderTitleLine1Primary: '',
    sliderTitleLine2Primary: '',
    sliderTitleLine3Primary: '',
    sliderSubTitleLine1Primary: '',
    sliderSubTitleLine2Primary: '',
    sliderSubTitleLine3Primary: '',
    sliderBtnTextPrimary: '',
    sliderUrlRedirectPrimary: '',
    sliderTitleLine1Secondary: '',
    sliderTitleLine2Secondary: '',
    sliderTitleLine3Secondary: '',
    sliderSubTitleLine1Secondary: '',
    sliderSubTitleLine2Secondary: '',
    sliderSubTitleLine3Secondary: '',
    sliderBtnTextSecondary: '',
    sliderUrlRedirectSecondary: '',
    positionIndex: '',
    hideSlide: false,
  });
  const [formErrors, setFormErrors] = useState({
    sliderTitleLine1Primary: false,
    sliderTitleLine2Primary: false,
    sliderTitleLine3Primary: false,
    sliderSubTitleLine1Primary: false,
    sliderSubTitleLine2Primary: false,
    sliderSubTitleLine3Primary: false,
    sliderBtnTextPrimary: false,
    sliderUrlRedirectPrimary: false,
    sliderTitleLine1Secondary: false,
    sliderTitleLine2Secondary: false,
    sliderTitleLine3Secondary: false,
    sliderSubTitleLine1Secondary: false,
    sliderSubTitleLine2Secondary: false,
    sliderSubTitleLine3Secondary: false,
    sliderBtnTextSecondary: false,
    sliderUrlRedirectSecondary: false,
    positionIndex: false,
    hideSlide: false,
  });
  const handleInputChangeSlider = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'hideSlide') {
      setForm((prevState) => ({
        ...prevState,
        hideSlide: !prevState.hideSlide,
      }));
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);
  const onChangeTab = useCallback(() => {}, []);

  // const userCountryCode = 185;
  // console.log('userCountryCode', user);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  const getReadData = useCallback(async () => {
    const { token } = user;

    try {
      const url = `${PERMISSIONS.CMSGetAllSlides}`;
      const response = await axios
        .get(`${SERVER_URL}/${url}`, {
          withCredentials: false,
          headers: {
            department: PERMISSIONS.grocery,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);

          return res;
        })
        .catch((err) => {
          setReadDataLoading(false);
          console.error(err);
        });

      return response;
    } catch (err) {}
  }, [user]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  const handleSaveSlider = async (isEditing) => {
    const { token } = user;
    const url = `${PERMISSIONS.CMSSaveSlide}`;

    // let payloadTest;
    // Object.keys(form).forEach((key) => {
    //   if (form[key] === '') {
    //     payloadTest = { ...payloadTest, [key]: 'required' };
    //   } else {
    //     payloadTest = { ...payloadTest, [key]: form[key] };
    //   }
    // });
    // console.log('payloadTest', payloadTest);

    const payload = {
      sliderTitleLine1Primary: form?.sliderTitleLine1Primary || null,
      sliderTitleLine2Primary: form?.sliderTitleLine2Primary || null,
      sliderTitleLine3Primary: form?.sliderTitleLine3Primary || null,
      sliderSubTitleLine1Primary: form?.sliderSubTitleLine1Primary || null,
      sliderSubTitleLine2Primary: form?.sliderSubTitleLine2Primary || null,
      sliderSubTitleLine3Primary: form?.sliderSubTitleLine3Primary || null,
      sliderBtnTextPrimary: form?.sliderBtnTextPrimary || null,
      sliderUrlRedirectPrimary: form?.sliderUrlRedirectPrimary || null,
      sliderTitleLine1Secondary: form?.sliderTitleLine1Secondary || null,
      sliderTitleLine2Secondary: form?.sliderTitleLine2Secondary || null,
      sliderTitleLine3Secondary: form?.sliderTitleLine3Secondary || null,
      sliderSubTitleLine1Secondary: form?.sliderSubTitleLine1Secondary || null,
      sliderSubTitleLine2Secondary: form?.sliderSubTitleLine2Secondary || null,
      sliderSubTitleLine3Secondary: form?.sliderSubTitleLine3Secondary || null,
      sliderBtnTextSecondary: form?.sliderBtnTextSecondary || null,
      sliderUrlRedirectSecondary: form?.sliderUrlRedirectSecondary || null,

      desktopImagePrimary: images?.desktopImagePrimary || null,
      tabletImagePrimary: images?.tabletImagePrimary || null,
      mobileImagePrimary: images?.mobileImagePrimary || null,
      desktopImageSecondary: images?.desktopImageSecondary || null,
      tabletImageSecondary: images?.tabletImageSecondary || null,
      mobileImageSecondary: images?.mobileImageSecondary || null,

      countryCode: user?.countryCodes[0],

      positionIndex: '',
      hideSlide: form?.hideSlide || false,
    };
    console.log('payload', payload);
    // return;
    const method = isEditing ? 'put' : 'post';

    try {
      const response = await axios({
        method: method,
        url: `${SERVER_URL}/${url}`,
        data: payload,
        params: {
          slideId: isEditing ? currentSlideData : null,
        },
        headers: {
          department: PERMISSIONS.grocery,
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setReadDataRefetch((t) => !t);
          setNewSlideModalActive((t) => !t);
          setIsEditing(false);
          setForm({
            sliderTitleLine1Primary: '',
            sliderTitleLine2Primary: '',
            sliderTitleLine3Primary: '',
            sliderSubTitleLine1Primary: '',
            sliderSubTitleLine2Primary: '',
            sliderSubTitleLine3Primary: '',
            sliderBtnTextPrimary: '',
            sliderUrlRedirectPrimary: '',
            sliderTitleLine1Secondary: '',
            sliderTitleLine2Secondary: '',
            sliderTitleLine3Secondary: '',
            sliderSubTitleLine1Secondary: '',
            sliderSubTitleLine2Secondary: '',
            sliderSubTitleLine3Secondary: '',
            sliderBtnTextSecondary: '',
            sliderUrlRedirectSecondary: '',
            positionIndex: '',
            hideSlide: false,
          });
          setImages({
            desktopImagePrimary: '',
            tabletImagePrimary: '',
            mobileImagePrimary: '',
            desktopImageSecondary: '',
            tabletImageSecondary: '',
            mobileImageSecondary: '',
          });
          setImagesPreview({
            desktopImagePrimary: '',
            tabletImagePrimary: '',
            mobileImagePrimary: '',
            desktopImageSecondary: '',
            tabletImageSecondary: '',
            mobileImageSecondary: '',
          });
          return res;
        })
        .catch((err) => {
          console.error(err);
          setReadDataRefetch((t) => !t);
          setNewSlideModalActive((t) => !t);
        });

      return response;
    } catch (err) {
      setReadDataRefetch((t) => !t);
      setNewSlideModalActive((t) => !t);
    }
  };

  const handleCloseSliderModal = useCallback(() => {
    setIsEditing(false);
    setForm({
      sliderTitleLine1Primary: '',
      sliderTitleLine2Primary: '',
      sliderTitleLine3Primary: '',
      sliderSubTitleLine1Primary: '',
      sliderSubTitleLine2Primary: '',
      sliderSubTitleLine3Primary: '',
      sliderBtnTextPrimary: '',
      sliderUrlRedirectPrimary: '',
      sliderTitleLine1Secondary: '',
      sliderTitleLine2Secondary: '',
      sliderTitleLine3Secondary: '',
      sliderSubTitleLine1Secondary: '',
      sliderSubTitleLine2Secondary: '',
      sliderSubTitleLine3Secondary: '',
      sliderBtnTextSecondary: '',
      sliderUrlRedirectSecondary: '',
      positionIndex: '',
      hideSlide: false,
    });
    setImages({
      desktopImagePrimary: '',
      tabletImagePrimary: '',
      mobileImagePrimary: '',
      desktopImageSecondary: '',
      tabletImageSecondary: '',
      mobileImageSecondary: '',
    });
    setImagesPreview({
      desktopImagePrimary: '',
      tabletImagePrimary: '',
      mobileImagePrimary: '',
      desktopImageSecondary: '',
      tabletImageSecondary: '',
      mobileImageSecondary: '',
    });
    setNewSlideModalActive((t) => !t);
  }, []);

  console.log('form data', form);
  console.log('images data', images);
  console.log('read data', readData);

  const handleActivateEditSliderModal = async (id) => {
    setIsEditing(true);
    try {
      const { token } = user;
      const url = `${PERMISSIONS.CMSGetSlideById}?slideId=${id}`;

      const response = await axios
        .get(`${SERVER_URL}/${url}`, {
          headers: {
            department: PERMISSIONS.grocery,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;
            // setCurrentSlideData(data);
            setCurrentSlideData(data?._id);
            console.log('current slide data', data);

            setForm({
              sliderTitleLine1Primary: data?.sliderTitleLine1Primary || '',
              sliderTitleLine2Primary: data?.sliderTitleLine2Primary || '',
              sliderTitleLine3Primary: data?.sliderTitleLine3Primary || '',
              sliderSubTitleLine1Primary: data?.sliderSubTitleLine1Primary || '',
              sliderSubTitleLine2Primary: data?.sliderSubTitleLine2Primary || '',
              sliderSubTitleLine3Primary: data?.sliderSubTitleLine3Primary || '',
              sliderBtnTextPrimary: data?.sliderBtnTextPrimary || '',
              sliderUrlRedirectPrimary: data?.sliderUrlRedirectPrimary || '',
              sliderTitleLine1Secondary: data?.sliderTitleLine1Secondary || '',
              sliderTitleLine2Secondary: data?.sliderTitleLine2Secondary || '',
              sliderTitleLine3Secondary: data?.sliderTitleLine3Secondary || '',
              sliderSubTitleLine1Secondary: data?.sliderSubTitleLine1Secondary || '',
              sliderSubTitleLine2Secondary: data?.sliderSubTitleLine2Secondary || '',
              sliderSubTitleLine3Secondary: data?.sliderSubTitleLine3Secondary || '',
              sliderBtnTextSecondary: data?.sliderBtnTextSecondary || '',
              sliderUrlRedirectSecondary: data?.sliderUrlRedirectSecondary || '',
              positionIndex: data?.positionIndex || '',
              hideSlide: data?.hideSlide || false,
            });
            setImages({
              desktopImagePrimary: data?.desktopImagePrimary?._id || '',
              tabletImagePrimary: data?.tabletImagePrimary?._id || '',
              mobileImagePrimary: data?.mobileImagePrimary?._id || '',
              desktopImageSecondary: data?.desktopImageSecondary?._id || '',
              tabletImageSecondary: data?.tabletImageSecondary?._id || '',
              mobileImageSecondary: data?.mobileImageSecondary?._id || '',
            });
            setImagesPreview({
              desktopImagePrimary: data?.desktopImagePrimary?.url || '',
              tabletImagePrimary: data?.tabletImagePrimary?.url || '',
              mobileImagePrimary: data?.mobileImagePrimary?.url || '',
              desktopImageSecondary: data?.desktopImageSecondary?.url || '',
              tabletImageSecondary: data?.tabletImageSecondary?.url || '',
              mobileImageSecondary: data?.mobileImageSecondary?.url || '',
            });
            setNewSlideModalActive((t) => !t);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      // if (response) {
      //   const { data } = response;
      //   setCurrentSlideData(data);
      //   setNewSlideModalActive((t) => !t);
      // }
    } catch (err) {
      console.error(err);
    }
  };

  console.log('current slide data', currentSlideData);
  const showPlaceholderImage = (slide) => {
    if (slide?.desktopImagePrimary?.url) {
      return slide?.desktopImagePrimary?.url;
    } else if (slide?.tabletImagePrimary?.url) {
      return slide?.tabletImagePrimary?.url;
    } else if (slide?.mobileImagePrimary?.url) {
      return slide?.mobileImagePrimary?.url;
    } else if (slide?.desktopImageSecondary?.url) {
      return slide?.desktopImageSecondary?.url;
    } else if (slide?.tabletImageSecondary?.url) {
      return slide?.tabletImageSecondary?.url;
    } else if (slide?.mobileImageSecondary?.url) {
      return slide?.mobileImageSecondary?.url;
    } else {
      return '/assets/images/placeholder-category.png';
    }
  };

  const handleDeleteSliderModal = async (id) => {
    const { token } = user;
    const url = `${PERMISSIONS.CMSDeleteSlide}`;

    try {
      const response = await axios
        .delete(`${SERVER_URL}/${url}`, {
          headers: {
            department: PERMISSIONS.grocery,
            Authorization: `Bearer ${token}`,
          },
          data: {
            slideId: id,
          },
        })
        .then((res) => {
          setReadDataRefetch((t) => !t);
        })
        .catch((err) => {
          console.error(err);
        });

      return response;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <h2 className="page-title">Cms slider page</h2>
      <Section className="section section__wrapper section__gallery">
        {newSlideModalActive && <SlideModal handleInputChangeSlider={handleInputChangeSlider} form={form} images={images} imagesPreview={imagesPreview} setImages={setImages} imagesErrors={imagesErrors} formErrors={formErrors} handleSaveSlider={handleSaveSlider} handleCloseSliderModal={handleCloseSliderModal} isEditing={isEditing} countryCodeExtension={countryCodeExtension} translations={translations} />}
        <div className="cms cms-slider">
          <header className="section__header">
            <div className="actions">
              {!readDataLoading ? (
                <button onClick={() => setNewSlideModalActive((t) => !t)} className="btn btn-primary pl-2 pr-2" type="button">
                  <span className="text">Add Slider Image</span>
                </button>
              ) : (
                <LoadingPlaceholder style={{ width: '104.5px', height: '50px' }} />
              )}
            </div>
          </header>
          <Main className="section__content relative min-h-table-content">
            {!readDataLoading ? (
              <div style={{ padding: '0 20px', margin: '20px 0' }}>
                <div className="cms-slider-grid">
                  {readData && readData.length > 0 ? (
                    readData.map((slide, index) => (
                      <div className="ind-slider-item-card" key={index}>
                        <div style={{ backgroundImage: `url(${showPlaceholderImage(slide)})` }} className="ind-slider-item-card__img"></div>
                        {/* {slide.sliderTitleLine1Primary} */}
                        <div className="ind-slider-overlay">
                          <img src="/assets/icons/pencil-fill.svg" alt="edit icon" onClick={() => handleActivateEditSliderModal(slide._id)} />
                          <img src="/assets/icons/delete-bin-6-line.svg" alt="delete icon" onClick={() => handleDeleteSliderModal(slide._id)} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <Message className="content-message">
                      <h2 style={{ color: '#666' }}>Data not found</h2>
                    </Message>
                  )}
                </div>
              </div>
            ) : (
              <Loading />
            )}
          </Main>
        </div>
      </Section>
    </>
  );
};
export default CmsSliderPage;
