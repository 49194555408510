import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2Secondary = ({ formSection2, handleInputChangeFormSection2, setEnImages, enImages, enImagesPreview, formSection2Errors, enImagesErrors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*secondary_mainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_mainHeading">
            <b>Main heading:</b>
          </label>
          <input id="secondary_mainHeading" type="text" placeholder="Main heading" name="secondary_mainHeading" value={formSection2.secondary.mainHeading} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.mainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_mainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Card 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">List 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Image</span>
              <span className="ml-6">
                <TooltipDash text="Image aspect ratio 3/2 (1500x1000)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload enImages={enImages} enImagesErrors={enImagesErrors} enImagesPreview={enImagesPreview} setEnImages={setEnImages} toStoreImg="section2Image1Secondary" />
            </div>
          </div>
          {/*secondary_list1item1*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_list1item1">
              <b>Item 1:</b>
            </label>
            <input id="secondary_list1item1" type="text" placeholder="Item 1" name="secondary_list1item1" value={formSection2.secondary.list1item1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.list1item1 ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_list1item1*/}
          {/*secondary_list1item2*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_list1item2">
              <b>Item 2:</b>
            </label>
            <input id="secondary_list1item2" type="text" placeholder="Item 2:" name="secondary_list1item2" value={formSection2.secondary.list1item2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.list1item2 ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_list1item2*/}
          {/*secondary_list1item3*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_list1item3">
              <b>Item 3:</b>
            </label>
            <input id="secondary_list1item3" type="text" placeholder="Item 3" name="secondary_list1item3" value={formSection2.secondary.list1item3} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.list1item3 ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_list1item3*/}
          {/*secondary_list1item4*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_list1item4">
              <b>Item 4:</b>
            </label>
            <input id="secondary_list1item4" type="text" placeholder="Item 4" name="secondary_list1item4" value={formSection2.secondary.list1item4} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.list1item4 ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_list1item4*/}
        </div>
        {/*Card 1:*/}
      </div>
    </>
  );
};
export default memo(Section2Secondary);
