import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/useAuth';
import Overlay from '../overlay/Overlay';
import { Wrapper, Aside } from '../content';
import Navbar from '../navbar/Navbar';
import { jwtDecode } from 'jwt-decode';
import { PERMISSIONS } from '../../utils/permissions';
import { groceryMenuItems } from '../../utils/navigations';
import { useAppContext } from '../../context/AppProvider';

const Sidebar = (props) => {
  const { handleChangeLanguage, currentLanguage, secondaryLanguage } = useAppContext();
  const { onHide, onToggle, active, className } = props;
  const { user, logout } = useAuth();
  const location = useLocation();
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);

  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    if (user && user.token) {
      const token = user.token;
      const decodeToken = jwtDecode(token);

      const isCheckRoleData = decodeToken && decodeToken.hasOwnProperty('roleData');
      if (isCheckRoleData) {
        const permissions = decodeToken.roleData?.permissions;

        if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
          if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
            setGroceryPermissions(permissions.grocery);
          }
        }
      }
    }
  }, [user, location]);

  const handleToggleLangSwitchDropdown = () => {
    setLangDropdownOpen((prev) => !prev);
  };

  // LOGOUT
  const handleLogout = () => {
    logout();
    onHide(false);
  };

  useEffect(() => {
    active ? document.documentElement.classList.add('overflow-hidden') : document.documentElement.classList.remove('overflow-hidden');
  }, [active]);

  const navbarProps = {
    location: location,
  };

  const groceryProps = {
    onHide: onHide,
    handleLogout: handleLogout,
    groceryPermissions: groceryPermissions,
  };

  // GROCERY Menus
  const groceryMenus = groceryMenuItems(groceryProps);

  const handleSwitch = (lang) => {
    handleChangeLanguage(lang);
    setLangDropdownOpen((prev) => !prev);
  };

  return (
    <>
      <Aside className={`gl__sidebar gl__sidebar--left gl__sidebar--layout ${active ? 'isActive' : ''} ${className !== undefined ? className : ''}`}>
        <Wrapper className="logo">
          <Link to={`/${PERMISSIONS.dashboard}`} className="link d-block" onClick={() => onHide(false)} data-cy="sidebar-logo">
            <img width={200} className="logo-brand" alt="FitPass" src="/assets/images/logoWhite.png" />
          </Link>
        </Wrapper>

        <Navbar className="navbar" {...navbarProps} menus={{ grocery: groceryMenus }} />
        <hr />
        <div className="lang-switch lang-switch--desktop">
          <div className="top-wrap">
            <span style={{ textTransform: 'uppercase' }} onClick={handleToggleLangSwitchDropdown}>
              {currentLanguage}
            </span>
            <img src="/assets/icons/lang-switch-arrow.svg" alt="lang switch arrow" className={`lang-switch-arrow ${langDropdownOpen ? 'active' : ''}`} onClick={handleToggleLangSwitchDropdown} />
          </div>
          <ul className={`lang-switch-dropdown ${langDropdownOpen ? 'active' : ''}`}>
            <li style={{ textTransform: 'uppercase' }} onClick={() => handleSwitch(secondaryLanguage)}>
              {secondaryLanguage}
            </li>
          </ul>
        </div>
      </Aside>

      <Overlay onToggle={onToggle} active={active} />
    </>
  );
};

export default Sidebar;
