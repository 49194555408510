import React, { memo, useState } from 'react';
import { Tabs } from 'antd';
import Section2Secondary from '../tabs/secondary/Section2Secondary';
import Section2Primary from '../tabs/primary/Section2Primary';
import CheckboxCms from '../../../../components/checkbox-cms';
import { useTranslation } from 'react-i18next';

const CmsHomepageSection2 = ({ translations, countryCodeExtension, formSection2, handleInputChangeFormSection2, setHpImages, hpImages, hpImagesPreview, formSection2Errors, hpImagesErrors }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const [activeTabKey, setActiveTabKey] = useState('1');

  const onChangeTabSection2 = (key) => {
    setActiveTabKey(key);
  };

  const sectionProps = {
    formSection2,
    handleInputChangeFormSection2,
    setHpImages,
    hpImages,
    hpImagesPreview,
    formSection2Errors,
    hpImagesErrors,
    translations,
    activeTabKey,
  };
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSection2}>
        <TabPane key="1" tab="EN">
          <Section2Secondary {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab={countryCodeExtension?.toUpperCase()}>
          <Section2Primary {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName="section2_hide" isChecked={formSection2.hide} handleChange={handleInputChangeFormSection2} label={activeTabKey === '2' ? translations?.hideSection : 'Hide entire section on website'} tooltipText={activeTabKey === '2' ? translations?.hideSectionTooltip : 'Hide entire section on website'} />
    </>
  );
};
export default memo(CmsHomepageSection2);
