import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section4Cg = ({ translations, activeTabKey, handleInputChangeFormSection4, formSection4, setHpImages, hpImages, hpImagesPreview, formSection4Errors, hpImagesErrors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
          <div className="cms-form-flex-col">
            <div className="images-card-header">
              <span>{translations?.introImage}</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.imageProportions} 7.7/1 (570x74)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload activeTabKey={activeTabKey} translations={translations} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section4Image1Primary" />
            </div>
          </div>
        </div>
        {/*Shared inputs*/}
        <div className="shared">
          {/*primary_sec4VideoUrl*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec4VideoUrl">
              <b>3 {translations?.stepsVideoURL}:</b>
            </label>
            <input id="primary_sec4VideoUrl" type="text" placeholder={`3 ${translations?.stepsVideoURL}`} name="primary_sec4VideoUrl" value={formSection4.primary.sec4VideoUrl} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4VideoUrl ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec4VideoUrl*/}
          {/*primary_sec4StepsTitlePart1*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec4StepsTitlePart1">
              <b>3 {translations?.stepsTitlePart} 1:</b>
            </label>
            <input id="primary_sec4StepsTitlePart1" type="text" placeholder={`3 ${translations?.stepsTitlePart} 1`} name="primary_sec4StepsTitlePart1" value={formSection4.primary.sec4StepsTitlePart1} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4StepsTitlePart1 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec4StepsTitlePart1*/}
          {/*primary_sec4StepsTitlePart2*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec4StepsTitlePart2">
              <b>3 {translations?.stepsTitlePart} 2:</b>
            </label>
            <input id="primary_sec4StepsTitlePart2" type="text" placeholder={`3 ${translations?.stepsTitlePart} 2`} name="primary_sec4StepsTitlePart2" value={formSection4.primary.sec4StepsTitlePart2} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4StepsTitlePart2 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec4StepsTitlePart2*/}
          {/*primary_sec4StepsButtonText*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec4StepsButtonText">
              <b>3 {translations?.stepsBtnText}:</b>
            </label>
            <input id="primary_sec4StepsButtonText" type="text" placeholder={`3 ${translations?.stepsBtnText}`} name="primary_sec4StepsButtonText" value={formSection4.primary.sec4StepsButtonText} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4StepsButtonText ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec4StepsButtonText*/}
          {/*primary_sec4StepsButtonUrl*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec4StepsButtonUrl">
              <b>3 {translations?.stepsBtnUrl}:</b>
            </label>
            <input id="primary_sec4StepsButtonUrl" type="text" placeholder={`3 ${translations?.stepsBtnUrl}`} name="primary_sec4StepsButtonUrl" value={formSection4.primary.sec4StepsButtonUrl} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4StepsButtonUrl ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec4StepsButtonUrl*/}
        </div>
      </div>
      {/*Shared inputs*/}
      <section>
        <article>
          <div className="cms-form-flex">
            {/*Card 1:*/}
            <div className="cms-form-flex-col">
              <p className="card-top">{translations?.step} 1:</p>
              {/*primary_sec4Step1Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec4Step1Title">
                  <b>{translations?.title}:</b>
                </label>
                <textarea id="primary_sec4Step1Title" type="text" placeholder={`${translations?.title}`} name="primary_sec4Step1Title" value={formSection4.primary.sec4Step1Title} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4Step1Title ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec4Step1Title*/}
              {/*primary_sec4Step1Text*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec4Step1Text">
                  <b>{translations?.text}:</b>
                </label>
                <textarea id="primary_sec4Step1Text" type="text" placeholder={`${translations?.text}`} name="primary_sec4Step1Text" value={formSection4.primary.sec4Step1Text} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4Step1Text ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec4Step1Text*/}
            </div>
            {/*Card 1:*/}
            {/*Card 2:*/}
            <div className="cms-form-flex-col">
              <p className="card-top">{translations?.step} 2:</p>
              {/*primary_sec4Step2Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec4Step2Title">
                  <b>{translations?.title}:</b>
                </label>
                <textarea id="primary_sec4Step2Title" type="text" placeholder={`${translations?.title}`} name="primary_sec4Step2Title" value={formSection4.primary.sec4Step2Title} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4Step2Title ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec4Step2Title*/}
              {/*primary_sec4Step2Text*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec4Step2Text">
                  <b>{translations?.text}:</b>
                </label>
                <textarea id="primary_sec4Step2Text" type="text" placeholder={`${translations?.text}`} name="primary_sec4Step2Text" value={formSection4.primary.sec4Step2Text} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4Step2Text ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec4Step2Text*/}
            </div>
            {/*Card 2:*/}
            {/*Card 3:*/}
            <div className="cms-form-flex-col">
              <p className="card-top">{translations?.step} 3:</p>
              {/*primary_sec4Step2Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec4Step3Title">
                  <b>{translations?.title}:</b>
                </label>
                <textarea id="primary_sec4Step3Title" type="text" placeholder={`${translations?.title}`} name="primary_sec4Step3Title" value={formSection4.primary.sec4Step3Title} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4Step3Title ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec4Step3Title*/}
              {/*primary_sec4Step3Text*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec4Step3Text">
                  <b>{translations?.text}:</b>
                </label>
                <textarea id="primary_sec4Step3Text" type="text" placeholder={`${translations?.text}`} name="primary_sec4Step3Text" value={formSection4.primary.sec4Step3Text} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.primary.sec4Step3Text ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec4Step3Text*/}
            </div>
            {/*Card 3:*/}
          </div>
        </article>
      </section>
    </>
  );
};
export default memo(Section4Cg);
