import React, { useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import { PERMISSIONS } from '../../../utils/permissions';

import { useAuth } from '../../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import { notification } from 'antd';

import CmsHomepageSection1 from './sections/CmsHomepageSection1';
import CmsHomepageSection2 from './sections/CmsHomepageSection2';
import CmsHomepageSection3 from './sections/CmsHomepageSection3';
import CmsHomepageSection4 from './sections/CmsHomepageSection4';

import { constructInitialFormSection1State, constructInitialFormSection2State, constructInitialFormSection3State, constructInitialFormSection4State, constructInitialHpImagesState, constructInitialHpImagesPreviewState } from './helpers/helpers';
import { useTranslation } from 'react-i18next';
import { cmsTranslations } from '../../../components/cmsTranslations';

const CmsHomepage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  let countryCodeExtension = null;
  const getCountryExtensionFromCountryCode = (countryCode) => {
    switch (countryCode) {
      case '66a76a9f198a9e71fd572334':
        countryCodeExtension = 'sr';
        break;
      case '6740868b48686db16c24f818':
        countryCodeExtension = 'cg';
        break;
    }
  };
  const countryExtension = getCountryExtensionFromCountryCode(user?.countryCodes[0]) || null;
  const translations = cmsTranslations[countryCodeExtension];

  console.log('countryCodeExtension', countryCodeExtension);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SECTION 1 HANDLE
  const [hpImages, setHpImages] = React.useState({
    section1Icon1Secondary: '',
    section1Icon2Secondary: '',
    section2Image1Secondary: '',
    section2Image2Secondary: '',
    section3Icon1Secondary: '',
    section3Icon2Secondary: '',
    section3Icon3Secondary: '',
    section3Icon4Secondary: '',
    section3Icon5Secondary: '',
    section3Icon6Secondary: '',
    section4Image1Secondary: '',

    section1Icon1Primary: '',
    section1Icon2Primary: '',
    section2Image1Primary: '',
    section2Image2Primary: '',
    section3Icon1Primary: '',
    section3Icon2Primary: '',
    section3Icon3Primary: '',
    section3Icon4Primary: '',
    section3Icon5Primary: '',
    section3Icon6Primary: '',
    section4Image1Primary: '',
  });
  const [hpImagesErrors, setHpImagesErrors] = React.useState({
    section1Icon1Secondary: false,
    section1Icon2Secondary: false,
    section2Image1Secondary: false,
    section2Image2Secondary: false,
    section3Icon1Secondary: false,
    section3Icon2Secondary: false,
    section3Icon3Secondary: false,
    section3Icon4Secondary: false,
    section3Icon5Secondary: false,
    section3Icon6Secondary: false,
    section4Image1Secondary: false,

    section1Icon1Primary: false,
    section1Icon2Primary: false,
    section2Image1Primary: false,
    section2Image2Primary: false,
    section3Icon1Primary: false,
    section3Icon2Primary: false,
    section3Icon3Primary: false,
    section3Icon4Primary: false,
    section3Icon5Primary: false,
    section3Icon6Primary: false,
    section4Image1Primary: false,
  });
  const [hpImagesPreview, setHpImagesPreview] = React.useState({
    section1Icon1Secondary: '',
    section1Icon2Secondary: '',
    section2Image1Secondary: '',
    section2Image2Secondary: '',
    section3Icon1Secondary: '',
    section3Icon2Secondary: '',
    section3Icon3Secondary: '',
    section3Icon4Secondary: '',
    section3Icon5Secondary: '',
    section3Icon6Secondary: '',
    section4Image1Secondary: '',

    section1Icon1Primary: '',
    section1Icon2Primary: '',
    section2Image1Primary: '',
    section2Image2Primary: '',
    section3Icon1Primary: '',
    section3Icon2Primary: '',
    section3Icon3Primary: '',
    section3Icon4Primary: '',
    section3Icon5Primary: '',
    section3Icon6Primary: '',
    section4Image1Primary: '',
  });
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    secondary: {
      title1: '',
      title2: '',
      subTitle1Part1: '',
      subTitle1Part2: '',
      subTitle2Part1: '',
      subTitle2Part2: '',
      button1Text: '',
      button2Text: '',
      button1Url: '',
      button2Url: '',
    },
    primary: {
      title1: '',
      title2: '',
      subTitle1Part1: '',
      subTitle1Part2: '',
      subTitle2Part1: '',
      subTitle2Part2: '',
      button1Text: '',
      button2Text: '',
      button1Url: '',
      button2Url: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    secondary: {
      title1: false,
      title2: false,
      subTitle1Part1: false,
      subTitle1Part2: false,
      subTitle2Part1: false,
      subTitle2Part2: false,
      button1Text: false,
      button2Text: false,
      button1Url: false,
      button2Url: false,
    },
    primary: {
      title1: false,
      title2: false,
      subTitle1Part1: false,
      subTitle1Part2: false,
      subTitle2Part1: false,
      subTitle2Part2: false,
      button1Text: false,
      button2Text: false,
      button1Url: false,
      button2Url: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);

  // SECTION 1 HANDLE

  // SECTION 2 HANDLE
  const [formSection2, setFormSection2] = React.useState({
    hide: false,
    secondary: {
      mainHeading: '',
      sec2button1Text: '',
      sec2button2Text: '',
      list1item1: '',
      list1item2: '',
      list1item3: '',
      list1item4: '',
      list1item5: '',
      list2item1: '',
      list2item2: '',
      list2item3: '',
      list2item4: '',
      // list2item5: '',
    },
    primary: {
      mainHeading: '',
      sec2button1Text: '',
      sec2button2Text: '',
      list1item1: '',
      list1item2: '',
      list1item3: '',
      list1item4: '',
      list1item5: '',
      list2item1: '',
      list2item2: '',
      list2item3: '',
      list2item4: '',
      // list2item5: '',
    },
  });
  const [formSection2Errors, setFormSection2Errors] = React.useState({
    secondary: {
      mainHeading: false,
      sec2button1Text: false,
      sec2button2Text: false,
      list1item1: false,
      list1item2: false,
      list1item3: false,
      list1item4: false,
      list1item5: false,
      list2item1: false,
      list2item2: false,
      list2item3: false,
      list2item4: false,
      // list2item5: false,
    },
    primary: {
      mainHeading: false,
      sec2button1Text: false,
      sec2button2Text: false,
      list1item1: false,
      list1item2: false,
      list1item3: false,
      list1item4: false,
      list1item5: false,
      list2item1: false,
      list2item2: false,
      list2item3: false,
      list2item4: false,
      // list2item5: false,
    },
  });
  const handleInputChangeFormSection2 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section2_hide') {
      setFormSection2((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection2((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  // const onChangeTabSection2 = useCallback(() => {}, []);
  // SECTION 2 HANDLE

  // SECTION 3 HANDLE
  const [formSection3, setFormSection3] = React.useState({
    hide: false,
    secondary: {
      sec3MainHeading: '',
      sec3Card1Title: '',
      sec3Card1SubTitle: '',
      sec3Card2Title: '',
      sec3Card2SubTitle: '',
      sec3Card3Title: '',
      sec3Card3SubTitle: '',
      sec3Card4Title: '',
      sec3Card4SubTitle: '',
      sec3Card5Title: '',
      sec3Card5SubTitle: '',
      sec3Card6Title: '',
      sec3Card6SubTitle: '',
    },
    primary: {
      sec3MainHeading: '',
      sec3Card1Title: '',
      sec3Card1SubTitle: '',
      sec3Card2Title: '',
      sec3Card2SubTitle: '',
      sec3Card3Title: '',
      sec3Card3SubTitle: '',
      sec3Card4Title: '',
      sec3Card4SubTitle: '',
      sec3Card5Title: '',
      sec3Card5SubTitle: '',
      sec3Card6Title: '',
      sec3Card6SubTitle: '',
    },
  });
  const [formSection3Errors, setFormSection3Errors] = React.useState({
    secondary: {
      sec3MainHeading: false,
      sec3Card1Title: false,
      sec3Card1SubTitle: false,
      sec3Card2Title: false,
      sec3Card2SubTitle: false,
      sec3Card3Title: false,
      sec3Card3SubTitle: false,
      sec3Card4Title: false,
      sec3Card4SubTitle: false,
      sec3Card5Title: false,
      sec3Card5SubTitle: false,
      sec3Card6Title: false,
      sec3Card6SubTitle: false,
    },
    primary: {
      sec3MainHeading: false,
      sec3Card1Title: false,
      sec3Card1SubTitle: false,
      sec3Card2Title: false,
      sec3Card2SubTitle: false,
      sec3Card3Title: false,
      sec3Card3SubTitle: false,
      sec3Card4Title: false,
      sec3Card4SubTitle: false,
      sec3Card5Title: false,
      sec3Card5SubTitle: false,
      sec3Card6Title: false,
      sec3Card6SubTitle: false,
    },
  });
  const handleInputChangeFormSection3 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section3_hide') {
      setFormSection3((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection3((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  // const onChangeTabSection3 = useCallback(() => {}, []);
  // SECTION 3 HANDLE

  // SECTION 4 HANDLE
  const [formSection4, setFormSection4] = React.useState({
    hide: false,
    secondary: {
      sec4VideoUrl: '',
      sec4StepsTitlePart1: '',
      sec4StepsTitlePart2: '',
      sec4Step1Title: '',
      sec4Step1Text: '',
      sec4Step2Title: '',
      sec4Step2Text: '',
      sec4Step3Title: '',
      sec4Step3Text: '',
      sec4StepsButtonText: '',
      sec4StepsButtonUrl: '',
    },
    primary: {
      sec4VideoUrl: '',
      sec4StepsTitlePart1: '',
      sec4StepsTitlePart2: '',
      sec4Step1Title: '',
      sec4Step1Text: '',
      sec4Step2Title: '',
      sec4Step2Text: '',
      sec4Step3Title: '',
      sec4Step3Text: '',
      sec4StepsButtonText: '',
      sec4StepsButtonUrl: '',
    },
  });
  const [formSection4Errors, setFormSection4Errors] = React.useState({
    secondary: {
      sec4VideoUrl: false,
      sec4StepsTitlePart1: false,
      sec4StepsTitlePart2: false,
      sec4Step1Title: false,
      sec4Step1Text: false,
      sec4Step2Title: false,
      sec4Step2Text: false,
      sec4Step3Title: false,
      sec4Step3Text: false,
      sec4StepsButtonText: false,
      sec4StepsButtonUrl: false,
    },
    primary: {
      sec4VideoUrl: false,
      sec4StepsTitlePart1: false,
      sec4StepsTitlePart2: false,
      sec4Step1Title: false,
      sec4Step1Text: false,
      sec4Step2Title: false,
      sec4Step2Text: false,
      sec4Step3Title: false,
      sec4Step3Text: false,
      sec4StepsButtonText: false,
      sec4StepsButtonUrl: false,
    },
  });
  const handleInputChangeFormSection4 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section4_hide') {
      setFormSection4((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection4((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  // const onChangeTabSection4 = useCallback(() => {}, []);
  // SECTION 4 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSection2Errors = { ...formSection2Errors };
    let newFormSection3Errors = { ...formSection3Errors };
    let newFormSection4Errors = { ...formSection4Errors };

    // Validate Section 1 (secondary)
    Object.keys(formSection1.secondary).forEach((key) => {
      if (!formSection1.secondary[key]) {
        newFormSection1Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.secondary[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.primary).forEach((key) => {
      if (!formSection1.primary[key]) {
        newFormSection1Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.primary[key] = false;
      }
    });

    // Validate Section 2 (secondary)
    Object.keys(formSection2.secondary).forEach((key) => {
      if (!formSection2.secondary[key]) {
        newFormSection2Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.secondary[key] = false;
      }
    });

    // Validate Section 2 (CG)
    Object.keys(formSection2.primary).forEach((key) => {
      if (!formSection2.primary[key]) {
        newFormSection2Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.primary[key] = false;
      }
    });

    // Validate Section 3 (secondary)
    Object.keys(formSection3.secondary).forEach((key) => {
      if (!formSection3.secondary[key]) {
        newFormSection3Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.secondary[key] = false;
      }
    });

    // Validate Section 3 (CG)
    Object.keys(formSection3.primary).forEach((key) => {
      if (!formSection3.primary[key]) {
        newFormSection3Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.primary[key] = false;
      }
    });

    // Validate Section 4 (secondary)
    Object.keys(formSection4.secondary).forEach((key) => {
      if (!formSection4.secondary[key]) {
        newFormSection4Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection4Errors.secondary[key] = false;
      }
    });

    // Validate Section 4 (CG)
    Object.keys(formSection4.primary).forEach((key) => {
      if (!formSection4.primary[key]) {
        newFormSection4Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection4Errors.primary[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(hpImages).forEach((key) => {
      if (hpImages[key] === null) {
        setHpImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setHpImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSection2Errors(newFormSection2Errors);
    setFormSection3Errors(newFormSection3Errors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: t('pleaseFillAllTheFieldsAndUploadImages'),
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      section1Hide: formSection1.hide || false,
      section2Hide: formSection2.hide || false,
      section3Hide: formSection3.hide || false,
      section4Hide: formSection4.hide || false,

      section1Card1TitleSecondary: formSection1.secondary.title1 || '',
      section1Card1SubtitlePart1Secondary: formSection1.secondary.subTitle1Part1 || '',
      section1Card1SubtitlePart2Secondary: formSection1.secondary.subTitle1Part2 || '',
      section1Card2SubtitlePart1Secondary: formSection1.secondary.subTitle2Part1 || '',
      section1Card2SubtitlePart2Secondary: formSection1.secondary.subTitle2Part2 || '',
      section1Card1ButtonTextSecondary: formSection1.secondary.button1Text || '',
      section1Card1ButtonUrlSecondary: formSection1.secondary.button1Url || '',
      section1Card2TitleSecondary: formSection1.secondary.title2 || '',
      section1Card2ButtonTextSecondary: formSection1.secondary.button2Text || '',
      section1Card2ButtonUrlSecondary: formSection1.secondary.button2Url || '',

      section1Card1TitlePrimary: formSection1.primary.title1 || '',
      section1Card1SubtitlePart1Primary: formSection1.primary.subTitle1Part1 || '',
      section1Card1SubtitlePart2Primary: formSection1.primary.subTitle1Part2 || '',
      section1Card2SubtitlePart1Primary: formSection1.primary.subTitle2Part1 || '',
      section1Card2SubtitlePart2Primary: formSection1.primary.subTitle2Part2 || '',
      section1Card1ButtonTextPrimary: formSection1.primary.button1Text || '',
      section1Card1ButtonUrlPrimary: formSection1.primary.button1Url || '',
      section1Card2TitlePrimary: formSection1.primary.title2 || '',
      section1Card2ButtonTextPrimary: formSection1.primary.button2Text || '',
      section1Card2ButtonUrlPrimary: formSection1.primary.button2Url || '',

      section2MainHeadingSecondary: formSection2.secondary.mainHeading || '',
      section2Button1TextSecondary: formSection2.secondary.sec2button1Text || '',
      section2Button2TextSecondary: formSection2.secondary.sec2button2Text || '',
      section2List1Item1Secondary: formSection2.secondary.list1item1 || '',
      section2List1Item2Secondary: formSection2.secondary.list1item2 || '',
      section2List1Item3Secondary: formSection2.secondary.list1item3 || '',
      section2List1Item4Secondary: formSection2.secondary.list1item4 || '',
      section2List1Item5Secondary: formSection2.secondary.list1item5 || '',
      section2List2Item1Secondary: formSection2.secondary.list2item1 || '',
      section2List2Item2Secondary: formSection2.secondary.list2item2 || '',
      section2List2Item3Secondary: formSection2.secondary.list2item3 || '',
      section2List2Item4Secondary: formSection2.secondary.list2item4 || '',
      // section2List2Item5En: formSection2.en.list2item5 || '',

      section2MainHeadingPrimary: formSection2.primary.mainHeading || '',
      section2Button1TextPrimary: formSection2.primary.sec2button1Text || '',
      section2Button2TextPrimary: formSection2.primary.sec2button2Text || '',
      section2List1Item1Primary: formSection2.primary.list1item1 || '',
      section2List1Item2Primary: formSection2.primary.list1item2 || '',
      section2List1Item3Primary: formSection2.primary.list1item3 || '',
      section2List1Item4Primary: formSection2.primary.list1item4 || '',
      section2List1Item5Primary: formSection2.primary.list1item5 || '',
      section2List2Item1Primary: formSection2.primary.list2item1 || '',
      section2List2Item2Primary: formSection2.primary.list2item2 || '',
      section2List2Item3Primary: formSection2.primary.list2item3 || '',
      section2List2Item4Primary: formSection2.primary.list2item4 || '',
      // section2List2Item5Cg: formSection2.cg.list2item5 || '',

      section3MainHeadingSecondary: formSection3.secondary.sec3MainHeading || '',
      section3Card1TitleSecondary: formSection3.secondary.sec3Card1Title || '',
      section3Card1SubtitleSecondary: formSection3.secondary.sec3Card1SubTitle || '',
      section3Card2TitleSecondary: formSection3.secondary.sec3Card2Title || '',
      section3Card2SubtitleSecondary: formSection3.secondary.sec3Card2SubTitle || '',
      section3Card3TitleSecondary: formSection3.secondary.sec3Card3Title || '',
      section3Card3SubtitleSecondary: formSection3.secondary.sec3Card3SubTitle || '',
      section3Card4TitleSecondary: formSection3.secondary.sec3Card4Title || '',
      section3Card4SubtitleSecondary: formSection3.secondary.sec3Card4SubTitle || '',
      section3Card5TitleSecondary: formSection3.secondary.sec3Card5Title || '',
      section3Card5SubtitleSecondary: formSection3.secondary.sec3Card5SubTitle || '',
      section3Card6TitleSecondary: formSection3.secondary.sec3Card6Title || '',
      section3Card6SubtitleSecondary: formSection3.secondary.sec3Card6SubTitle || '',

      section3MainHeadingPrimary: formSection3.primary.sec3MainHeading || '',
      section3Card1TitlePrimary: formSection3.primary.sec3Card1Title || '',
      section3Card1SubtitlePrimary: formSection3.primary.sec3Card1SubTitle || '',
      section3Card2TitlePrimary: formSection3.primary.sec3Card2Title || '',
      section3Card2SubtitlePrimary: formSection3.primary.sec3Card2SubTitle || '',
      section3Card3TitlePrimary: formSection3.primary.sec3Card3Title || '',
      section3Card3SubtitlePrimary: formSection3.primary.sec3Card3SubTitle || '',
      section3Card4TitlePrimary: formSection3.primary.sec3Card4Title || '',
      section3Card4SubtitlePrimary: formSection3.primary.sec3Card4SubTitle || '',
      section3Card5TitlePrimary: formSection3.primary.sec3Card5Title || '',
      section3Card5SubtitlePrimary: formSection3.primary.sec3Card5SubTitle || '',
      section3Card6TitlePrimary: formSection3.primary.sec3Card6Title || '',
      section3Card6SubtitlePrimary: formSection3.primary.sec3Card6SubTitle || '',

      section4VideoUrlSecondary: formSection4.secondary.sec4VideoUrl || '',
      section4StepsTitleSecondaryPart1: formSection4.secondary.sec4StepsTitlePart1 || '',
      section4StepsTitleSecondaryPart2: formSection4.secondary.sec4StepsTitlePart2 || '',
      section4Step1TitleSecondary: formSection4.secondary.sec4Step1Title || '',
      section4Step1TextSecondary: formSection4.secondary.sec4Step1Text || '',
      section4Step2TitleSecondary: formSection4.secondary.sec4Step2Title || '',
      section4Step2TextSecondary: formSection4.secondary.sec4Step2Text || '',
      section4Step3TitleSecondary: formSection4.secondary.sec4Step3Title || '',
      section4Step3TextSecondary: formSection4.secondary.sec4Step3Text || '',
      section4StepsButtonTextSecondary: formSection4.secondary.sec4StepsButtonText || '',
      section4StepsButtonUrlSecondary: formSection4.secondary.sec4StepsButtonUrl || '',

      section4VideoUrlPrimary: formSection4.primary.sec4VideoUrl || '',
      section4StepsTitlePrimaryPart1: formSection4.primary.sec4StepsTitlePart1 || '',
      section4StepsTitlePrimaryPart2: formSection4.primary.sec4StepsTitlePart2 || '',
      section4Step1TitlePrimary: formSection4.primary.sec4Step1Title || '',
      section4Step1TextPrimary: formSection4.primary.sec4Step1Text || '',
      section4Step2TitlePrimary: formSection4.primary.sec4Step2Title || '',
      section4Step2TextPrimary: formSection4.primary.sec4Step2Text || '',
      section4Step3TitlePrimary: formSection4.primary.sec4Step3Title || '',
      section4Step3TextPrimary: formSection4.primary.sec4Step3Text || '',
      section4StepsButtonTextPrimary: formSection4.primary.sec4StepsButtonText || '',
      section4StepsButtonUrlPrimary: formSection4.primary.sec4StepsButtonUrl || '',

      // IMAGES
      section1Card1IconSecondary: hpImages.section1Icon1Secondary || null,
      section1Card2IconSecondary: hpImages.section1Icon2Secondary || null,
      section2Image1Secondary: hpImages.section2Image1Secondary || null,
      section2Image2Secondary: hpImages.section2Image2Secondary || null,
      section3Icon1Secondary: hpImages.section3Icon1Secondary || null,
      section3Icon2Secondary: hpImages.section3Icon2Secondary || null,
      section3Icon3Secondary: hpImages.section3Icon3Secondary || null,
      section3Icon4Secondary: hpImages.section3Icon4Secondary || null,
      section3Icon5Secondary: hpImages.section3Icon5Secondary || null,
      section3Icon6Secondary: hpImages.section3Icon6Secondary || null,
      section4Image1Secondary: hpImages.section4Image1Secondary || null,

      section1Card1IconPrimary: hpImages.section1Icon1Primary || null,
      section1Card2IconPrimary: hpImages.section1Icon2Primary || null,
      section2Image1Primary: hpImages.section2Image1Primary || null,
      section2Image2Primary: hpImages.section2Image2Primary || null,
      section3Icon1Primary: hpImages.section3Icon1Primary || null,
      section3Icon2Primary: hpImages.section3Icon2Primary || null,
      section3Icon3Primary: hpImages.section3Icon3Primary || null,
      section3Icon4Primary: hpImages.section3Icon4Primary || null,
      section3Icon5Primary: hpImages.section3Icon5Primary || null,
      section3Icon6Primary: hpImages.section3Icon6Primary || null,
      section4Image1Primary: hpImages.section4Image1Primary || null,

      countryCode: user.countryCodes[0] || null,
    };
    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createHpDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateHpDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: t('dataSavedSuccess'),
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getHpDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (!initialData) return;
    const data = initialData[0];
    setFormSection1((prevState) => constructInitialFormSection1State(data, prevState));
    setFormSection2((prevState) => constructInitialFormSection2State(data, prevState));
    setFormSection3((prevState) => constructInitialFormSection3State(data, prevState));
    setFormSection4((prevState) => constructInitialFormSection4State(data, prevState));
    setHpImages((prevState) => constructInitialHpImagesState(data, prevState));
    setHpImagesPreview((prevState) => constructInitialHpImagesPreviewState(data, prevState));
  }, [initialData]);
  // HANDLE FETCH AND SET INITIAL DATA

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  return (
    <div className="cms cms-homepage">
      <h2 className="page-title">{t('cmsHomepage')}</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* SECTION 1 (Companies - Partners) */}
            <Panel header={t('section1CompaniesPartners')} key="1">
              <CmsHomepageSection1 handleInputChangeFormSection1={handleInputChangeFormSection1} formSection1={formSection1} setHpImages={setHpImages} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} formSection1Errors={formSection1Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 1 (Companies - Partners) */}
            {/* SECTION 2 (Everyone wins with Fitpass!) */}
            <Panel header={t('section2EveryoneWins')} key="2">
              <CmsHomepageSection2 formSection2={formSection2} handleInputChangeFormSection2={handleInputChangeFormSection2} setHpImages={setHpImages} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} formSection2Errors={formSection2Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 2 (Everyone wins with Fitpass!) */}
            {/* SECTION 3 (Discover all Fitpass benefits!) */}
            <Panel header={t('section3DiscoverFitpassBenefits')} key="3">
              <CmsHomepageSection3 formSection3={formSection3} handleInputChangeFormSection3={handleInputChangeFormSection3} setHpImages={setHpImages} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} formSection3Errors={formSection3Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 3 (Discover all Fitpass benefits!) */}
            {/* SECTION 4 (Get Fitpass in 3 simple steps) */}
            <Panel header={t('section4GetFitpass3Steps')} key="4">
              <CmsHomepageSection4 formSection4={formSection4} handleInputChangeFormSection4={handleInputChangeFormSection4} setHpImages={setHpImages} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} formSection4Errors={formSection4Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 4 (Get Fitpass in 3 simple steps) */}
          </Collapse>
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? t('savingChanges') : t('saveChanges')}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};

export default CmsHomepage;
