import React, { useState, useCallback, useEffect } from 'react';
import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import CmsForCompaniesSection1 from './sections/CmsForCompaniesSection1';
import CmsForCompaniesSection2 from './sections/CmsForCompaniesSection2';
import CmsForCompaniesSection3 from './sections/CmsForCompaniesSection3';
import CmsForCompaniesHeader from './sections/CmsForCompaniesHeader';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { useAuth } from '../../../context/useAuth';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { PERMISSIONS } from '../../../utils/permissions';
import { SERVER_URL } from '../../../config/index';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { cmsTranslations } from '../../../components/cmsTranslations';
import { useTranslation } from 'react-i18next';

import CheckboxCms from '../../../components/checkbox-cms';

const CmsForCompanies = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  let countryCodeExtension = null;
  const getCountryExtensionFromCountryCode = (countryCode) => {
    switch (countryCode) {
      case '66a76a9f198a9e71fd572334':
        countryCodeExtension = 'sr';
        break;
      case '6740868b48686db16c24f818':
        countryCodeExtension = 'cg';
        break;
    }
  };
  const countryExtension = getCountryExtensionFromCountryCode(user?.countryCodes[0]) || null;
  const translations = cmsTranslations[countryCodeExtension];

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // IMAGES HANDLE
  const [fcImages, setFcImages] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',

    section1Image1Secondary: '',
    section1Image2Secondary: '',
    section1Image3Secondary: '',
    section2ImageSecondary: '',
    section3Image1Secondary: '',
    section3Image2Secondary: '',
    section3Image3Secondary: '',
    section3Image4Secondary: '',

    section1Image1Primary: '',
    section1Image2Primary: '',
    section1Image3Primary: '',
    section2ImagePrimary: '',
    section3Image1Primary: '',
    section3Image2Primary: '',
    section3Image3Primary: '',
    section3Image4Primary: '',
  });
  const [fcImagesPreview, setFcImagesPreview] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',

    section1Image1Secondary: '',
    section1Image2Secondary: '',
    section1Image3Secondary: '',
    section2ImageSecondary: '',
    section3Image1Secondary: '',
    section3Image2Secondary: '',
    section3Image3Secondary: '',
    section3Image4Secondary: '',

    section1Image1Primary: '',
    section1Image2Primary: '',
    section1Image3Primary: '',
    section2ImagePrimary: '',
    section3Image1Primary: '',
    section3Image2Primary: '',
    section3Image3Primary: '',
    section3Image4Primary: '',
  });
  const [fcImagesErrors, setFcImagesErrors] = React.useState({
    sectionHeaderImageSecondaryDesktop: false,
    sectionHeaderImagePrimaryDesktop: false,
    sectionHeaderImageSecondaryTablet: false,
    sectionHeaderImagePrimaryTablet: false,
    sectionHeaderImageSecondaryMobile: false,
    sectionHeaderImagePrimaryMobile: false,

    section1Image1Secondary: false,
    section1Image2Secondary: false,
    section1Image3Secondary: false,
    section2ImageSecondary: false,
    section3Image1Secondary: false,
    section3Image2Secondary: false,
    section3Image3Secondary: false,
    section3Image4Secondary: false,

    section1Image1Primary: false,
    section1Image2Primary: false,
    section1Image3Primary: false,
    section2ImagePrimary: false,
    section3Image1Primary: false,
    section3Image2Primary: false,
    section3Image3Primary: false,
    section3Image4Primary: false,
  });
  // IMAGES HANDLE
  // HEADER HANDLE
  const [formSectionHeader, setFormSectionHeader] = React.useState({
    secondary: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
    primary: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
  });
  const [formSectionHeaderErrors, setFormSectionHeaderErrors] = React.useState({
    secondary: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
    primary: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
  });
  const handleInputChangeFormSectionHeader = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    setFormSectionHeader((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  // const onChangeTabSectionHeader = useCallback(() => {}, []);
  // HEADER HANDLE
  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });
  // SECTION 1 HANDLE
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    secondary: {
      sec1MainHeadingPart1: '',
      sec1MainHeadingPart2: '',
      sec1Benefit1Text: '',
      sec1Benefit1Resource: '',
      sec1Benefit2Text: '',
      sec1Benefit2Resource: '',
      sec1Benefit3Text: '',
      sec1Benefit3Resource: '',
    },
    primary: {
      sec1MainHeadingPart1: '',
      sec1MainHeadingPart2: '',
      sec1Benefit1Text: '',
      sec1Benefit1Resource: '',
      sec1Benefit2Text: '',
      sec1Benefit2Resource: '',
      sec1Benefit3Text: '',
      sec1Benefit3Resource: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    secondary: {
      sec1MainHeadingPart1: false,
      sec1MainHeadingPart2: false,
      sec1Benefit1Text: false,
      sec1Benefit1Resource: false,
      sec1Benefit2Text: false,
      sec1Benefit2Resource: false,
      sec1Benefit3Text: false,
      sec1Benefit3Resource: false,
    },
    primary: {
      sec1MainHeadingPart1: false,
      sec1MainHeadingPart2: false,
      sec1Benefit1Text: false,
      sec1Benefit1Resource: false,
      sec1Benefit2Text: false,
      sec1Benefit2Resource: false,
      sec1Benefit3Text: false,
      sec1Benefit3Resource: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  // const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // SECTION 2 HANDLE
  const [formSection2, setFormSection2] = React.useState({
    hide: false,
    secondary: {
      sec2MainHeadingPart1: '',
      sec2MainHeadingPart2: '',
      sec2Advantage1Title: '',
      sec2Advantage1Text: '',
      sec2Advantage2Title: '',
      sec2Advantage2Text: '',
      sec2Advantage3Title: '',
      sec2Advantage3Text: '',
      sec2Advantage4Title: '',
      sec2Advantage4Text: '',
    },
    primary: {
      sec2MainHeadingPart1: '',
      sec2MainHeadingPart2: '',
      sec2Advantage1Title: '',
      sec2Advantage1Text: '',
      sec2Advantage2Title: '',
      sec2Advantage2Text: '',
      sec2Advantage3Title: '',
      sec2Advantage3Text: '',
      sec2Advantage4Title: '',
      sec2Advantage4Text: '',
    },
  });
  const [formSection2Errors, setFormSection2Errors] = React.useState({
    secondary: {
      sec2MainHeadingPart1: false,
      sec2MainHeadingPart2: false,
      sec2Advantage1Title: false,
      sec2Advantage1Text: false,
      sec2Advantage2Title: false,
      sec2Advantage2Text: false,
      sec2Advantage3Title: false,
      sec2Advantage3Text: false,
      sec2Advantage4Title: false,
      sec2Advantage4Text: false,
    },
    primary: {
      sec2MainHeadingPart1: false,
      sec2MainHeadingPart2: false,
      sec2Advantage1Title: false,
      sec2Advantage1Text: false,
      sec2Advantage2Title: false,
      sec2Advantage2Text: false,
      sec2Advantage3Title: false,
      sec2Advantage3Text: false,
      sec2Advantage4Title: false,
      sec2Advantage4Text: false,
    },
  });
  const handleInputChangeFormSection2 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section2_hide') {
      setFormSection2((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection2((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  // const onChangeTabSection2 = useCallback(() => {}, []);
  // SECTION 2 HANDLE

  // SECTION 3 HANDLE
  const [formSection3, setFormSection3] = React.useState({
    hide: false,
    secondary: {
      sec3MainHeading: '',
      sec3Card1Title: '',
      sec3Card1Text: '',
      sec3Card2Title: '',
      sec3Card2Text: '',
      sec3Card3Title: '',
      sec3Card3Text: '',
      sec3Card4Title: '',
      sec3Card4Text: '',
    },
    primary: {
      sec3MainHeading: '',
      sec3Card1Title: '',
      sec3Card1Text: '',
      sec3Card2Title: '',
      sec3Card2Text: '',
      sec3Card3Title: '',
      sec3Card3Text: '',
      sec3Card4Title: '',
      sec3Card4Text: '',
    },
  });
  const [formSection3Errors, setFormSection3Errors] = React.useState({
    secondary: {
      sec3MainHeading: false,
      sec3Card1Title: false,
      sec3Card1Text: false,
      sec3Card2Title: false,
      sec3Card2Text: false,
      sec3Card3Title: false,
      sec3Card3Text: false,
      sec3Card4Title: false,
      sec3Card4Text: false,
    },
    primary: {
      sec3MainHeading: false,
      sec3Card1Title: false,
      sec3Card1Text: false,
      sec3Card2Title: false,
      sec3Card2Text: false,
      sec3Card3Title: false,
      sec3Card3Text: false,
      sec3Card4Title: false,
      sec3Card4Text: false,
    },
  });
  const handleInputChangeFormSection3 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section3_hide') {
      setFormSection3((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection3((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  // const onChangeTabSection3 = useCallback(() => {}, []);
  // SECTION 3 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSection2Errors = { ...formSection2Errors };
    let newFormSection3Errors = { ...formSection3Errors };
    let newFormSectionHeaderErrors = { ...formSectionHeaderErrors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.secondary).forEach((key) => {
      if (!formSection1.secondary[key]) {
        newFormSection1Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.secondary[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.primary).forEach((key) => {
      if (!formSection1.primary[key]) {
        newFormSection1Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.primary[key] = false;
      }
    });

    // Validate Section 2 (EN)
    Object.keys(formSection2.secondary).forEach((key) => {
      if (!formSection2.secondary[key]) {
        newFormSection2Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.secondary[key] = false;
      }
    });

    // Validate Section 2 (CG)
    Object.keys(formSection2.primary).forEach((key) => {
      if (!formSection2.primary[key]) {
        newFormSection2Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.primary[key] = false;
      }
    });

    // Validate Section 3 (EN)
    Object.keys(formSection3.secondary).forEach((key) => {
      if (!formSection3.secondary[key]) {
        newFormSection3Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.secondary[key] = false;
      }
    });

    // Validate Section 3 (CG)
    Object.keys(formSection3.primary).forEach((key) => {
      if (!formSection3.primary[key]) {
        newFormSection3Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.primary[key] = false;
      }
    });

    // Validate Section Header (EN)
    Object.keys(formSectionHeader.secondary).forEach((key) => {
      if (!formSectionHeader.secondary[key]) {
        newFormSectionHeaderErrors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.secondary[key] = false;
      }
    });

    // Validate Section Header (CG)
    Object.keys(formSectionHeader.primary).forEach((key) => {
      if (!formSectionHeader.primary[key]) {
        newFormSectionHeaderErrors.primary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.primary[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(fcImages).forEach((key) => {
      if (fcImages[key] === null || fcImages[key] === '') {
        setFcImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setFcImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSection2Errors(newFormSection2Errors);
    setFormSection3Errors(newFormSection3Errors);
    setFormSectionHeaderErrors(newFormSectionHeaderErrors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      fcHide: formGeneralSettings.hide || false,

      section1Hide: formSection1.hide || false,
      section2Hide: formSection2.hide || false,
      section3Hide: formSection3.hide || false,

      // header
      sectionHeaderMainHeadingPart1Secondary: formSectionHeader.secondary.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Secondary: formSectionHeader.secondary.headMainHeadingPart2 || '',

      sectionHeaderMainHeadingPart1Primary: formSectionHeader.primary.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Primary: formSectionHeader.primary.headMainHeadingPart2 || '',

      section1MainHeadingPart1Secondary: formSection1.secondary.sec1MainHeadingPart1 || '',
      section1MainHeadingPart2Secondary: formSection1.secondary.sec1MainHeadingPart2 || '',
      section1Benefit1TextSecondary: formSection1.secondary.sec1Benefit1Text || '',
      section1Benefit1ResourceSecondary: formSection1.secondary.sec1Benefit1Resource || '',
      section1Benefit2TextSecondary: formSection1.secondary.sec1Benefit2Text || '',
      section1Benefit2ResourceSecondary: formSection1.secondary.sec1Benefit2Resource || '',
      section1Benefit3TextSecondary: formSection1.secondary.sec1Benefit3Text || '',
      section1Benefit3ResourceSecondary: formSection1.secondary.sec1Benefit3Resource || '',

      section1MainHeadingPart1Primary: formSection1.primary.sec1MainHeadingPart1 || '',
      section1MainHeadingPart2Primary: formSection1.primary.sec1MainHeadingPart2 || '',
      section1Benefit1TextPrimary: formSection1.primary.sec1Benefit1Text || '',
      section1Benefit1ResourcePrimary: formSection1.primary.sec1Benefit1Resource || '',
      section1Benefit2TextPrimary: formSection1.primary.sec1Benefit2Text || '',
      section1Benefit2ResourcePrimary: formSection1.primary.sec1Benefit2Resource || '',
      section1Benefit3TextPrimary: formSection1.primary.sec1Benefit3Text || '',
      section1Benefit3ResourcePrimary: formSection1.primary.sec1Benefit3Resource || '',

      section2MainHeadingPart1Secondary: formSection2.secondary.sec2MainHeadingPart1 || '',
      section2MainHeadingPart2Secondary: formSection2.secondary.sec2MainHeadingPart2 || '',
      section2Advantage1TitleSecondary: formSection2.secondary.sec2Advantage1Title || '',
      section2Advantage1TextSecondary: formSection2.secondary.sec2Advantage1Text || '',
      section2Advantage2TitleSecondary: formSection2.secondary.sec2Advantage2Title || '',
      section2Advantage2TextSecondary: formSection2.secondary.sec2Advantage2Text || '',
      section2Advantage3TitleSecondary: formSection2.secondary.sec2Advantage3Title || '',
      section2Advantage3TextSecondary: formSection2.secondary.sec2Advantage3Text || '',
      section2Advantage4TitleSecondary: formSection2.secondary.sec2Advantage4Title || '',
      section2Advantage4TextSecondary: formSection2.secondary.sec2Advantage4Text || '',

      section2MainHeadingPart1Primary: formSection2.primary.sec2MainHeadingPart1 || '',
      section2MainHeadingPart2Primary: formSection2.primary.sec2MainHeadingPart2 || '',
      section2Advantage1TitlePrimary: formSection2.primary.sec2Advantage1Title || '',
      section2Advantage1TextPrimary: formSection2.primary.sec2Advantage1Text || '',
      section2Advantage2TitlePrimary: formSection2.primary.sec2Advantage2Title || '',
      section2Advantage2TextPrimary: formSection2.primary.sec2Advantage2Text || '',
      section2Advantage3TitlePrimary: formSection2.primary.sec2Advantage3Title || '',
      section2Advantage3TextPrimary: formSection2.primary.sec2Advantage3Text || '',
      section2Advantage4TitlePrimary: formSection2.primary.sec2Advantage4Title || '',
      section2Advantage4TextPrimary: formSection2.primary.sec2Advantage4Text || '',

      section3MainHeadingSecondary: formSection3.secondary.sec3MainHeading || '',
      section3Card1TitleSecondary: formSection3.secondary.sec3Card1Title || '',
      section3Card1TextSecondary: formSection3.secondary.sec3Card1Text || '',
      section3Card2TitleSecondary: formSection3.secondary.sec3Card2Title || '',
      section3Card2TextSecondary: formSection3.secondary.sec3Card2Text || '',
      section3Card3TitleSecondary: formSection3.secondary.sec3Card3Title || '',
      section3Card3TextSecondary: formSection3.secondary.sec3Card3Text || '',
      section3Card4TitleSecondary: formSection3.secondary.sec3Card4Title || '',
      section3Card4TextSecondary: formSection3.secondary.sec3Card4Text || '',

      section3MainHeadingPrimary: formSection3.primary.sec3MainHeading || '',
      section3Card1TitlePrimary: formSection3.primary.sec3Card1Title || '',
      section3Card1TextPrimary: formSection3.primary.sec3Card1Text || '',
      section3Card2TitlePrimary: formSection3.primary.sec3Card2Title || '',
      section3Card2TextPrimary: formSection3.primary.sec3Card2Text || '',
      section3Card3TitlePrimary: formSection3.primary.sec3Card3Title || '',
      section3Card3TextPrimary: formSection3.primary.sec3Card3Text || '',
      section3Card4TitlePrimary: formSection3.primary.sec3Card4Title || '',
      section3Card4TextPrimary: formSection3.primary.sec3Card4Text || '',

      // images
      sectionHeaderImageSecondaryDesktop: fcImages.sectionHeaderImageSecondaryDesktop || null,
      sectionHeaderImagePrimaryDesktop: fcImages.sectionHeaderImagePrimaryDesktop || null,
      sectionHeaderImageSecondaryTablet: fcImages.sectionHeaderImageSecondaryTablet || null,
      sectionHeaderImagePrimaryTablet: fcImages.sectionHeaderImagePrimaryTablet || null,
      sectionHeaderImageSecondaryMobile: fcImages.sectionHeaderImageSecondaryMobile || null,
      sectionHeaderImagePrimaryMobile: fcImages.sectionHeaderImagePrimaryMobile || null,

      section1Image1Secondary: fcImages.section1Image1Secondary || null,
      section1Image2Secondary: fcImages.section1Image2Secondary || null,
      section1Image3Secondary: fcImages.section1Image3Secondary || null,
      section2ImageSecondary: fcImages.section2ImageSecondary || null,
      section3Image1Secondary: fcImages.section3Image1Secondary || null,
      section3Image2Secondary: fcImages.section3Image2Secondary || null,
      section3Image3Secondary: fcImages.section3Image3Secondary || null,
      section3Image4Secondary: fcImages.section3Image4Secondary || null,

      section1Image1Primary: fcImages.section1Image1Primary || null,
      section1Image2Primary: fcImages.section1Image2Primary || null,
      section1Image3Primary: fcImages.section1Image3Primary || null,
      section2ImagePrimary: fcImages.section2ImagePrimary || null,
      section3Image1Primary: fcImages.section3Image1Primary || null,
      section3Image2Primary: fcImages.section3Image2Primary || null,
      section3Image3Primary: fcImages.section3Image3Primary || null,
      section3Image4Primary: fcImages.section3Image4Primary || null,

      countryCode: user?.countryCodes?.[0] || '',
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createFcDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateFcDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getFcDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialData) return;
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.fcHide || false,
    }));
    setFormSectionHeader((prevState) => ({
      ...prevState,
      secondary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Secondary || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Secondary || '',
      },
      primary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Primary || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Primary || '',
      },
    }));
    setFormSection1((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section1Hide || false,
      secondary: {
        sec1MainHeadingPart1: initialData[0]?.section1MainHeadingPart1Secondary || '',
        sec1MainHeadingPart2: initialData[0]?.section1MainHeadingPart2Secondary || '',
        sec1Benefit1Text: initialData[0]?.section1Benefit1TextSecondary || '',
        sec1Benefit1Resource: initialData[0]?.section1Benefit1ResourceSecondary || '',
        sec1Benefit2Text: initialData[0]?.section1Benefit2TextSecondary || '',
        sec1Benefit2Resource: initialData[0]?.section1Benefit2ResourceSecondary || '',
        sec1Benefit3Text: initialData[0]?.section1Benefit3TextSecondary || '',
        sec1Benefit3Resource: initialData[0]?.section1Benefit3ResourceSecondary || '',
      },
      primary: {
        sec1MainHeadingPart1: initialData[0]?.section1MainHeadingPart1Primary || '',
        sec1MainHeadingPart2: initialData[0]?.section1MainHeadingPart2Primary || '',
        sec1Benefit1Text: initialData[0]?.section1Benefit1TextPrimary || '',
        sec1Benefit1Resource: initialData[0]?.section1Benefit1ResourcePrimary || '',
        sec1Benefit2Text: initialData[0]?.section1Benefit2TextPrimary || '',
        sec1Benefit2Resource: initialData[0]?.section1Benefit2ResourcePrimary || '',
        sec1Benefit3Text: initialData[0]?.section1Benefit3TextPrimary || '',
        sec1Benefit3Resource: initialData[0]?.section1Benefit3ResourcePrimary || '',
      },
    }));
    setFormSection2((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section2Hide || false,
      secondary: {
        sec2MainHeadingPart1: initialData[0]?.section2MainHeadingPart1Secondary || '',
        sec2MainHeadingPart2: initialData[0]?.section2MainHeadingPart2Secondary || '',
        sec2Advantage1Title: initialData[0]?.section2Advantage1TitleSecondary || '',
        sec2Advantage1Text: initialData[0]?.section2Advantage1TextSecondary || '',
        sec2Advantage2Title: initialData[0]?.section2Advantage2TitleSecondary || '',
        sec2Advantage2Text: initialData[0]?.section2Advantage2TextSecondary || '',
        sec2Advantage3Title: initialData[0]?.section2Advantage3TitleSecondary || '',
        sec2Advantage3Text: initialData[0]?.section2Advantage3TextSecondary || '',
        sec2Advantage4Title: initialData[0]?.section2Advantage4TitleSecondary || '',
        sec2Advantage4Text: initialData[0]?.section2Advantage4TextSecondary || '',
      },
      primary: {
        sec2MainHeadingPart1: initialData[0]?.section2MainHeadingPart1Primary || '',
        sec2MainHeadingPart2: initialData[0]?.section2MainHeadingPart2Primary || '',
        sec2Advantage1Title: initialData[0]?.section2Advantage1TitlePrimary || '',
        sec2Advantage1Text: initialData[0]?.section2Advantage1TextPrimary || '',
        sec2Advantage2Title: initialData[0]?.section2Advantage2TitlePrimary || '',
        sec2Advantage2Text: initialData[0]?.section2Advantage2TextPrimary || '',
        sec2Advantage3Title: initialData[0]?.section2Advantage3TitlePrimary || '',
        sec2Advantage3Text: initialData[0]?.section2Advantage3TextPrimary || '',
        sec2Advantage4Title: initialData[0]?.section2Advantage4TitlePrimary || '',
        sec2Advantage4Text: initialData[0]?.section2Advantage4TextPrimary || '',
      },
    }));
    setFormSection3((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section3Hide || false,
      secondary: {
        sec3MainHeading: initialData[0]?.section3MainHeadingSecondary || '',
        sec3Card1Title: initialData[0]?.section3Card1TitleSecondary || '',
        sec3Card1Text: initialData[0]?.section3Card1TextSecondary || '',
        sec3Card2Title: initialData[0]?.section3Card2TitleSecondary || '',
        sec3Card2Text: initialData[0]?.section3Card2TextSecondary || '',
        sec3Card3Title: initialData[0]?.section3Card3TitleSecondary || '',
        sec3Card3Text: initialData[0]?.section3Card3TextSecondary || '',
        sec3Card4Title: initialData[0]?.section3Card4TitleSecondary || '',
        sec3Card4Text: initialData[0]?.section3Card4TextSecondary || '',
      },
      primary: {
        sec3MainHeading: initialData[0]?.section3MainHeadingPrimary || '',
        sec3Card1Title: initialData[0]?.section3Card1TitlePrimary || '',
        sec3Card1Text: initialData[0]?.section3Card1TextPrimary || '',
        sec3Card2Title: initialData[0]?.section3Card2TitlePrimary || '',
        sec3Card2Text: initialData[0]?.section3Card2TextPrimary || '',
        sec3Card3Title: initialData[0]?.section3Card3TitlePrimary || '',
        sec3Card3Text: initialData[0]?.section3Card3TextPrimary || '',
        sec3Card4Title: initialData[0]?.section3Card4TitlePrimary || '',
        sec3Card4Text: initialData[0]?.section3Card4TextPrimary || '',
      },
    }));
    setFcImages((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?._id || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?._id || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?._id || null,
      section1Image1Secondary: initialData[0]?.section1Image1Secondary?._id || null,
      section1Image2Secondary: initialData[0]?.section1Image2Secondary?._id || null,
      section1Image3Secondary: initialData[0]?.section1Image3Secondary?._id || null,
      section2ImageSecondary: initialData[0]?.section2ImageSecondary?._id || null,
      section3Image1Secondary: initialData[0]?.section3Image1Secondary?._id || null,
      section3Image2Secondary: initialData[0]?.section3Image2Secondary?._id || null,
      section3Image3Secondary: initialData[0]?.section3Image3Secondary?._id || null,
      section3Image4Secondary: initialData[0]?.section3Image4Secondary?._id || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?._id || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?._id || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?._id || null,
      section1Image1Primary: initialData[0]?.section1Image1Primary?._id || null,
      section1Image2Primary: initialData[0]?.section1Image2Primary?._id || null,
      section1Image3Primary: initialData[0]?.section1Image3Primary?._id || null,
      section2ImagePrimary: initialData[0]?.section2ImagePrimary?._id || null,
      section3Image1Primary: initialData[0]?.section3Image1Primary?._id || null,
      section3Image2Primary: initialData[0]?.section3Image2Primary?._id || null,
      section3Image3Primary: initialData[0]?.section3Image3Primary?._id || null,
      section3Image4Primary: initialData[0]?.section3Image4Primary?._id || null,
    }));
    setFcImagesPreview((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?.url || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?.url || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?.url || null,
      section1Image1Secondary: initialData[0]?.section1Image1Secondary?.url || null,
      section1Image2Secondary: initialData[0]?.section1Image2Secondary?.url || null,
      section1Image3Secondary: initialData[0]?.section1Image3Secondary?.url || null,
      section2ImageSecondary: initialData[0]?.section2ImageSecondary?.url || null,
      section3Image1Secondary: initialData[0]?.section3Image1Secondary?.url || null,
      section3Image2Secondary: initialData[0]?.section3Image2Secondary?.url || null,
      section3Image3Secondary: initialData[0]?.section3Image3Secondary?.url || null,
      section3Image4Secondary: initialData[0]?.section3Image4Secondary?.url || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?.url || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?.url || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?.url || null,
      section1Image1Primary: initialData[0]?.section1Image1Primary?.url || null,
      section1Image2Primary: initialData[0]?.section1Image2Primary?.url || null,
      section1Image3Primary: initialData[0]?.section1Image3Primary?.url || null,
      section2ImagePrimary: initialData[0]?.section2ImagePrimary?.url || null,
      section3Image1Primary: initialData[0]?.section3Image1Primary?.url || null,
      section3Image2Primary: initialData[0]?.section3Image2Primary?.url || null,
      section3Image3Primary: initialData[0]?.section3Image3Primary?.url || null,
      section3Image4Primary: initialData[0]?.section3Image4Primary?.url || null,
    }));
  }, [initialData]);

  useEffect(() => {
    handleFetchData();
  }, []);
  // HANDLE FETCH AND SET INITIAL DATA

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'fc_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  return (
    <div className="cms cms-for-companies">
      <h2 className="page-title">CMS For Companies</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* Header (Join the Fitpass Revolution) */}
            <Panel header="HEADER (Join the Fitpass Revolution)" key="1">
              <CmsForCompaniesHeader formSectionHeader={formSectionHeader} handleInputChangeFormSectionHeader={handleInputChangeFormSectionHeader} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} formSectionHeaderErrors={formSectionHeaderErrors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* Header (Join the Fitpass Revolution) */}
            {/* SECTION 1 (Did you know? Why do you need Fitpass as a benefit?) */}
            <Panel header="SECTION 1 (Did you know? Why do you need Fitpass as a benefit?)" key="2">
              <CmsForCompaniesSection1 formSection1={formSection1} handleInputChangeFormSection1={handleInputChangeFormSection1} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} formSection1Errors={formSection1Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 1 (Did you know? Why do you need Fitpass as a benefit?) */}

            {/* SECTION 2 (Count on us Fitpass dedicated client support team will maximise your company wellbeing results!) */}
            <Panel header="SECTION 2 (Count on us Fitpass dedicated client support team will maximise your company wellbeing results!)" key="3">
              <CmsForCompaniesSection2 formSection2={formSection2} handleInputChangeFormSection2={handleInputChangeFormSection2} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} formSection2Errors={formSection2Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 2 (Count on us Fitpass dedicated client support team will maximise your company wellbeing results!) */}

            {/* SECTION 3 (Activate your team - by reducing their spending!) */}
            <Panel header="SECTION 3 (Activate your team - by reducing their spending!)" key="4">
              <CmsForCompaniesSection3 formSection3={formSection3} handleInputChangeFormSection3={handleInputChangeFormSection3} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} formSection3Errors={formSection3Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 3 (Activate your team - by reducing their spending!) */}
          </Collapse>
          <CheckboxCms inputName="fc_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsForCompanies;
