import React, { useState } from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { stateUS } from '../../../../helpers/state';
import Loading from '../../../../components/loading/Loading';
import { useTranslation } from 'react-i18next';
import { Select, Autocomplete, TextField } from '@mui/material';

export const UpdateForm = ({ venues, selectedVenues, handleVenuesChange, handleSearchVenues, updateProps, updateLoading, updateModalFormRef, handleOnSubmit, handleOnChange, handleOnBlur, sleep, updateFormError, updateAddress, handleOnRemoveUpdate, toggleUpdateAddress, sleepAddress, toggleNewPassword, setToggleUpdateAddress, handleOnSaveUpdate, updatePasswordProps, editBtnLoader, setToggleUpdate, setToggleNewPassword, TogglePasswordChecker }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title={t('editMember')}>
      {!updateLoading ? (
        <form
          name="update"
          ref={updateModalFormRef}
          onSubmit={(event) =>
            handleOnSubmit({
              event: event,
              name: 'update',
              state: updateProps,
            })
          }
          className="ant-form ant-form-horizontal"
        >
          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateFirstName">
              <b className="required">{t('firstName')}:</b>
            </label>

            <input name="updateFirstName" id="updateFirstName" value={sleep.updateFirstName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder={t('firstName')} data-cy="" className={`input ${updateFormError.updateFirstNameError ? 'input-error' : ''}`} />
            <p data-cy="" className={`error__onblur ${updateFormError.updateFirstNameError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateLastName">
              <b className="required">{t('lastName')}:</b>
            </label>

            <input name="updateLastName" id="updateLastName" value={sleep.updateLastName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder={t('lastName')} data-cy="" className={`input ${updateFormError.updateLastNameError ? 'input-error' : ''}`} />
            <p data-cy="" className={`error__onblur ${updateFormError.updateLastNameError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateEmail">
              <b className="required">{t('email')}:</b>
            </label>

            <input name="updateEmail" id="updateEmail" value={sleep.updateEmail || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="email" placeholder={t('email')} data-cy="" className={`input ${updateFormError.updateEmailError ? 'input-error' : ''}`} />
            <p data-cy="" className={`error__onblur ${updateFormError.updateEmailError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <p>{t('assignVenue')}:</p>
            <Autocomplete
              multiple
              hiddenlabel={true}
              options={venues}
              getOptionLabel={(option) => option['name']}
              value={selectedVenues}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                handleSearchVenues(newInputValue);
              }}
              // PaperComponent={CustomPaper}
              onChange={handleVenuesChange}
              filterSelectedOptions
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#d9d9d9',
                  borderWidth: 1,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#d9d9d9',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  '& > svg': {
                    // fill: "blue",
                    fontSize: '25px',
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder={t?.cityInput}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#BCBCBC',
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      color: '#666666',
                      fontSize: '14px',
                      opacity: 1,
                    },
                  }}
                />
              )}
            />
          </div>

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updatePhone">
              <b className="required">{t('phone')}:</b>
            </label>

            <input name="updatePhone" id="updatePhone" value={sleep.updatePhone || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="number" placeholder={t('phone')} data-cy="" className={`input ${updateFormError.updatePhoneError ? 'input-error' : ''}`} />
            <p data-cy="" className={`error__onblur ${updateFormError.updatePhoneError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <div className="form-group relative">
            <div className="toggle-new-password">
              <input type="checkbox" id="toggleNewPassword" className="input-checkbox" onClick={() => setToggleNewPassword((prevState) => !prevState)} />
              <label htmlFor="toggleNewPassword">{t('createNewPassword')}</label>
            </div>
          </div>

          {toggleNewPassword === true && <TogglePasswordChecker {...updatePasswordProps} />}

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateStatus">
              <b className="required">{t('status')}:</b>
            </label>

            <select name="updateStatus" id="updateStatus" value={sleep.updateStatus} onChange={handleOnChange} onBlur={handleOnBlur} data-cy="" className={`select select-full ${updateFormError.updateStatusError ? 'input-error' : ''}`}>
              <option value="none">{t('select')}</option>
              <option value="active">{t('active')}</option>
              <option value="inactive">{t('inactive')}</option>
            </select>

            <p data-cy="" className={`error__onblur ${updateFormError.updateStatusError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <div className="form-group form-group-sidebar">
            <button data-cy="modal-update-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdate(false)}>
              <span className="text">{t('cancel')}</span>
            </button>

            {!editBtnLoader ? (
              <button data-cy="modal-update-submit-btn" type="submit" className="btn btn-primary">
                <span className="text">{t('saveChanges')}</span>
              </button>
            ) : (
              <button type="button" className="btn btn-primary-outline">
                <span className="text">{t('saving')}...</span>
              </button>
            )}
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
