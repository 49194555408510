import React, { useEffect, useState } from 'react';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Table = (props) => {
  const { t } = useTranslation();
  // TABLE
  const { data, handleCheckedRows } = props;
  // PAGINATION
  const { pagination } = props;

  // CUSTOM FOR ACTIONS
  const { groceryPermissions } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // HANDLE CHECKED ALL
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // HANDLE CHECKED CURRENT
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckAll(true);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [isCheck, handleCheckedRows]);
  console.log('nyami request data', data);

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            {/* <th>{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateRequests].includes(key.name)) && <Checkbox type='checkbox' name='select-all-employees' id='selectAll' handleClick={handleSelectAll} isChecked={isCheckAll} />}</th> */}
            <th>{t('venueName')}</th>
            <th>{t('reviewedBy')}</th>
            <th>{t('reviewedAt')}</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              console.log('item', item);
              const formatedDate = moment(item.reviewedAt).format('DD/MM/YYYY HH:mm');
              return (
                <tr key={item._id}>
                  {/* <td data-cy={`checkbox-item-employees-${idx}`}>{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateRequests].includes(key.name)) && <Checkbox key={item._id} type='checkbox' name={item._id} id={item._id} handleClick={handleClick} isChecked={isCheck.includes(item._id)} />}</td> */}
                  <td>{item?.newValues?.name}</td>
                  <td>
                    {item?.reviewedBy[0]?.firstName} {item?.reviewedBy[0]?.lastName}
                  </td>
                  <td>{formatedDate}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{t('dataNotFound')}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
