import React, { useState, useCallback, useEffect } from 'react';
import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import CmsAboutUsHeader from './sections/CmsAboutUsHeader';
import CmsAboutUsSection1 from './sections/CmsAboutUsSection1';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { useAuth } from '../../../context/useAuth';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { PERMISSIONS } from '../../../utils/permissions';
import { SERVER_URL } from '../../../config/index';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

import CheckboxCms from '../../../components/checkbox-cms';
import { cmsTranslations } from '../../../components/cmsTranslations';
import { useTranslation } from 'react-i18next';

const CmsAboutUs = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  let countryCodeExtension = null;
  const getCountryExtensionFromCountryCode = (countryCode) => {
    switch (countryCode) {
      case '66a76a9f198a9e71fd572334':
        countryCodeExtension = 'sr';
        break;
      case '6740868b48686db16c24f818':
        countryCodeExtension = 'cg';
        break;
    }
  };
  const countryExtension = getCountryExtensionFromCountryCode(user?.countryCodes[0]) || null;
  const translations = cmsTranslations[countryCodeExtension];

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // IMAGES HANDLE
  const [auImages, setAuImages] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',

    sectionHeroHeaderImageSecondary: '',
    sectionHeroHeaderImagePrimary: '',

    section1Image1Secondary: '',
    section1Image2Secondary: '',

    section1Image1Primary: '',
    section1Image2Primary: '',
  });
  const [auImagesPreview, setAuImagesPreview] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',

    sectionHeroHeaderImageSecondary: '',
    sectionHeroHeaderImagePrimary: '',

    section1Image1Secondary: '',
    section1Image2Secondary: '',

    section1Image1Primary: '',
    section1Image2Primary: '',
  });
  const [auImagesErrors, setAuImagesErrors] = React.useState({
    sectionHeaderImageSecondaryDesktop: false,
    sectionHeaderImagePrimaryDesktop: false,
    sectionHeaderImageSecondaryTablet: false,
    sectionHeaderImagePrimaryTablet: false,
    sectionHeaderImageSecondaryMobile: false,
    sectionHeaderImagePrimaryMobile: false,

    sectionHeroHeaderImageSecondary: false,
    sectionHeroHeaderImagePrimary: false,

    section1Image1Secondary: false,
    section1Image2Secondary: false,

    section1Image1Primary: false,
    section1Image2Primary: false,
  });
  // IMAGES HANDLE

  // HEADER HANDLE
  const [formSectionHeader, setFormSectionHeader] = React.useState({
    secondary: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
    primary: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
  });
  const [formSectionHeaderErrors, setFormSectionHeaderErrors] = React.useState({
    secondary: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
    primary: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
  });
  const handleInputChangeFormSectionHeader = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    setFormSectionHeader((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  // const onChangeTabSectionHeader = useCallback(() => {}, []);
  // HEADER HANDLE

  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });
  // SECTION 1 HANDLE
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    secondary: {
      sec1MainHeading: '',
      sec1Row1Text: '',
      sec1Row1VideoUrl: '',
      sec1Row2Text: '',
      sec1Row3Text: '',
    },
    primary: {
      sec1MainHeading: '',
      sec1Row1Text: '',
      sec1Row1VideoUrl: '',
      sec1Row2Text: '',
      sec1Row3Text: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    secondary: {
      sec1MainHeading: false,
      sec1Row1Text: false,
      sec1RowVideoUrl: false,
      sec1Row2Text: false,
      sec1Row3Text: false,
    },
    primary: {
      sec1MainHeading: false,
      sec1Row1Text: false,
      sec1RowVideoUrl: false,
      sec1Row2Text: false,
      sec1Row3Text: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  // const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSectionHeaderErrors = { ...formSectionHeaderErrors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.secondary).forEach((key) => {
      if (!formSection1.secondary[key]) {
        newFormSection1Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.secondary[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.primary).forEach((key) => {
      if (!formSection1.primary[key]) {
        newFormSection1Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.primary[key] = false;
      }
    });

    // Validate Section Header (EN)
    Object.keys(formSectionHeader.secondary).forEach((key) => {
      if (!formSectionHeader.secondary[key]) {
        newFormSectionHeaderErrors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.secondary[key] = false;
      }
    });

    // Validate Section Header (CG)
    Object.keys(formSectionHeader.primary).forEach((key) => {
      if (!formSectionHeader.primary[key]) {
        newFormSectionHeaderErrors.primary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.primary[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(auImages).forEach((key) => {
      if (auImages[key] === null || auImages[key] === '') {
        setAuImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setAuImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSectionHeaderErrors(newFormSectionHeaderErrors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      auHide: formGeneralSettings.hide || false,

      section1Hide: formSection1.hide || false,

      // header
      sectionHeaderMainHeadingPart1Secondary: formSectionHeader.secondary.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Secondary: formSectionHeader.secondary.headMainHeadingPart2 || '',

      sectionHeaderMainHeadingPart1Primary: formSectionHeader.primary.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Primary: formSectionHeader.primary.headMainHeadingPart2 || '',

      section1MainHeadingSecondary: formSection1.secondary.sec1MainHeading || '',
      section1Row1TextSecondary: formSection1.secondary.sec1Row1Text || '',
      section1Row1VideoUrlSecondary: formSection1.secondary.sec1Row1VideoUrl || '',
      section1Row2TextSecondary: formSection1.secondary.sec1Row2Text || '',
      section1Row3TextSecondary: formSection1.secondary.sec1Row3Text || '',

      section1MainHeadingPrimary: formSection1.primary.sec1MainHeading || '',
      section1Row1TextPrimary: formSection1.primary.sec1Row1Text || '',
      section1Row1VideoUrlPrimary: formSection1.primary.sec1Row1VideoUrl || '',
      section1Row2TextPrimary: formSection1.primary.sec1Row2Text || '',
      section1Row3TextPrimary: formSection1.primary.sec1Row3Text || '',

      // images
      sectionHeaderImageSecondaryDesktop: auImages.sectionHeaderImageSecondaryDesktop || null,
      sectionHeaderImagePrimaryDesktop: auImages.sectionHeaderImagePrimaryDesktop || null,
      sectionHeaderImageSecondaryTablet: auImages.sectionHeaderImageSecondaryTablet || null,
      sectionHeaderImagePrimaryTablet: auImages.sectionHeaderImagePrimaryTablet || null,
      sectionHeaderImageSecondaryMobile: auImages.sectionHeaderImageSecondaryMobile || null,
      sectionHeaderImagePrimaryMobile: auImages.sectionHeaderImagePrimaryMobile || null,

      sectionHeroHeaderImageSecondary: auImages.sectionHeroHeaderImageSecondary || null,
      sectionHeroHeaderImagePrimary: auImages.sectionHeroHeaderImagePrimary || null,

      section1Image1Secondary: auImages.section1Image1Secondary || null,
      section1Image2Secondary: auImages.section1Image2Secondary || null,

      section1Image1Primary: auImages.section1Image1Primary || null,
      section1Image2Primary: auImages.section1Image2Primary || null,

      countryCode: user?.countryCodes?.[0] || '',
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createAuDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateAuDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getAuDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialData) return;
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.auHide || false,
    }));
    setFormSectionHeader((prevState) => ({
      ...prevState,
      secondary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Secondary || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Secondary || '',
      },
      primary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Primary || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Primary || '',
      },
    }));
    setFormSection1((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section1Hide || false,
      secondary: {
        sec1MainHeading: initialData[0]?.section1MainHeadingSecondary || '',
        sec1Row1Text: initialData[0]?.section1Row1TextSecondary || '',
        sec1Row1VideoUrl: initialData[0]?.section1Row1VideoUrlSecondary || '',
        sec1Row2Text: initialData[0]?.section1Row2TextSecondary || '',
        sec1Row3Text: initialData[0]?.section1Row3TextSecondary || '',
      },
      primary: {
        sec1MainHeading: initialData[0]?.section1MainHeadingPrimary || '',
        sec1Row1Text: initialData[0]?.section1Row1TextPrimary || '',
        sec1Row1VideoUrl: initialData[0]?.section1Row1VideoUrlPrimary || '',
        sec1Row2Text: initialData[0]?.section1Row2TextPrimary || '',
        sec1Row3Text: initialData[0]?.section1Row3TextPrimary || '',
      },
    }));

    setAuImages((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?._id || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?._id || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?._id || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?._id || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?._id || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?._id || null,
      section1Image1Secondary: initialData[0]?.section1Image1Secondary?._id || null,
      section1Image2Secondary: initialData[0]?.section1Image2Secondary?._id || null,
      section1Image1Primary: initialData[0]?.section1Image1Primary?._id || null,
      section1Image2Primary: initialData[0]?.section1Image2Primary?._id || null,
      sectionHeroHeaderImageSecondary: initialData[0]?.sectionHeroHeaderImageSecondary?._id || null,
      sectionHeroHeaderImagePrimary: initialData[0]?.sectionHeroHeaderImagePrimary?._id || null,
    }));
    setAuImagesPreview((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?.url || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?.url || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?.url || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?.url || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?.url || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?.url || null,
      section1Image1Secondary: initialData[0]?.section1Image1Secondary?.url || null,
      section1Image2Secondary: initialData[0]?.section1Image2Secondary?.url || null,
      section1Image1Primary: initialData[0]?.section1Image1Primary?.url || null,
      section1Image2Primary: initialData[0]?.section1Image2Primary?.url || null,
      sectionHeroHeaderImageSecondary: initialData[0]?.sectionHeroHeaderImageSecondary?.url || null,
      sectionHeroHeaderImagePrimary: initialData[0]?.sectionHeroHeaderImagePrimary?.url || null,
    }));
  }, [initialData]);

  useEffect(() => {
    handleFetchData();
  }, []);
  // HANDLE FETCH AND SET INITIAL DATA

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'au_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  return (
    <div className="cms cms-for-companies">
      <h2 className="page-title">{t('cmsAboutUs')}</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* Header (Join the Fitpass Revolution) */}
            <Panel header="HEADER (Join the Fitpass Revolution)" key="1">
              <CmsAboutUsHeader formSectionHeader={formSectionHeader} handleInputChangeFormSectionHeader={handleInputChangeFormSectionHeader} auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} formSectionHeaderErrors={formSectionHeaderErrors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* Header (Join the Fitpass Revolution) */}

            {/* SECTION 1 (About us) */}
            <Panel header="SECTION 1 (About us)" key="2">
              <CmsAboutUsSection1 formSection1={formSection1} handleInputChangeFormSection1={handleInputChangeFormSection1} auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} formSection1Errors={formSection1Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 1 (About us) */}
          </Collapse>
          <CheckboxCms inputName="au_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? t('savingChanges') : t('saveChanges')}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsAboutUs;
