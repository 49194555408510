import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1Primary = ({ translations, activeTabKey, handleInputChangeFormSection1, formSection1, formSection1Errors, fcImages, fcImagesErrors, fcImagesPreview, setFcImages }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_sec1MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec1MainHeadingPart1">
            <b>{translations?.mainHeadingPart} 1:</b>
          </label>
          <input id="primary_sec1MainHeadingPart1" type="text" placeholder={`${translations?.mainHeadingPart} 1`} name="primary_sec1MainHeadingPart1" value={formSection1.primary.sec1MainHeadingPart1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec1MainHeadingPart1*/}
        {/*primary_sec1MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec1MainHeadingPart2">
            <b>{translations?.mainHeadingPart} 2:</b>
          </label>
          <input id="primary_sec1MainHeadingPart2" type="text" placeholder={`${translations?.mainHeadingPart} 2`} name="primary_sec1MainHeadingPart2" value={formSection1.primary.sec1MainHeadingPart2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec1MainHeadingPart2*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Benefit 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.benefit} 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>{translations?.image}</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.imageProportions} 1.82/1 (284x156)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section1Image1Primary" />
            </div>
          </div>
          {/*primary_sec1Benefit1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Benefit1Text">
              <b>
                {translations?.benefit} 1 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec1Benefit1Text" type="text" placeholder={`${translations?.benefit} 1 ${translations?.text}:`} name="primary_sec1Benefit1Text" value={formSection1.primary.sec1Benefit1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Benefit1Text ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Benefit1Text*/}
          {/*primary_sec1Benefit1Resource*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Benefit1Resource">
              <b>
                {translations?.benefit} 1 {translations?.resource}:
              </b>
            </label>
            <textarea id="primary_sec1Benefit1Resource" type="text" placeholder={`${translations?.benefit} 1 ${translations?.resource}:`} name="primary_sec1Benefit1Resource" value={formSection1.primary.sec1Benefit1Resource} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Benefit1Resource ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Benefit1Resource*/}
        </div>
        {/*Benefit 1:*/}
        {/*Benefit 2:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.benefit} 2:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Slika</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.imageProportions} 1.64/1 (350x213)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section1Image2Primary" />
            </div>
          </div>
          {/*primary_sec1Benefit2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Benefit2Text">
              <b>
                {translations?.benefit} 2 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec1Benefit2Text" type="text" placeholder={`${translations?.benefit} 2 ${translations?.text}:`} name="primary_sec1Benefit2Text" value={formSection1.primary.sec1Benefit2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Benefit2Text ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Benefit2Text*/}
          {/*primary_sec1Benefit2Resource*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Benefit2Resource">
              <b>
                {translations?.benefit} 2 {translations?.resource}:
              </b>
            </label>
            <textarea id="primary_sec1Benefit1Resource" type="text" placeholder={`${translations?.benefit} 2 ${translations?.resource}:`} name="primary_sec1Benefit2Resource" value={formSection1.primary.sec1Benefit2Resource} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Benefit1Resource ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Benefit2Resource*/}
        </div>
        {/*Benefit 2:*/}
        {/*Benefit 3:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.benefit} 3:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>{translations?.image}</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.imageProportions} 2.94/1 (460x156)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section1Image3Primary" />
            </div>
          </div>
          {/*primary_sec1Benefit3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Benefit3Text">
              <b>
                {translations?.benefit} 3 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec1Benefit3Text" type="text" placeholder={`${translations?.benefit} 3 ${translations?.text}:`} name="primary_sec1Benefit3Text" value={formSection1.primary.sec1Benefit3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Benefit3Text ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Benefit3Text*/}
          {/*primary_sec1Benefit3Resource*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Benefit3Resource">
              <b>
                {translations?.benefit} 3 {translations?.resource}:
              </b>
            </label>
            <textarea id="primary_sec1Benefit3Resource" type="text" placeholder={`${translations?.benefit} 3 ${translations?.resource}:`} name="primary_sec1Benefit3Resource" value={formSection1.primary.sec1Benefit3Resource} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Benefit3Resource ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Benefit3Resource*/}
        </div>
        {/*Benefit 3:*/}
      </div>
    </>
  );
};
export default Section1Primary;
