import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2Cg = ({ activeTabKey, translations, formSection2, handleInputChangeFormSection2, setHpImages, hpImages, hpImagesPreview, formSection2Errors, hpImagesErrors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_mainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_mainHeading">
            <b>{translations?.mainHeading}:</b>
          </label>
          <input id="primary_mainHeading" type="text" placeholder={`${translations?.mainHeading}`} name="primary_mainHeading" value={formSection2.primary.mainHeading} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.mainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_mainHeading*/}
        {/*primary_sec2button1Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2button1Text">
            <b>{translations?.btnText} 1:</b>
          </label>
          <input id="primary_sec2button1Text" type="text" placeholder={`${translations?.btnText} 1`} name="primary_sec2button1Text" value={formSection2.primary.sec2button1Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2button1Text ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec2button1Text*/}
        {/*primary_sec2button2Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2button2Text">
            <b>{translations?.btnText} 2:</b>
          </label>
          <input id="primary_sec2button2Text" type="text" placeholder={`${translations?.btnText} 2`} name="primary_sec2button2Text" value={formSection2.primary.sec2button2Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2button2Text ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_button2Text*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Card 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.list} 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>{translations?.image}</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.imageProportions} 3/2 (1500x1000)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload activeTabKey={activeTabKey} translations={translations} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section2Image1Primary" />
            </div>
          </div>
          {/*primary_list1item1*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list1item1">
              <b>{translations?.item} 1:</b>
            </label>
            <input id="primary_list1item1" type="text" placeholder={`${translations?.item} 1`} name="primary_list1item1" value={formSection2.primary.list1item1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list1item1 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list1item1*/}
          {/*primary_list1item2*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list1item2">
              <b>{translations?.item} 2:</b>
            </label>
            <input id="primary_list1item2" type="text" placeholder={`${translations?.item} 2`} name="primary_list1item2" value={formSection2.primary.list1item2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list1item2 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list1item2*/}
          {/*primary_list1item3*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list1item3">
              <b>{translations?.item} 3:</b>
            </label>
            <input id="primary_list1item3" type="text" placeholder={`${translations?.item} 3`} name="primary_list1item3" value={formSection2.primary.list1item3} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list1item3 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list1item3*/}
          {/*primary_list1item4*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list1item4">
              <b>{translations?.item} 4:</b>
            </label>
            <input id="primary_list1item4" type="text" placeholder={`${translations?.item} 4`} name="primary_list1item4" value={formSection2.primary.list1item4} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list1item4 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list1item4*/}
          {/*primary_list1item5*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list1item5">
              <b>{translations?.item} 5:</b>
            </label>
            <input id="primary_list1item5" type="text" placeholder={`${translations?.item} 5`} name="primary_list1item5" value={formSection2.primary.list1item5} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list1item5 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list1item5*/}
        </div>
        {/*Card 1:*/}
        {/*Card 2:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.list} 2:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>{translations?.image}</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.imageProportions} 3/2 (1500x1000)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload activeTabKey={activeTabKey} translations={translations} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section2Image2Primary" />
            </div>
          </div>
          {/*primary_list2item1*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="primary_list2item1">
              <b>{translations?.item} 1:</b>
            </label>
            <input id="primary_list2item1" type="text" placeholder={`${translations?.item} 1`} name="primary_list2item1" value={formSection2.primary.list2item1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list2item1 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list2item1*/}
          {/*primary_list2item2*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="primary_list2item2">
              <b>{translations?.item} 2:</b>
            </label>
            <input id="primary_list2item2" type="text" placeholder={`${translations?.item} 2`} name="primary_list2item2" value={formSection2.primary.list2item2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list2item2 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list2item2*/}
          {/*primary_list2item3*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="primary_list2item3">
              <b>{translations?.item} 3:</b>
            </label>
            <input id="primary_list2item3" type="text" placeholder={`${translations?.item} 3`} name="primary_list2item3" value={formSection2.primary.list2item3} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list2item3 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list2item3*/}
          {/*primary_list2item4*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="primary_list2item4">
              <b>{translations?.item} 4:</b>
            </label>
            <input id="primary_list2item4" type="text" placeholder={`${translations?.item} 4`} name="primary_list2item4" value={formSection2.primary.list2item4} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list2item4 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list2item4*/}
          {/*primary_list2item5*/}
          {/* <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list2item5">
              <b>Stavka 5:</b>
            </label>
            <input id="primary_list2item5" type="text" placeholder="Stavka 5" name="primary_list2item5" value={formSection2.primary.list2item5} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list2item5 ? 'input-error-important' : ''}`} />
          </div> */}
          {/*primary_list1item5*/}
        </div>
        {/*Card 2:*/}
      </div>
    </>
  );
};
export default memo(Section2Cg);
