export const constructInitialFormSection1State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section1Hide || false,
    secondary: {
      ...prevState.secondary,
      title1: data?.section1Card1TitleSecondary || '',
      title2: data?.section1Card2TitleSecondary || '',
      subTitle1Part1: data?.section1Card1SubtitlePart1Secondary || '',
      subTitle1Part2: data?.section1Card1SubtitlePart2Secondary || '',
      subTitle2Part1: data?.section1Card2SubtitlePart1Secondary || '',
      subTitle2Part2: data?.section1Card2SubtitlePart2Secondary || '',
      button1Text: data?.section1Card1ButtonTextSecondary || '',
      button2Text: data?.section1Card2ButtonTextSecondary || '',
      button1Url: data?.section1Card1ButtonUrlSecondary || '',
      button2Url: data?.section1Card2ButtonUrlSecondary || '',
    },
    primary: {
      ...prevState.primary,
      title1: data?.section1Card1TitlePrimary || '',
      title2: data?.section1Card2TitlePrimary || '',
      subTitle1Part1: data?.section1Card1SubtitlePart1Primary || '',
      subTitle1Part2: data?.section1Card1SubtitlePart2Primary || '',
      subTitle2Part1: data?.section1Card2SubtitlePart1Primary || '',
      subTitle2Part2: data?.section1Card2SubtitlePart2Primary || '',
      button1Text: data?.section1Card1ButtonTextPrimary || '',
      button2Text: data?.section1Card2ButtonTextPrimary || '',
      button1Url: data?.section1Card1ButtonUrlPrimary || '',
      button2Url: data?.section1Card2ButtonUrlPrimary || '',
    },
  };
};

export const constructInitialFormSection2State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section2Hide || false,
    secondary: {
      ...prevState.secondary,
      mainHeading: data?.section2MainHeadingSecondary || '',
      sec2button1Text: data?.section2Button1TextSecondary || '',
      sec2button2Text: data?.section2Button2TextSecondary || '',
      list1item1: data?.section2List1Item1Secondary || '',
      list1item2: data?.section2List1Item2Secondary || '',
      list1item3: data?.section2List1Item3Secondary || '',
      list1item4: data?.section2List1Item4Secondary || '',
      list1item5: data?.section2List1Item5Secondary || '',
      list2item1: data?.section2List2Item1Secondary || '',
      list2item2: data?.section2List2Item2Secondary || '',
      list2item3: data?.section2List2Item3Secondary || '',
      list2item4: data?.section2List2Item4Secondary || '',
    },
    primary: {
      ...prevState.primary,
      mainHeading: data?.section2MainHeadingPrimary || '',
      sec2button1Text: data?.section2Button1TextPrimary || '',
      sec2button2Text: data?.section2Button2TextPrimary || '',
      list1item1: data?.section2List1Item1Primary || '',
      list1item2: data?.section2List1Item2Primary || '',
      list1item3: data?.section2List1Item3Primary || '',
      list1item4: data?.section2List1Item4Primary || '',
      list1item5: data?.section2List1Item5Primary || '',
      list2item1: data?.section2List2Item1Primary || '',
      list2item2: data?.section2List2Item2Primary || '',
      list2item3: data?.section2List2Item3Primary || '',
      list2item4: data?.section2List2Item4Primary || '',
    },
  };
};

export const constructInitialFormSection3State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section3Hide || false,
    secondary: {
      ...prevState.secondary,
      sec3MainHeading: data?.section3MainHeadingSecondary || '',
      sec3Card1Title: data?.section3Card1TitleSecondary || '',
      sec3Card1SubTitle: data?.section3Card1SubtitleSecondary || '',
      sec3Card2Title: data?.section3Card2TitleSecondary || '',
      sec3Card2SubTitle: data?.section3Card2SubtitleSecondary || '',
      sec3Card3Title: data?.section3Card3TitleSecondary || '',
      sec3Card3SubTitle: data?.section3Card3SubtitleSecondary || '',
      sec3Card4Title: data?.section3Card4TitleSecondary || '',
      sec3Card4SubTitle: data?.section3Card4SubtitleSecondary || '',
      sec3Card5Title: data?.section3Card5TitleSecondary || '',
      sec3Card5SubTitle: data?.section3Card5SubtitleSecondary || '',
      sec3Card6Title: data?.section3Card6TitleSecondary || '',
      sec3Card6SubTitle: data?.section3Card6SubtitleSecondary || '',
    },
    primary: {
      ...prevState.primary,
      sec3MainHeading: data?.section3MainHeadingPrimary || '',
      sec3Card1Title: data?.section3Card1TitlePrimary || '',
      sec3Card1SubTitle: data?.section3Card1SubtitlePrimary || '',
      sec3Card2Title: data?.section3Card2TitlePrimary || '',
      sec3Card2SubTitle: data?.section3Card2SubtitlePrimary || '',
      sec3Card3Title: data?.section3Card3TitlePrimary || '',
      sec3Card3SubTitle: data?.section3Card3SubtitlePrimary || '',
      sec3Card4Title: data?.section3Card4TitlePrimary || '',
      sec3Card4SubTitle: data?.section3Card4SubtitlePrimary || '',
      sec3Card5Title: data?.section3Card5TitlePrimary || '',
      sec3Card5SubTitle: data?.section3Card5SubtitlePrimary || '',
      sec3Card6Title: data?.section3Card6TitlePrimary || '',
      sec3Card6SubTitle: data?.section3Card6SubtitlePrimary || '',
    },
  };
};

export const constructInitialFormSection4State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section4Hide || false,
    secondary: {
      ...prevState.secondary,
      sec4VideoUrl: data?.section4VideoUrlSecondary || '',
      sec4StepsTitlePart1: data?.section4StepsTitleSecondaryPart1 || '',
      sec4StepsTitlePart2: data?.section4StepsTitleSecondaryPart2 || '',
      sec4Step1Title: data?.section4Step1TitleSecondary || '',
      sec4Step1Text: data?.section4Step1TextSecondary || '',
      sec4Step2Title: data?.section4Step2TitleSecondary || '',
      sec4Step2Text: data?.section4Step2TextSecondary || '',
      sec4Step3Title: data?.section4Step3TitleSecondary || '',
      sec4Step3Text: data?.section4Step3TextSecondary || '',
      sec4StepsButtonText: data?.section4StepsButtonTextSecondary || '',
      sec4StepsButtonUrl: data?.section4StepsButtonUrlSecondary || '',
    },
    primary: {
      ...prevState.primary,
      sec4VideoUrl: data?.section4VideoUrlPrimary || '',
      sec4StepsTitlePart1: data?.section4StepsTitlePrimaryPart1 || '',
      sec4StepsTitlePart2: data?.section4StepsTitlePrimaryPart2 || '',
      sec4Step1Title: data?.section4Step1TitlePrimary || '',
      sec4Step1Text: data?.section4Step1TextPrimary || '',
      sec4Step2Title: data?.section4Step2TitlePrimary || '',
      sec4Step2Text: data?.section4Step2TextPrimary || '',
      sec4Step3Title: data?.section4Step3TitlePrimary || '',
      sec4Step3Text: data?.section4Step3TextPrimary || '',
      sec4StepsButtonText: data?.section4StepsButtonTextPrimary || '',
      sec4StepsButtonUrl: data?.section4StepsButtonUrlPrimary || '',
    },
  };
};

export const constructInitialHpImagesState = (data, prevState) => {
  return {
    ...prevState,
    section1Icon1Secondary: data?.section1Card1IconSecondary?._id || null,
    section1Icon2Secondary: data?.section1Card2IconSecondary?._id || null,
    section2Image1Secondary: data?.section2Image1Secondary?._id || null,
    section2Image2Secondary: data?.section2Image2Secondary?._id || null,
    section3Icon1Secondary: data?.section3Icon1Secondary?._id || null,
    section3Icon2Secondary: data?.section3Icon2Secondary?._id || null,
    section3Icon3Secondary: data?.section3Icon3Secondary?._id || null,
    section3Icon4Secondary: data?.section3Icon4Secondary?._id || null,
    section3Icon5Secondary: data?.section3Icon5Secondary?._id || null,
    section3Icon6Secondary: data?.section3Icon6Secondary?._id || null,
    section4Image1Secondary: data?.section4Image1Secondary?._id || null,

    section1Icon1Primary: data?.section1Card1IconPrimary?._id || null,
    section1Icon2Primary: data?.section1Card2IconPrimary?._id || null,
    section2Image1Primary: data?.section2Image1Primary?._id || null,
    section2Image2Primary: data?.section2Image2Primary?._id || null,
    section3Icon1Primary: data?.section3Icon1Primary?._id || null,
    section3Icon2Primary: data?.section3Icon2Primary?._id || null,
    section3Icon3Primary: data?.section3Icon3Primary?._id || null,
    section3Icon4Primary: data?.section3Icon4Primary?._id || null,
    section3Icon5Primary: data?.section3Icon5Primary?._id || null,
    section3Icon6Primary: data?.section3Icon6Primary?._id || null,
    section4Image1Primary: data?.section4Image1Primary?._id || null,
  };
};

export const constructInitialHpImagesPreviewState = (data, prevState) => {
  return {
    ...prevState,
    section1Icon1Secondary: data?.section1Card1IconSecondary?.url || null,
    section1Icon2Secondary: data?.section1Card2IconSecondary?.url || null,
    section2Image1Secondary: data?.section2Image1Secondary?.url || null,
    section2Image2Secondary: data?.section2Image2Secondary?.url || null,
    section3Icon1Secondary: data?.section3Icon1Secondary?.url || null,
    section3Icon2Secondary: data?.section3Icon2Secondary?.url || null,
    section3Icon3Secondary: data?.section3Icon3Secondary?.url || null,
    section3Icon4Secondary: data?.section3Icon4Secondary?.url || null,
    section3Icon5Secondary: data?.section3Icon5Secondary?.url || null,
    section3Icon6Secondary: data?.section3Icon6Secondary?.url || null,
    section4Image1Secondary: data?.section4Image1Secondary?.url || null,

    section1Icon1Primary: data?.section1Card1IconPrimary?.url || null,
    section1Icon2Primary: data?.section1Card2IconPrimary?.url || null,
    section2Image1Primary: data?.section2Image1Primary?.url || null,
    section2Image2Primary: data?.section2Image2Primary?.url || null,
    section3Icon1Primary: data?.section3Icon1Primary?.url || null,
    section3Icon2Primary: data?.section3Icon2Primary?.url || null,
    section3Icon3Primary: data?.section3Icon3Primary?.url || null,
    section3Icon4Primary: data?.section3Icon4Primary?.url || null,
    section3Icon5Primary: data?.section3Icon5Primary?.url || null,
    section3Icon6Primary: data?.section3Icon6Primary?.url || null,
    section4Image1Primary: data?.section4Image1Primary?.url || null,
  };
};
