import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1Primary = ({ translations, activeTabKey, handleInputChangeFormSection1, formSection1, auImages, auImagesErrors, auImagesPreview, setAuImages, formSection1Errors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_sec1MainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec1MainHeading">
            <b>{translations?.mainHeading}:</b>
          </label>
          <input id="primary_sec1MainHeading" type="text" placeholder={`${translations?.mainHeading}`} name="primary_sec1MainHeading" value={formSection1.primary.sec1MainHeading} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1MainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec1MainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Row 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.row} 1:</p>
          {/* <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Image</span>
              <span className="ml-6">
                <TooltipDash text="Icon aspect ratio 1/1 (150x150)" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="section1Image1En" />
            </div>
          </div> */}
          {/*primary_sec1Row1VideoUrl*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="primary_sec1Row1VideoUrl">
              <b>
                {translations?.row} 1 {translations?.videoUrl}:
              </b>
            </label>
            <input id="primary_sec1Row1VideoUrl" type="text" placeholder={`${translations?.row} 1 ${translations?.videoUrl}`} name="primary_sec1Row1VideoUrl" value={formSection1.primary.sec1Row1VideoUrl} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Row1VideoUrl ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Row1VideoUrl*/}
          {/*primary_sec1Row1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="primary_sec1Row1Text">
              <b>
                {translations?.row} 1 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec1Row1Text" type="text" placeholder={`${translations?.row} 1 ${translations?.text}`} name="primary_sec1Row1Text" value={formSection1.primary.sec1Row1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Row1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec1Row1Text*/}
        </div>
        {/*Row 1:*/}
        {/*Row 2:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.row} 2:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>
                {translations?.row} 2 {translations?.image}
              </span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.imageProportions} 1/1 (240x240)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="section1Image1Primary" />
            </div>
          </div>
          {/*primary_sec1Row2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Row2Text">
              <b>
                {translations?.row} 2 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec1Row2Text" type="text" placeholder={`${translations?.row} 2 ${translations?.text}`} name="primary_sec1Row2Text" value={formSection1.primary.sec1Row2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Row2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec1Row2Text*/}
        </div>
        {/*Row 2:*/}
        {/*Row 3:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.row} 3:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>
                {translations?.row} 3 {translations?.image}
              </span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.imageProportions} 1/1 (240x240)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="section1Image2Primary" />
            </div>
          </div>
          {/*primary_sec1Row3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Row3Text">
              <b>
                {translations?.row} 3 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec1Row3Text" type="text" placeholder={`${translations?.row} 3 ${translations?.text}`} name="primary_sec1Row3Text" value={formSection1.primary.sec1Row3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Row3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec1Row3Text*/}
        </div>
        {/*Row 3:*/}
      </div>
    </>
  );
};
export default Section1Primary;
