import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2Primary = ({ translations, activeTabKey, formSection2, handleInputChangeFormSection2, setEnImages, enImages, enImagesPreview, formSection2Errors, enImagesErrors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_mainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_mainHeading">
            <b>{translations?.mainHeading}:</b>
          </label>
          <input id="primary_mainHeading" type="text" placeholder="Glavni naslov" name="primary_mainHeading" value={formSection2.primary.mainHeading} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.mainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_mainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Card 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Lista 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>{translations?.image}</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.imageProportions} 3/2 (1500x1000)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} enImages={enImages} enImagesErrors={enImagesErrors} enImagesPreview={enImagesPreview} setEnImages={setEnImages} toStoreImg="section2Image1Primary" />
            </div>
          </div>
          {/*primary_list1item1*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list1item1">
              <b>{translations?.item} 1:</b>
            </label>
            <input id="primary_list1item1" type="text" placeholder={`${translations?.item} 1`} name="primary_list1item1" value={formSection2.primary.list1item1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list1item1 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list1item1*/}
          {/*primary_list1item2*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list1item2">
              <b>{translations?.item} 2:</b>
            </label>
            <input id="primary_list1item2" type="text" placeholder={`${translations?.item} 2`} name="primary_list1item2" value={formSection2.primary.list1item2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list1item2 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list1item2*/}
          {/*primary_list1item3*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list1item3">
              <b>{translations?.item} 3:</b>
            </label>
            <input id="primary_list1item3" type="text" placeholder={`${translations?.item} 3`} name="primary_list1item3" value={formSection2.primary.list1item3} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list1item3 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list1item3*/}
          {/*primary_list1item4*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_list1item4">
              <b>{translations?.item} 4:</b>
            </label>
            <input id="primary_list1item4" type="text" placeholder={`${translations?.item} 4`} name="primary_list1item4" value={formSection2.primary.list1item4} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.list1item4 ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_list1item4*/}
        </div>
        {/*Card 1:*/}
      </div>
    </>
  );
};
export default memo(Section2Primary);
