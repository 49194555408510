import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1Secondary = ({ handleInputChangeFormSection1, formSection1, clImages, clImagesErrors, clImagesPreview, setClImages, formSection1Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Image</span>
            <span className="ml-6">
              <TooltipDash text="Image aspect ratio 1/1 (480x480)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="section1ImageSecondary" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*secondary_sec1MainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_sec1MainHeading">
            <b>Main heading:</b>
          </label>
          <input id="secondary_sec1MainHeading" type="text" placeholder="Main heading" name="secondary_sec1MainHeading" value={formSection1.secondary.sec1MainHeading} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1MainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_sec1MainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Item 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Item 1:</p>
          {/*secondary_sec1ListItem1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1ListItem1Title">
              <b>Item 1 Title:</b>
            </label>
            <textarea id="secondary_sec1ListItem1Title" type="text" placeholder="Item 1 Title" name="secondary_sec1ListItem1Title" value={formSection1.secondary.sec1ListItem1Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1ListItem1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec1ListItem1Title*/}
          {/*secondary_sec1ListItem1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1ListItem1Text">
              <b>Item 1 Text:</b>
            </label>
            <textarea id="secondary_sec1ListItem1Text" type="text" placeholder="Item 1 Text" name="secondary_sec1ListItem1Text" value={formSection1.secondary.sec1ListItem1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1ListItem1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec1ListItem1Text*/}
        </div>
        {/*Item 1*/}

        {/*Item 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Item 2:</p>
          {/*secondary_sec1ListItem2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1ListItem2Title">
              <b>Item 2 Title:</b>
            </label>
            <textarea id="secondary_sec1ListItem2Title" type="text" placeholder="Item 1 Title" name="secondary_sec1ListItem2Title" value={formSection1.secondary.sec1ListItem2Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1ListItem2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec1ListItem2Title*/}
          {/*secondary_sec1ListItem2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1ListItem2Text">
              <b>Item 2 Text:</b>
            </label>
            <textarea id="secondary_sec1ListItem2Text" type="text" placeholder="Item 1 Text" name="secondary_sec1ListItem2Text" value={formSection1.secondary.sec1ListItem2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1ListItem2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec1ListItem2Text*/}
        </div>
        {/*Item 2*/}

        {/*Item 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Item 3:</p>
          {/*secondary_sec1ListItem3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1ListItem3Title">
              <b>Item 3 Title:</b>
            </label>
            <textarea id="secondary_sec1ListItem3Title" type="text" placeholder="Item 1 Title" name="secondary_sec1ListItem3Title" value={formSection1.secondary.sec1ListItem3Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1ListItem3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec1ListItem3Title*/}
          {/*secondary_sec1ListItem3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1ListItem3Text">
              <b>Item 3 Text:</b>
            </label>
            <textarea id="secondary_sec1ListItem3Text" type="text" placeholder="Item 1 Text" name="secondary_sec1ListItem3Text" value={formSection1.secondary.sec1ListItem3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1ListItem3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec1ListItem3Text*/}
        </div>
        {/*Item 3*/}

        {/*Item 4*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Item 4:</p>
          {/*secondary_sec1ListItem4Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1ListItem4Title">
              <b>Item 4 Title:</b>
            </label>
            <textarea id="secondary_sec1ListItem4Title" type="text" placeholder="Item 1 Title" name="secondary_sec1ListItem4Title" value={formSection1.secondary.sec1ListItem4Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1ListItem4Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec1ListItem4Title*/}
          {/*secondary_sec1ListItem4Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1ListItem4Text">
              <b>Item 4 Text:</b>
            </label>
            <textarea id="secondary_sec1ListItem4Text" type="text" placeholder="Item 1 Text" name="secondary_sec1ListItem4Text" value={formSection1.secondary.sec1ListItem4Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1ListItem4Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec1ListItem4Text*/}
        </div>
        {/*Item 4*/}
      </div>
    </>
  );
};
export default Section1Secondary;
