import React, { useState } from 'react';
import { stateUS } from '../../../../helpers/state';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { useTranslation } from 'react-i18next';
// import { Select } from 'antd';
import { Select, Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import { SERVER_URL } from '../../../../config/index';

const { Option } = Select;

export const CreateForm = ({ createProps, createModalFormRef, handleOnSubmit, createForm, createFormError, handleOnChange, handleOnBlur, createFormEmail, addresses, handleOnRemove, setToggleCreateAddress, toggleCreateAddress, TogglePasswordChecker, createPasswordProps, createLoading, handleOnSave, setToggleCreate, venuesOptions, searchText, user, selectedVenues, venues, fetchVenues, handleVenuesChange, handleSearchVenues }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  return (
    <SidebarModal {...createProps} className="sidebar__modal--right" title={t('createMember')}>
      <form
        name="create"
        ref={createModalFormRef}
        onSubmit={(event) =>
          handleOnSubmit({
            event: event,
            name: 'create',
            state: createProps,
          })
        }
        className="ant-form ant-form-horizontal"
      >
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createFirstName">
            <b className="required">{t('firstName')}:</b>
          </label>

          <input name="createFirstName" id="createFirstName" value={createForm.createFirstName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder={t('firstName')} data-cy="" className={`input ${createFormError.createFirstNameError ? 'input-error' : ''}`} />
          <p data-cy="" className={`error__onblur ${createFormError.createFirstNameError ? 'error' : ''}`}>
            {t('requiredField')}
          </p>
        </div>
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createLastName">
            <b className="required">{t('lastName')}:</b>
          </label>

          <input name="createLastName" id="createLastName" value={createForm.createLastName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder={t('lastName')} data-cy="" className={`input ${createFormError.createLastNameError ? 'input-error' : ''}`} />
          <p data-cy="" className={`error__onblur ${createFormError.createLastNameError ? 'error' : ''}`}>
            {t('requiredField')}
          </p>
        </div>
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createEmail">
            <b className="required">{t('email')}:</b>
          </label>

          <input name="createEmail" id="createEmail" value={createForm.createEmail || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="email" placeholder={t('email')} data-cy="" className={`input ${createFormError.createEmailError || createFormEmail ? 'input-error' : ''}`} />
          <p data-cy="" className={`error__onblur ${createFormError.createEmailError ? 'error' : ''}`}>
            {t('requiredField')}
          </p>

          <p data-cy="" className={`error__onblur ${createFormEmail ? 'error' : ''}`}>
            {t('userAlreadyExists')}.
          </p>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <p>{t('assignVenue')}:</p>
          <Autocomplete
            multiple
            hiddenlabel={true}
            options={venues}
            getOptionLabel={(option) => option['name']}
            value={selectedVenues}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              handleSearchVenues(newInputValue);
            }}
            // PaperComponent={CustomPaper}
            onChange={handleVenuesChange}
            filterSelectedOptions
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d9d9d9',
                borderWidth: 1,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#d9d9d9',
              },
              '& .MuiAutocomplete-popupIndicator': {
                '& > svg': {
                  // fill: "blue",
                  fontSize: '25px',
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('selectVenue')}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#BCBCBC',
                    },
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#666666',
                    fontSize: '14px',
                    opacity: 1,
                  },
                }}
              />
            )}
          />
        </div>

        {/* <Select
          mode="multiple"
          value={selectedVenues}
          onSearch={handleSearchVenues}
          onChange={() => console.log('blbablabalb')}
          showArrow
          name="updateSubcategoryAttributes"
          searchValue={searchText}
          placeholder="izaberite venues"
          type="text"
          defaultOpen={false}
          id="updateSubcategoryAttributes"
          style={{ width: '100%' }}
          dropdownRender={(menu) => (
            <>
              {venuesOptions.length > 0 ? (
                menu
              ) : (
                <div style={{ padding: '8px', textAlign: 'center' }}>
                  <span>No venues available</span>
                </div>
              )}
            </>
          )}
        >
          {venuesOptions && venuesOptions.length > 0
            ? venuesOptions.map((item) => {
                return (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                );
              })
            : null}
        </Select> */}

        {/* <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createStatus">
            <b className="required">{t('venue')}:</b>
          </label>

          <select name="createStatus" id="createStatus" defaultValue={createForm.createStatus || 'none'} onChange={handleOnChange} onBlur={handleOnBlur} data-cy="" className={`select select-full ${createFormError.createStatusError ? 'input-error' : ''}`}>
            <option value="none">{t('select')}</option>
            <option value="srb">{t('serbia')}</option>
            <option value="cg">{t('montenegro')}</option>
            <option value="lt">{t('lithuania')}</option>
          </select>

          <p data-cy="" className={`error__onblur ${createFormError.createStatusError ? 'error' : ''}`}>
            {t('requiredField')}
          </p>
        </div> */}

        {/* <span className='fake-label'>
          <b className='required'>{t('address')}:</b>
        </span>
        <div className='form-group form-group-address relative'>
          <div className='addresses' data-cy=''>
            {addresses &&
              addresses.map((item) => {
                const { address, zip_code, city, state } = item;
                return (
                  <div key={item.address_id} className='item' data-cy=''>
                    <p>
                      {address}, {zip_code}, {city}, {state}
                    </p>
                    <button className='btn' type='button' onClick={() => handleOnRemove(item.address_id)}>
                      <span className='icon'>
                        <img alt='Delete' src='/assets/icons/delete.svg' />
                      </span>
                    </button>
                  </div>
                );
              })}
          </div>

          <button className='btn btn-default' type='button' onClick={() => setToggleCreateAddress((prevState) => !prevState)}>
            <span className='icon'>
              <img alt='Create new Address' src='/assets/icons/plus.svg' />
            </span>
            <span className='text'></span>
          </button>
        </div> */}
        {/* {toggleCreateAddress && (
          <div className='wrapper-address'>
            <div className='form-group relative'>
              <label className='label mb-1 d-block' htmlFor='createAddress'>
                <b className='required'>{t('address')}:</b>
              </label>

              <input name='createAddress' id='createAddress' value={createForm.createAddress || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='text' placeholder={t('address')} data-cy='' className={`input ${createFormError.createAddressError ? 'input-error' : ''}`} />
              <p data-cy='' className={`error__onblur ${createFormError.createAddressError ? 'error' : ''}`}>
                {t('requiredField')}
              </p>
            </div>

            <div className='form-group relative'>
              <label className='label mb-1 d-block' htmlFor='createZipCode'>
                <b className='required'>{t('zipCode')}:</b>
              </label>

              <input name='createZipCode' id='createZipCode' value={createForm.createZipCode || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='number' placeholder={t('zipCode')} data-cy='' className={`input ${createFormError.createZipCodeError ? 'input-error' : ''}`} />
              <p data-cy='' className={`error__onblur ${createFormError.createZipCodeError ? 'error' : ''}`}>
                {t('requiredField')}
              </p>
            </div>

            <div className='form-group relative'>
              <label className='label mb-1 d-block' htmlFor='createCity'>
                <b className='required'>{t('city')}:</b>
              </label>

              <input name='createCity' id='createCity' value={createForm.createCity || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='text' placeholder={t('city')} data-cy='' className={`input ${createFormError.createCityError ? 'input-error' : ''}`} />
              <p data-cy='' className={`error__onblur ${createFormError.createCityError ? 'error' : ''}`}>
                {t('requiredField')}
              </p>
            </div>

            <div className='form-group relative'>
              <label className='label mb-1 d-block' htmlFor='createState'>
                <b className='required'>{t('state')}:</b>
              </label>

              <select name='createState' id='createState' value={createForm.createState || 'none'} onChange={handleOnChange} onBlur={handleOnBlur} data-cy='' className={`select select-full ${createFormError.createStateError ? 'input-error' : ''}`}>
                <option value='none'>{t('selectState')}</option>
                {stateUS &&
                  stateUS.length > 0 &&
                  stateUS.map((item) => {
                    return (
                      <option key={`create_${item.name.toLowerCase()}`} value={item.abbreviation}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>

              <p data-cy='' className={`error__onblur ${createFormError.createStateError ? 'error' : ''}`}>
                {t('requiredField')}
              </p>
            </div>

            <div className='form-group form-group-sidebar mt-6'>
              <button data-cy='' type='button' className='btn btn-primary-link' onClick={() => setToggleCreateAddress(false)}>
                <span className='text'>{t('cancel')}</span>
              </button>

              <button data-cy='' type='button' className='btn btn-primary' onClick={handleOnSave}>
                <span className='text'>{t('createAddress')}</span>
              </button>
            </div>
          </div>
        )} */}
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createPhone">
            <b className="required">{t('phone')}:</b>
          </label>

          <input name="createPhone" id="createPhone" value={createForm.createPhone || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="number" placeholder={t('phone')} data-cy="" className={`input ${createFormError.createPhoneError ? 'input-error' : ''}`} />

          <p data-cy="" className={`error__onblur ${createFormError.createPhoneError ? 'error' : ''}`}>
            {t('requiredField')}
          </p>
        </div>

        <TogglePasswordChecker {...createPasswordProps} createFormError={createFormError} />

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createStatus">
            <b className="required">{t('status')}:</b>
          </label>

          <select name="createStatus" id="createStatus" defaultValue={createForm.createStatus || 'none'} onChange={handleOnChange} onBlur={handleOnBlur} data-cy="" className={`select select-full ${createFormError.createStatusError ? 'input-error' : ''}`}>
            <option value="none">{t('select')}</option>
            <option value="active">{t('active')}</option>
            <option value="inactive">{t('inactive')}</option>
          </select>

          <p data-cy="" className={`error__onblur ${createFormError.createStatusError ? 'error' : ''}`}>
            {t('requiredField')}
          </p>
        </div>
        <div className="form-group form-group-sidebar">
          <button data-cy="modal-create-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreate(false)}>
            <span className="text">{t('cancel')}</span>
          </button>

          {!createLoading ? (
            <button data-cy="modal-create-submit-btn" type="submit" className="btn btn-primary">
              <span className="text">{t('createMember')}</span>
            </button>
          ) : (
            <button data-cy="modal-create-submit-btn" type="button" className="btn btn-primary-outline">
              <span className="text">{t('loading')}...</span>
            </button>
          )}
        </div>
      </form>
    </SidebarModal>
  );
};
