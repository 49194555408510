import React, { memo, useState } from 'react';
import { Tabs } from 'antd';
import Section1Secondary from '../tabs/secondary/Section1Secondary';
import Section1Primary from '../tabs/primary/Section1Primary';
import CheckboxCms from '../../../../components/checkbox-cms';

const CmsClubSection1 = ({ countryCodeExtension, translations, handleInputChangeFormSection1, formSection1, clImages, clImagesPreview, setClImages, formSection1Errors, clImagesErrors }) => {
  const { TabPane } = Tabs;

  const [activeTabKey, setActiveTabKey] = useState('1');

  const onChangeTabSection1 = (key) => {
    setActiveTabKey(key);
  };

  const sectionProps = {
    handleInputChangeFormSection1,
    formSection1,
    clImages,
    clImagesPreview,
    setClImages,
    onChangeTabSection1,
    formSection1Errors,
    clImagesErrors,
    activeTabKey,
    translations,
  };
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSection1}>
        <TabPane key="1" tab="EN">
          <Section1Secondary {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab={countryCodeExtension?.toUpperCase()}>
          <Section1Primary {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName="section1_hide" isChecked={formSection1.hide} handleChange={handleInputChangeFormSection1} label={activeTabKey === '2' ? translations?.hideSection : 'Hide entire section on website'} tooltipText={activeTabKey === '2' ? translations?.hideSectionTooltip : 'Hide entire section on website'} />
    </>
  );
};
export default memo(CmsClubSection1);
