import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const SecondaryLangSlideData = ({ handleInputChangeSlider, form, images, imagesPreview, setImages, formErrors, imagesErrors }) => {
  return (
    <div className="cms-form-flex">
      <div className="cms-form-flex-col">
        <p className="card-top">Slider images:</p>
        <div className="cms-form-head-inner" style={{ display: 'flex', gap: '30px' }}>
          <div className="images-card-header">
            <span>Desktop</span>
            <span className="ml-2">{/* <TooltipDash text="Icon aspect ratio 1/1 (150x150)px" acceptFormatText={true} /> */}</span>
            <div className="upload-wrapper mt-2">
              <ImageUpload images={images} imagesPreview={imagesPreview} setImages={setImages} imagesErrors={imagesErrors} toStoreImg="desktopImageSecondary" />
            </div>
          </div>
          <div className="images-card-header">
            <span>Tablet</span>
            <span className="ml-2">{/* <TooltipDash text="Icon aspect ratio 1/1 (150x150)px" acceptFormatText={true} /> */}</span>
            <div className="upload-wrapper mt-2">
              <ImageUpload images={images} imagesPreview={imagesPreview} setImages={setImages} imagesErrors={imagesErrors} toStoreImg="tabletImageSecondary" />
            </div>
          </div>
          <div className="images-card-header">
            <span>Mobile</span>
            <span className="ml-2">{/* <TooltipDash text="Icon aspect ratio 1/1 (150x150)px" acceptFormatText={true} /> */}</span>
            <div className="upload-wrapper mt-2">
              <ImageUpload images={images} imagesPreview={imagesPreview} setImages={setImages} imagesErrors={imagesErrors} toStoreImg="mobileImageSecondary" />
            </div>
          </div>
        </div>

        {/*sliderTitleLine1Secondary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderTitleLine1Secondary">
            <b>Title Line 1:</b>
          </label>
          <input id="sliderTitleLine1Secondary" type="text" placeholder="Title Line 1" name="sliderTitleLine1Secondary" value={form.sliderTitleLine1Secondary} onChange={handleInputChangeSlider} className={`${formErrors.sliderTitleLine1Secondary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderTitleLine1Secondary*/}

        {/*sliderTitleLine2Secondary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderTitleLine2Secondary">
            <b>Title Line 2:</b>
          </label>
          <input id="sliderTitleLine2Secondary" type="text" placeholder="Title Line 2" name="sliderTitleLine2Secondary" value={form.sliderTitleLine2Secondary} onChange={handleInputChangeSlider} className={`${formErrors.sliderTitleLine2Secondary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderTitleLine2Secondary*/}

        {/*sliderTitleLine3Secondary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderTitleLine3Secondary">
            <b>Title Line 3:</b>
          </label>
          <input id="sliderTitleLine3Secondary" type="text" placeholder="Title Line 3" name="sliderTitleLine3Secondary" value={form.sliderTitleLine3Secondary} onChange={handleInputChangeSlider} className={`${formErrors.sliderTitleLine3Secondary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderTitleLine3Secondary*/}

        {/*sliderSubTitleLine1Secondary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderSubTitleLine1Secondary">
            <b>Sub Title Line 1:</b>
          </label>
          <input id="sliderSubTitleLine1Secondary" type="text" placeholder="Sub Title Line 1" name="sliderSubTitleLine1Secondary" value={form.sliderSubTitleLine1Secondary} onChange={handleInputChangeSlider} className={`${formErrors.sliderSubTitleLine1Secondary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderSubTitleLine1Secondary*/}

        {/*sliderSubTitleLine2Secondary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderSubTitleLine2Secondary">
            <b>Sub Title Line 2:</b>
          </label>
          <input id="sliderSubTitleLine2Secondary" type="text" placeholder="Sub Title Line 2" name="sliderSubTitleLine2Secondary" value={form.sliderSubTitleLine2Secondary} onChange={handleInputChangeSlider} className={`${formErrors.sliderSubTitleLine2Secondary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderSubTitleLine2Secondary*/}

        {/*sliderSubTitleLine3Secondary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderSubTitleLine3Secondary">
            <b>Sub Title Line 3:</b>
          </label>
          <input id="sliderSubTitleLine3Secondary" type="text" placeholder="Sub Title Line 3" name="sliderSubTitleLine3Secondary" value={form.sliderSubTitleLine3Secondary} onChange={handleInputChangeSlider} className={`${formErrors.sliderSubTitleLine3Secondary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderSubTitleLine3Secondary*/}

        {/*sliderBtnTextSecondary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderBtnTextSecondary">
            <b>Button Text:</b>
          </label>
          <input id="sliderBtnTextSecondary" type="text" placeholder="Button Text" name="sliderBtnTextSecondary" value={form.sliderBtnTextSecondary} onChange={handleInputChangeSlider} className={`${formErrors.sliderBtnTextSecondary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderBtnTextSecondary*/}

        {/*sliderUrlRedirectSecondary*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="sliderUrlRedirectSecondary">
            <b>Button URL redirect:</b>
          </label>
          <input id="sliderUrlRedirectSecondary" type="text" placeholder="Button URL redirect" name="sliderUrlRedirectSecondary" value={form.sliderUrlRedirectSecondary} onChange={handleInputChangeSlider} className={`${formErrors.sliderUrlRedirectSecondary ? 'input-error-important' : ''}`} />
        </div>
        {/*sliderUrlRedirectSecondary*/}
      </div>
    </div>
  );
};
export default SecondaryLangSlideData;
