import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2Primary = ({ translations, activeTabKey, handleInputChangeFormSection2, formSection2, fpImages, fpImagesErrors, fpImagesPreview, setFpImages, formSection2Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>
              {translations?.logo} {translations?.image}
            </span>
            <span className="ml-6">
              <TooltipDash text={`${translations?.imageProportions} 5/1 (300x60)px`} acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload translations={translations} activeTabKey={activeTabKey} fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} toStoreImg="section2ImagePrimary" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_sec2MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2MainHeadingPart1">
            <b>{translations?.mainHeadingLine} 1:</b>
          </label>
          <input id="primary_sec2MainHeadingPart1" type="text" placeholder={`${translations?.mainHeadingLine} 1`} name="primary_sec2MainHeadingPart1" value={formSection2.primary.sec2MainHeadingPart1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec2MainHeadingPart1*/}
        {/*primary_sec2MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2MainHeadingPart2">
            <b>{translations?.mainHeadingLine} 2:</b>
          </label>
          <input id="primary_sec2MainHeadingPart2" type="text" placeholder={`${translations?.mainHeadingLine} 2`} name="primary_sec2MainHeadingPart2" value={formSection2.primary.sec2MainHeadingPart2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec2MainHeadingPart2*/}
        {/*primary_sec2MainHeadingPart3*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2MainHeadingPart3">
            <b>{translations?.mainHeadingLine} 3:</b>
          </label>
          <input id="primary_sec2MainHeadingPart3" type="text" placeholder={`${translations?.mainHeadingLine} 3`} name="primary_sec2MainHeadingPart3" value={formSection2.primary.sec2MainHeadingPart3} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2MainHeadingPart3 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec2MainHeadingPart3*/}
        {/*primary_sec2ButtonText*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2ButtonText">
            <b>{translations?.btnText}:</b>
          </label>
          <input id="primary_sec2ButtonText" type="text" placeholder={`${translations?.btnText}`} name="primary_sec2ButtonText" value={formSection2.primary.sec2ButtonText} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2ButtonText ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec2ButtonText*/}
        {/*primary_sec2ButtonRedirect*/}
        {/* <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2ButtonRedirect">
            <b>URL dugmeta:</b>
          </label>
          <input id="primary_sec2ButtonRedirect" type="text" placeholder="URL dugmeta" name="primary_sec2ButtonRedirect" value={formSection2.primary.sec2ButtonRedirect} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2ButtonRedirect ? 'input-error-important' : ''}`} />
        </div> */}
        {/*primary_sec2ButtonRedirect*/}
        {/*primary_sec2LogoText*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2LogoText">
            <b>
              {translations?.logo} {translations?.text}:
            </b>
          </label>
          <input id="primary_sec2LogoText" type="text" placeholder={`${translations?.logo} ${translations?.text}`} name="primary_sec2LogoText" value={formSection2.primary.sec2LogoText} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2LogoText ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec2LogoText*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Korak 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.step} 1:</p>
          {/*primary_sec2Step1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Step1Title">
              <b>
                {translations?.step} 1 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec2Step1Title" type="text" placeholder={`${translations?.step} 1 ${translations?.title}`} name="primary_sec2Step1Title" value={formSection2.primary.sec2Step1Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Step1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec2Step1Title*/}
          {/*primary_sec2Step1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Step1Text">
              <b>
                {translations?.step} 1 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec2Korak1Text" type="text" placeholder={`${translations?.step} 1 ${translations?.text}`} name="primary_sec2Step1Text" value={formSection2.primary.sec2Step1Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Step1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec2Step1Text*/}
        </div>
        {/*Korak 1*/}

        {/*Korak 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.step} 2:</p>
          {/*primary_sec2Step2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Step2Title">
              <b>
                {translations?.step} 2 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec2Step2Title" type="text" placeholder={`${translations?.step} 2 ${translations?.title}`} name="primary_sec2Step2Title" value={formSection2.primary.sec2Step2Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Step2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec2Step2Title*/}
          {/*primary_sec2Step2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Step2Text">
              <b>
                {translations?.step} 2 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec2Step2Text" type="text" placeholder={`${translations?.step} 2 ${translations?.text}`} name="primary_sec2Step2Text" value={formSection2.primary.sec2Step2Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Step2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec2Step2Text*/}
        </div>
        {/*Korak 2*/}

        {/*Korak 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.step} 3:</p>
          {/*primary_sec2Step3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Step3Title">
              <b>
                {translations?.step} 3 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec2Step3Title" type="text" placeholder={`${translations?.step} 3 ${translations?.title}`} name="primary_sec2Step3Title" value={formSection2.primary.sec2Step3Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Step3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec2Step3Title*/}
          {/*primary_sec2Step3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Step3Text">
              <b>
                {translations?.step} 3 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec2Korak3Text" type="text" placeholder={`${translations?.step} 3 ${translations?.text}`} name="primary_sec2Step3Text" value={formSection2.primary.sec2Step3Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Step3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec2Step3Text*/}
        </div>
        {/*Korak 3*/}
      </div>
    </>
  );
};
export default Section2Primary;
