import React, { memo, useState } from 'react';
import { Tabs } from 'antd';
import Section4Secondary from '../tabs/secondary/Section4Secondary';
import Section4Primary from '../tabs/primary/Section4Primary';
import CheckboxCms from '../../../../components/checkbox-cms';
import { useTranslation } from 'react-i18next';

const CmsHomepageSection4 = ({ translations, countryCodeExtension, handleInputChangeFormSection4, formSection4, setHpImages, hpImages, hpImagesPreview, formSection4Errors, hpImagesErrors }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const [activeTabKey, setActiveTabKey] = useState('1');

  const onChangeTabSection4 = (key) => {
    setActiveTabKey(key);
  };

  const sectionProps = {
    handleInputChangeFormSection4,
    formSection4,
    setHpImages,
    hpImages,
    hpImagesPreview,
    formSection4Errors,
    hpImagesErrors,
    translations,
    activeTabKey,
  };
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSection4}>
        <TabPane key="1" tab="EN">
          <Section4Secondary {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab={countryCodeExtension?.toUpperCase()}>
          <Section4Primary {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName="section4_hide" isChecked={formSection4.hide} handleChange={handleInputChangeFormSection4} label={activeTabKey === '2' ? translations?.hideSection : 'Hide entire section on website'} tooltipText={activeTabKey === '2' ? translations?.hideSectionTooltip : 'Hide entire section on website'} />
    </>
  );
};
export default memo(CmsHomepageSection4);
