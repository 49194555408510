import React, { useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import { PERMISSIONS } from '../../../utils/permissions';

import { useAuth } from '../../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import { notification } from 'antd';
import CheckboxCms from '../../../components/checkbox-cms';

import CmsExploreNetworkSection1 from './sections/CmsExploreNetworkSection1';
import CmsExploreNetworkSection2 from './sections/CmsExploreNetworkSection2';

import { constructInitialFormSection1State, constructInitialFormSection2State, constructInitialEnImagesState, constructInitialEnImagesPreviewState } from './helpers/helpers';
import { cmsTranslations } from '../../../components/cmsTranslations';

const CmsExploreNetwork = () => {
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  let countryCodeExtension = null;
  const getCountryExtensionFromCountryCode = (countryCode) => {
    switch (countryCode) {
      case '66a76a9f198a9e71fd572334':
        countryCodeExtension = 'sr';
        break;
      case '6740868b48686db16c24f818':
        countryCodeExtension = 'cg';
        break;
    }
  };
  const countryExtension = getCountryExtensionFromCountryCode(user?.countryCodes[0]) || null;
  const translations = cmsTranslations[countryCodeExtension];

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // GENERAL SETTINGS HANDLE
  const [formGeneralSettings, setFormGeneralSettings] = useState({
    hide: false,
  });

  // SECTION 1 HANDLE
  const [enImages, setEnImages] = React.useState({
    section1Icon1Secondary: '',
    section1Icon2Secondary: '',
    section2Image1Secondary: '',

    section1Icon1Primary: '',
    section1Icon2Primary: '',
    section2Image1Primary: '',
  });
  const [enImagesErrors, setEnImagesErrors] = React.useState({
    section1Icon1Secondary: false,
    section1Icon2Secondary: false,
    section2Image1Secondary: false,

    section1Icon1Primary: false,
    section1Icon2Primary: false,
    section2Image1Primary: false,
  });
  const [enImagesPreview, setEnImagesPreview] = React.useState({
    section1Icon1Secondary: '',
    section1Icon2Secondary: '',
    section2Image1Secondary: '',

    section1Icon1Primary: '',
    section1Icon2Primary: '',
    section2Image1Primary: '',
  });

  const [formSection1, setFormSection1] = useState({
    hide: false,
    secondary: {
      title1: '',
      title2: '',
      subTitle1Part1: '',
      subTitle1Part2: '',
      subTitle2Part1: '',
      subTitle2Part2: '',
      button1Text: '',
      button2Text: '',
      button1Url: '',
      button2Url: '',
    },
    primary: {
      title1: '',
      title2: '',
      subTitle1Part1: '',
      subTitle1Part2: '',
      subTitle2Part1: '',
      subTitle2Part2: '',
      button1Text: '',
      button2Text: '',
      button1Url: '',
      button2Url: '',
    },
  });

  const [formSection1Errors, setFormSection1Errors] = React.useState({
    secondary: {
      title1: false,
      title2: false,
      subTitle1Part1: false,
      subTitle1Part2: false,
      subTitle2Part1: false,
      subTitle2Part2: false,
      button1Text: false,
      button2Text: false,
      button1Url: false,
      button2Url: false,
    },
    primary: {
      title1: false,
      title2: false,
      subTitle1Part1: false,
      subTitle1Part2: false,
      subTitle2Part1: false,
      subTitle2Part2: false,
      button1Text: false,
      button2Text: false,
      button1Url: false,
      button2Url: false,
    },
  });

  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // SECTION 2 HANDLE
  const [formSection2, setFormSection2] = React.useState({
    hide: false,
    secondary: {
      mainHeading: '',
      list1item1: '',
      list1item2: '',
      list1item3: '',
      list1item4: '',
    },
    primary: {
      mainHeading: '',
      list1item1: '',
      list1item2: '',
      list1item3: '',
      list1item4: '',
    },
  });
  const [formSection2Errors, setFormSection2Errors] = React.useState({
    secondary: {
      mainHeading: false,
      list1item1: false,
      list1item2: false,
      list1item3: false,
      list1item4: false,
    },
    primary: {
      mainHeading: false,
      list1item1: false,
      list1item2: false,
      list1item3: false,
      list1item4: false,
    },
  });
  const handleInputChangeFormSection2 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section2_hide') {
      setFormSection2((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection2((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection2 = useCallback(() => {}, []);
  // SECTION 2 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSection2Errors = { ...formSection2Errors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.secondary).forEach((key) => {
      if (!formSection1.secondary[key]) {
        newFormSection1Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.secondary[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.primary).forEach((key) => {
      if (!formSection1.primary[key]) {
        newFormSection1Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.primary[key] = false;
      }
    });

    // Validate Section 2 (EN)
    Object.keys(formSection2.secondary).forEach((key) => {
      if (!formSection2.secondary[key]) {
        newFormSection2Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.secondary[key] = false;
      }
    });

    // Validate Section 2 (CG)
    Object.keys(formSection2.primary).forEach((key) => {
      if (!formSection2.primary[key]) {
        newFormSection2Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.primary[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(enImages).forEach((key) => {
      if (enImages[key] === null) {
        setEnImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setEnImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    setFormSection1Errors(newFormSection1Errors);
    setFormSection2Errors(newFormSection2Errors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      enHide: false,

      section1Hide: formSection1.hide || false,
      section2Hide: formSection2.hide || false,

      section1Card1TitleSecondary: formSection1.secondary.title1 || '',
      section1Card1SubtitlePart1Secondary: formSection1.secondary.subTitle1Part1 || '',
      section1Card1SubtitlePart2Secondary: formSection1.secondary.subTitle1Part2 || '',
      section1Card2SubtitlePart1Secondary: formSection1.secondary.subTitle2Part1 || '',
      section1Card2SubtitlePart2Secondary: formSection1.secondary.subTitle2Part2 || '',
      section1Card1ButtonTextSecondary: formSection1.secondary.button1Text || '',
      section1Card1ButtonUrlSecondary: formSection1.secondary.button1Url || '',
      section1Card2TitleSecondary: formSection1.secondary.title2 || '',
      section1Card2ButtonTextSecondary: formSection1.secondary.button2Text || '',
      section1Card2ButtonUrlSecondary: formSection1.secondary.button2Url || '',

      section1Card1TitlePrimary: formSection1.primary.title1 || '',
      section1Card1SubtitlePart1Primary: formSection1.primary.subTitle1Part1 || '',
      section1Card1SubtitlePart2Primary: formSection1.primary.subTitle1Part2 || '',
      section1Card2SubtitlePart1Primary: formSection1.primary.subTitle2Part1 || '',
      section1Card2SubtitlePart2Primary: formSection1.primary.subTitle2Part2 || '',
      section1Card1ButtonTextPrimary: formSection1.primary.button1Text || '',
      section1Card1ButtonUrlPrimary: formSection1.primary.button1Url || '',
      section1Card2TitlePrimary: formSection1.primary.title2 || '',
      section1Card2ButtonTextPrimary: formSection1.primary.button2Text || '',
      section1Card2ButtonUrlPrimary: formSection1.primary.button2Url || '',

      section2MainHeadingSecondary: formSection2.secondary.mainHeading || '',
      section2List1Item1Secondary: formSection2.secondary.list1item1 || '',
      section2List1Item2Secondary: formSection2.secondary.list1item2 || '',
      section2List1Item3Secondary: formSection2.secondary.list1item3 || '',
      section2List1Item4Secondary: formSection2.secondary.list1item4 || '',

      section2MainHeadingPrimary: formSection2.primary.mainHeading || '',
      section2List1Item1Primary: formSection2.primary.list1item1 || '',
      section2List1Item2Primary: formSection2.primary.list1item2 || '',
      section2List1Item3Primary: formSection2.primary.list1item3 || '',
      section2List1Item4Primary: formSection2.primary.list1item4 || '',

      // IMAGES
      section1Card1IconSecondary: enImages.section1Icon1Secondary || null,
      section1Card2IconSecondary: enImages.section1Icon2Secondary || null,
      section2Image1Secondary: enImages.section2Image1Secondary || null,

      section1Card1IconPrimary: enImages.section1Icon1Primary || null,
      section1Card2IconPrimary: enImages.section1Icon2Primary || null,
      section2Image1Primary: enImages.section2Image1Primary || null,

      countryCode: user?.countryCodes?.[0] || '',
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createEnDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateEnDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getEnDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (!initialData) return;
    const data = initialData[0];
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.enHide || false,
    }));
    setFormSection1((prevState) => constructInitialFormSection1State(data, prevState));
    setFormSection2((prevState) => constructInitialFormSection2State(data, prevState));
    setEnImages((prevState) => constructInitialEnImagesState(data, prevState));
    setEnImagesPreview((prevState) => constructInitialEnImagesPreviewState(data, prevState));
  }, [initialData]);
  // HANDLE FETCH AND SET INITIAL DATA

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'en_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  return (
    <div className="cms cms-explore-network">
      <h2 className="page-title">CMS Explore network</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* SECTION 1 (Companies - Partners) */}
            <Panel header="SECTION 1 (Companies - Partners)" key="1">
              <CmsExploreNetworkSection1 handleInputChangeFormSection1={handleInputChangeFormSection1} formSection1={formSection1} setEnImages={setEnImages} enImages={enImages} enImagesErrors={enImagesErrors} enImagesPreview={enImagesPreview} formSection1Errors={formSection1Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 1 (Companies - Partners) */}
            {/* SECTION 2 (Everyone wins with Fitpass!) */}
            <Panel header="SECTION 2 (Everyone wins with Fitpass!)" key="2">
              <CmsExploreNetworkSection2 formSection2={formSection2} handleInputChangeFormSection2={handleInputChangeFormSection2} setEnImages={setEnImages} enImages={enImages} enImagesErrors={enImagesErrors} enImagesPreview={enImagesPreview} formSection2Errors={formSection2Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 2 (Everyone wins with Fitpass!) */}
          </Collapse>
          {/* <CheckboxCms inputName="en_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" /> */}
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsExploreNetwork;
