import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2Secondary = ({ handleInputChangeFormSection2, formSection2, fcImages, fcImagesErrors, fcImagesPreview, setFcImages, formSection2Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Image</span>
            <span className="ml-6">
              <TooltipDash text="Image aspect ratio 1/2 (584x1188)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section2ImageSecondary" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*secondary_sec2MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_sec2MainHeadingPart1">
            <b>Main heading part 1:</b>
          </label>
          <input id="secondary_sec2MainHeadingPart1" type="text" placeholder="Main heading part 1" name="secondary_sec2MainHeadingPart1" value={formSection2.secondary.sec2MainHeadingPart1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_sec2MainHeadingPart1*/}
        {/*secondary_sec2MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_sec2MainHeadingPart2">
            <b>Main heading part 2:</b>
          </label>
          <input id="secondary_sec2MainHeadingPart2" type="text" placeholder="Main heading part 2" name="secondary_sec2MainHeadingPart2" value={formSection2.secondary.sec2MainHeadingPart2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_sec2MainHeadingPart2*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Advantage 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Advantage 1:</p>
          {/*secondary_sec2Advantage1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec2Advantage1Title">
              <b>Advantage 1 Title:</b>
            </label>
            <textarea id="secondary_sec2Advantage1Title" type="text" placeholder="Advantage 1 Title" name="secondary_sec2Advantage1Title" value={formSection2.secondary.sec2Advantage1Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2Advantage1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec2Advantage1Title*/}
          {/*secondary_sec2Advantage1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec2Advantage1Text">
              <b>Advantage 1 Text:</b>
            </label>
            <textarea id="secondary_sec2Advantage1Text" type="text" placeholder="Advantage 1 Text" name="secondary_sec2Advantage1Text" value={formSection2.secondary.sec2Advantage1Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2Advantage1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec2Advantage1Text*/}
        </div>
        {/*Advantage 1*/}

        {/*Advantage 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Advantage 2:</p>
          {/*secondary_sec2Advantage2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec2Advantage2Title">
              <b>Advantage 2 Title:</b>
            </label>
            <textarea id="secondary_sec2Advantage2Title" type="text" placeholder="Advantage 2 Title" name="secondary_sec2Advantage2Title" value={formSection2.secondary.sec2Advantage2Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2Advantage2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec2Advantage2Title*/}
          {/*secondary_sec2Advantage2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec2Advantage2Text">
              <b>Advantage 2 Text:</b>
            </label>
            <textarea id="secondary_sec2Advantage2Text" type="text" placeholder="Advantage 2 Text" name="secondary_sec2Advantage2Text" value={formSection2.secondary.sec2Advantage2Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2Advantage2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec2Advantage2Text*/}
        </div>
        {/*Advantage 2*/}

        {/*Advantage 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Advantage 3:</p>
          {/*secondary_sec2Advantage3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec2Advantage3Title">
              <b>Advantage 3 Title:</b>
            </label>
            <textarea id="secondary_sec2Advantage3Title" type="text" placeholder="Advantage 3 Title" name="secondary_sec2Advantage3Title" value={formSection2.secondary.sec2Advantage3Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2Advantage3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec2Advantage3Title*/}
          {/*secondary_sec2Advantage3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec2Advantage3Text">
              <b>Advantage 3 Text:</b>
            </label>
            <textarea id="secondary_sec2Advantage3Text" type="text" placeholder="Advantage 3 Text" name="secondary_sec2Advantage3Text" value={formSection2.secondary.sec2Advantage3Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2Advantage3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec2Advantage3Text*/}
        </div>
        {/*Advantage 3*/}

        {/*Advantage 4*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Advantage 4:</p>
          {/*secondary_sec2Advantage4Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec2Advantage4Title">
              <b>Advantage 4 Title:</b>
            </label>
            <textarea id="secondary_sec2Advantage4Title" type="text" placeholder="Advantage 4 Title" name="secondary_sec2Advantage4Title" value={formSection2.secondary.sec2Advantage4Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2Advantage4Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec2Advantage4Title*/}
          {/*secondary_sec2Advantage4Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec2Advantage4Text">
              <b>Advantage 4 Text:</b>
            </label>
            <textarea id="secondary_sec2Advantage4Text" type="text" placeholder="Advantage 4 Text" name="secondary_sec2Advantage4Text" value={formSection2.secondary.sec2Advantage4Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.secondary.sec2Advantage4Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec2Advantage4Text*/}
        </div>
        {/*Advantage 4*/}
      </div>
    </>
  );
};
export default Section2Secondary;
