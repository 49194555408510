import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2Primary = ({ translations, activeTabKey, handleInputChangeFormSection2, formSection2, fcImages, fcImagesErrors, fcImagesPreview, setFcImages, formSection2Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>{translations?.image}</span>
            <span className="ml-6">
              <TooltipDash text={`${translations?.imageProportions} 1/2 (584x1188)px`} acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload translations={translations} activeTabKey={activeTabKey} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section2ImagePrimary" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_sec2MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2MainHeadingPart1">
            <b>{translations?.mainHeadingPart} 1:</b>
          </label>
          <input id="primary_sec2MainHeadingPart1" type="text" placeholder={`${translations?.mainHeadingPart} 1`} name="primary_sec2MainHeadingPart1" value={formSection2.primary.sec2MainHeadingPart1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec2MainHeadingPart1*/}
        {/*primary_sec2MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec2MainHeadingPart2">
            <b>{translations?.mainHeadingPart} 2:</b>
          </label>
          <input id="primary_sec2MainHeadingPart2" type="text" placeholder={`${translations?.mainHeadingPart} 2`} name="primary_sec2MainHeadingPart2" value={formSection2.primary.sec2MainHeadingPart2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec2MainHeadingPart2*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Advantage 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.advantage} 1:</p>
          {/*primary_sec2Advantage1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Advantage1Title">
              <b>
                {translations?.advantage} 1 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec2Advantage1Title" type="text" placeholder={`${translations?.advantage} 1 ${translations?.title}`} name="primary_sec2Advantage1Title" value={formSection2.primary.sec2Advantage1Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Advantage1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec2Advantage1Title*/}
          {/*primary_sec2Advantage1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Advantage1Text">
              <b>
                {translations?.advantage} 1 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec2Advantage1Text" type="text" placeholder={`${translations?.advantage} 1 ${translations?.text}`} name="primary_sec2Advantage1Text" value={formSection2.primary.sec2Advantage1Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Advantage1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec2Advantage1Text*/}
        </div>
        {/*Advantage 1*/}

        {/*Advantage 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.advantage} 2:</p>
          {/*primary_sec2Advantage2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Advantage2Title">
              <b>
                {translations?.advantage} 2 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec2Advantage2Title" type="text" placeholder={`${translations?.advantage} 2 ${translations?.title}`} name="primary_sec2Advantage2Title" value={formSection2.primary.sec2Advantage2Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Advantage2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec2Advantage2Title*/}
          {/*primary_sec2Advantage2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Advantage2Text">
              <b>
                {translations?.advantage} 2 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec2Advantage2Text" type="text" placeholder={`${translations?.advantage} 2 ${translations?.text}`} name="primary_sec2Advantage2Text" value={formSection2.primary.sec2Advantage2Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Advantage2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec2Advantage2Text*/}
        </div>
        {/*Advantage 2*/}

        {/*Advantage 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.advantage} 3:</p>
          {/*primary_sec2Advantage3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Advantage3Title">
              <b>
                {translations?.advantage} 3 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec2Advantage3Title" type="text" placeholder={`${translations?.advantage} 3 ${translations?.title}`} name="primary_sec2Advantage3Title" value={formSection2.primary.sec2Advantage3Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Advantage3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec2Advantage3Title*/}
          {/*primary_sec2Advantage3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Advantage3Text">
              <b>
                {translations?.advantage} 3 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec2Advantage3Text" type="text" placeholder={`${translations?.advantage} 3 ${translations?.text}`} name="primary_sec2Advantage3Text" value={formSection2.primary.sec2Advantage3Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Advantage3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec2Advantage3Text*/}
        </div>
        {/*Advantage 3*/}

        {/*Advantage 4*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.advantage} 4:</p>
          {/*primary_sec2Advantage4Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Advantage4Title">
              <b>
                {translations?.advantage} 4 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec2Advantage4Title" type="text" placeholder={`${translations?.advantage} 4 ${translations?.title}`} name="primary_sec2Advantage4Title" value={formSection2.primary.sec2Advantage4Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Advantage4Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec2Advantage4Title*/}
          {/*primary_sec2Advantage4Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec2Advantage4Text">
              <b>
                {translations?.advantage} 4 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec2Advantage4Text" type="text" placeholder={`${translations?.advantage} 4 ${translations?.text}`} name="primary_sec2Advantage4Text" value={formSection2.primary.sec2Advantage4Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.primary.sec2Advantage4Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec2Advantage4Text*/}
        </div>
        {/*Advantage 4*/}
      </div>
    </>
  );
};
export default Section2Primary;
