const Section3Primary = ({ translations, activeTabKey, handleInputChangeFormSection3, formSection3, formSection3Errors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_sec3MainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec3MainHeading">
            <b>{translations?.mainHeading}:</b>
          </label>
          <input id="primary_sec3MainHeading" type="text" placeholder="Glavni naslov" name="primary_sec3MainHeading" value={formSection3.primary.sec3MainHeading} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3MainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec3MainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Info 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.info} 1:</p>
          {/*primary_sec3Info1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Info1Title">
              <b>
                {translations?.info} 1 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec3Info1Title" type="text" placeholder={`${translations?.info} 1 ${translations?.title}`} name="primary_sec3Info1Title" value={formSection3.primary.sec3Info1Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Info1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec3Info1Title*/}
          {/*primary_sec3Info1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Info1Text">
              <b>
                {translations?.info} 1 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec3Info1Text" type="text" placeholder={`${translations?.info} 1 ${translations?.text}`} name="primary_sec3Info1Text" value={formSection3.primary.sec3Info1Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Info1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec3Info1Text*/}
        </div>
        {/*Info 1*/}

        {/*Info 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.info} 2:</p>
          {/*primary_sec3Info2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Info2Title">
              <b>
                {translations?.info} 2 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec3Info2Title" type="text" placeholder={`${translations?.info} 2 ${translations?.title}`} name="primary_sec3Info2Title" value={formSection3.primary.sec3Info2Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Info2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec3Info2Title*/}
          {/*primary_sec3Info2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Info2Text">
              <b>
                {translations?.info} 2 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec3Info2Text" type="text" placeholder={`${translations?.info} 2 ${translations?.text}`} name="primary_sec3Info2Text" value={formSection3.primary.sec3Info2Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Info2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec3Info2Text*/}
        </div>
        {/*Info 2*/}

        {/*Info 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.info} 3:</p>
          {/*primary_sec3Info3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Info3Title">
              <b>
                {translations?.info} 3 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec3Info3Title" type="text" placeholder={`${translations?.info} 3 ${translations?.title}`} name="primary_sec3Info3Title" value={formSection3.primary.sec3Info3Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Info3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec3Info3Title*/}
          {/*primary_sec3Info3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Info3Text">
              <b>
                {translations?.info} 3 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec3Info3Text" type="text" placeholder={`${translations?.info} 3 ${translations?.text}`} name="primary_sec3Info3Text" value={formSection3.primary.sec3Info3Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Info3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec3Info3Text*/}
        </div>
        {/*Info 3*/}

        {/*Info 4*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.info} 4:</p>
          {/*primary_sec3Info4Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Info4Title">
              <b>
                {translations?.info} 4 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec3Info4Title" type="text" placeholder={`${translations?.info} 4 ${translations?.title}`} name="primary_sec3Info4Title" value={formSection3.primary.sec3Info4Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Info4Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec3Info4Title*/}
          {/*primary_sec3Info4Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Info4Text">
              <b>
                {translations?.info} 4 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec3Info4Text" type="text" placeholder={`${translations?.info} 4 ${translations?.text}`} name="primary_sec3Info4Text" value={formSection3.primary.sec3Info4Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Info4Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec3Info4Text*/}
        </div>
        {/*Info 4*/}
      </div>
    </>
  );
};
export default Section3Primary;
