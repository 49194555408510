import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const SectionHeaderSecondary = ({ handleInputChangeFormSectionHeader, formSectionHeader, clImages, clImagesErrors, clImagesPreview, setClImages, formSectionHeaderErrors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="cms-form-head-inner" style={{ display: 'flex', gap: '30px', border: 'none', marginBottom: '0', paddingBottom: '0' }}>
            <div>
              <div className="images-card-header">
                <span>Desktop</span>
                <span className="ml-6">
                  <TooltipDash acceptFormatText={true} />
                </span>
              </div>
              <div className="upload-wrapper">
                <ImageUpload clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="sectionHeaderImageSecondaryDesktop" />
              </div>
            </div>
            <div>
              <div className="images-card-header">
                <span>Tablet</span>
                <span className="ml-6">
                  <TooltipDash acceptFormatText={true} />
                </span>
              </div>
              <div className="upload-wrapper">
                <ImageUpload clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="sectionHeaderImageSecondaryTablet" />
              </div>
            </div>
            <div>
              <div className="images-card-header">
                <span>Mobile</span>
                <span className="ml-6">
                  <TooltipDash acceptFormatText={true} />
                </span>
              </div>
              <div className="upload-wrapper">
                <ImageUpload clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="sectionHeaderImageSecondaryMobile" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*secondary_headMainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_headMainHeadingPart1">
            <b>Main heading part 1:</b>
          </label>
          <input id="secondary_headMainHeadingPart1" type="text" placeholder="Main heading part 1" name="secondary_headMainHeadingPart1" value={formSectionHeader.secondary.headMainHeadingPart1} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.secondary.headMainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_headMainHeadingPart1*/}
        {/*secondary_headMainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_headMainHeadingPart2">
            <b>Main heading part 2:</b>
          </label>
          <input id="secondary_headMainHeadingPart2" type="text" placeholder="Main heading part 2" name="secondary_headMainHeadingPart2" value={formSectionHeader.secondary.headMainHeadingPart2} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.secondary.headMainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_headMainHeadingPart2*/}
        {/*secondary_headText*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_headText">
            <b>Main text:</b>
          </label>
          <input id="secondary_headText" type="text" placeholder="Main head text" name="secondary_headText" value={formSectionHeader.secondary.headText} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.secondary.headText ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_headText*/}
      </div>
    </>
  );
};
export default SectionHeaderSecondary;
