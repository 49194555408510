import React, { useState, useCallback, useEffect } from 'react';
import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { useAuth } from '../../../context/useAuth';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { PERMISSIONS } from '../../../utils/permissions';
import { SERVER_URL } from '../../../config/index';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import CheckboxCms from '../../../components/checkbox-cms';

import CmsForPartnersSection1 from './sections/CmsForPartnersSection1';
import CmsForPartnersSection2 from './sections/CmsForPartnersSection2';
import CmsForPartnersSection3 from './sections/CmsForPartnersSection3';
import CmsForPartnersSectionHeader from './sections/CmsForPartnersSectionHeader';

import { cmsTranslations } from '../../../components/cmsTranslations';
import { useTranslation } from 'react-i18next';

const CmsForPartners = () => {
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  let countryCodeExtension = null;
  const getCountryExtensionFromCountryCode = (countryCode) => {
    switch (countryCode) {
      case '66a76a9f198a9e71fd572334':
        countryCodeExtension = 'sr';
        break;
      case '6740868b48686db16c24f818':
        countryCodeExtension = 'cg';
        break;
    }
  };
  const countryExtension = getCountryExtensionFromCountryCode(user?.countryCodes[0]) || null;
  const translations = cmsTranslations[countryCodeExtension];

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // IMAGES HANDLE
  const [fpImages, setFpImages] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',

    section1ImageSecondary: '',
    section2ImageSecondary: '',

    section1ImagePrimary: '',
    section2ImagePrimary: '',
  });
  const [fpImagesPreview, setFpImagesPreview] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',

    section1ImageSecondary: '',
    section2ImageSecondary: '',

    section1ImagePrimary: '',
    section2ImagePrimary: '',
  });
  const [fpImagesErrors, setFpImagesErrors] = React.useState({
    sectionHeaderImageSecondaryDesktop: false,
    sectionHeaderImagePrimaryDesktop: false,
    sectionHeaderImageSecondaryTablet: false,
    sectionHeaderImagePrimaryTablet: false,
    sectionHeaderImageSecondaryMobile: false,
    sectionHeaderImagePrimaryMobile: false,

    section1ImageSecondary: false,
    section2ImageSecondary: false,

    section1ImagePrimary: false,
    section2ImagePrimary: false,
  });
  // IMAGES HANDLE

  // HEADER HANDLE
  const [formSectionHeader, setFormSectionHeader] = React.useState({
    secondary: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
    primary: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
  });
  const [formSectionHeaderErrors, setFormSectionHeaderErrors] = React.useState({
    secondary: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
    primary: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
  });
  const handleInputChangeFormSectionHeader = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    setFormSectionHeader((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSectionHeader = useCallback(() => {}, []);
  // HEADER HANDLE

  // GENERAL SETTINGS HANDLE
  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });

  // SECTION 1 HANDLE
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    secondary: {
      sec1MainHeadingPart1: '',
      sec1MainHeadingPart2: '',
      sec1MainHeadingPart3: '',
      sec1Advantage1Title: '',
      sec1Advantage1Text: '',
      sec1Advantage2Title: '',
      sec1Advantage2Text: '',
      sec1Advantage3Title: '',
      sec1Advantage3Text: '',
    },
    primary: {
      sec1MainHeadingPart1: '',
      sec1MainHeadingPart2: '',
      sec1MainHeadingPart3: '',
      sec1Advantage1Title: '',
      sec1Advantage1Text: '',
      sec1Advantage2Title: '',
      sec1Advantage2Text: '',
      sec1Advantage3Title: '',
      sec1Advantage3Text: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    secondary: {
      sec1MainHeadingPart1: false,
      sec1MainHeadingPart2: false,
      sec1MainHeadingPart3: false,
      sec1Advantage1Title: false,
      sec1Advantage1Text: false,
      sec1Advantage2Title: false,
      sec1Advantage2Text: false,
      sec1Advantage3Title: false,
      sec1Advantage3Text: false,
    },
    primary: {
      sec1MainHeadingPart1: false,
      sec1MainHeadingPart2: false,
      sec1MainHeadingPart3: false,
      sec1Advantage1Title: false,
      sec1Advantage1Text: false,
      sec1Advantage2Title: false,
      sec1Advantage2Text: false,
      sec1Advantage3Title: false,
      sec1Advantage3Text: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // SECTION 2 HANDLE
  const [formSection2, setFormSection2] = React.useState({
    hide: false,
    secondary: {
      sec2MainHeadingPart1: '',
      sec2MainHeadingPart2: '',
      sec2MainHeadingPart3: '',
      sec2Step1Title: '',
      sec2Step1Text: '',
      sec2Step2Title: '',
      sec2Step2Text: '',
      sec2Step3Title: '',
      sec2Step3Text: '',
      sec2ButtonText: '',
      sec2LogoText: '',
    },
    primary: {
      sec2MainHeadingPart1: '',
      sec2MainHeadingPart2: '',
      sec2MainHeadingPart3: '',
      sec2Step1Title: '',
      sec2Step1Text: '',
      sec2Step2Title: '',
      sec2Step2Text: '',
      sec2Step3Title: '',
      sec2Step3Text: '',
      sec2ButtonText: '',
      sec2LogoText: '',
    },
  });
  const [formSection2Errors, setFormSection2Errors] = React.useState({
    secondary: {
      sec2MainHeadingPart1: false,
      sec2MainHeadingPart2: false,
      sec2MainHeadingPart3: false,
      sec2Step1Title: false,
      sec2Step1Text: false,
      sec2Step2Title: false,
      sec2Step2Text: false,
      sec2Step3Title: false,
      sec2Step3Text: false,
      sec2ButtonText: false,
      sec2LogoText: false,
    },
    primary: {
      sec2MainHeadingPart1: false,
      sec2MainHeadingPart2: false,
      sec2MainHeadingPart3: false,
      sec2Step1Title: false,
      sec2Step1Text: false,
      sec2Step2Title: false,
      sec2Step2Text: false,
      sec2Step3Title: false,
      sec2Step3Text: false,
      sec2ButtonText: false,
      sec2LogoText: false,
    },
  });
  const handleInputChangeFormSection2 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section2_hide') {
      setFormSection2((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection2((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection2 = useCallback(() => {}, []);
  // SECTION 2 HANDLE

  // SECTION WANT MORE INFO HANDLE
  const [formSection3, setFormSection3] = React.useState({
    hide: false,
    secondary: {
      sec3MainHeading: '',
      sec3Info1Title: '',
      sec3Info1Text: '',
      sec3Info2Title: '',
      sec3Info2Text: '',
      sec3Info3Title: '',
      sec3Info3Text: '',
      sec3Info4Title: '',
      sec3Info4Text: '',
    },
    primary: {
      sec3MainHeading: '',
      sec3Info1Title: '',
      sec3Info1Text: '',
      sec3Info2Title: '',
      sec3Info2Text: '',
      sec3Info3Title: '',
      sec3Info3Text: '',
      sec3Info4Title: '',
      sec3Info4Text: '',
    },
  });
  const [formSection3Errors, setFormSection3Errors] = React.useState({
    secondary: {
      sec3MainHeading: false,
      sec3Info1Title: false,
      sec3Info1Text: false,
      sec3Info2Title: false,
      sec3Info2Text: false,
      sec3Info3Title: false,
      sec3Info3Text: false,
      sec3Info4Title: false,
      sec3Info4Text: false,
    },
    primary: {
      sec3MainHeading: false,
      sec3Info1Title: false,
      sec3Info1Text: false,
      sec3Info2Title: false,
      sec3Info2Text: false,
      sec3Info3Title: false,
      sec3Info3Text: false,
      sec3Info4Title: false,
      sec3Info4Text: false,
    },
  });
  const handleInputChangeFormSection3 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section3_hide') {
      setFormSection3((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection3((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection3 = useCallback(() => {}, []);
  // SECTION WANT MORE INFO HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSection2Errors = { ...formSection2Errors };
    let newFormSection3Errors = { ...formSection3Errors };
    let newFormSectionHeaderErrors = { ...formSectionHeaderErrors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.secondary).forEach((key) => {
      if (!formSection1.secondary[key]) {
        newFormSection1Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.secondary[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.primary).forEach((key) => {
      if (!formSection1.primary[key]) {
        newFormSection1Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.primary[key] = false;
      }
    });

    // Validate Section 2 (EN)
    Object.keys(formSection2.secondary).forEach((key) => {
      if (!formSection2.secondary[key]) {
        newFormSection2Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.secondary[key] = false;
      }
    });

    // Validate Section 2 (CG)
    Object.keys(formSection2.primary).forEach((key) => {
      if (!formSection2.primary[key]) {
        newFormSection2Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.primary[key] = false;
      }
    });

    // Validate Section 3 (EN)
    Object.keys(formSection3.secondary).forEach((key) => {
      if (!formSection3.secondary[key]) {
        newFormSection3Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.secondary[key] = false;
      }
    });

    // Validate Section 3 (CG)
    Object.keys(formSection3.primary).forEach((key) => {
      if (!formSection3.primary[key]) {
        newFormSection3Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.primary[key] = false;
      }
    });

    // Validate Section Header (EN)
    Object.keys(formSectionHeader.secondary).forEach((key) => {
      if (!formSectionHeader.secondary[key]) {
        newFormSectionHeaderErrors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.secondary[key] = false;
      }
    });

    // Validate Section Header (CG)
    Object.keys(formSectionHeader.primary).forEach((key) => {
      if (!formSectionHeader.primary[key]) {
        newFormSectionHeaderErrors.primary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.primary[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(fpImages).forEach((key) => {
      if (fpImages[key] === null || fpImages[key] === '') {
        setFpImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setFpImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSection2Errors(newFormSection2Errors);
    setFormSection3Errors(newFormSection3Errors);
    setFormSectionHeaderErrors(newFormSectionHeaderErrors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      fpHide: formGeneralSettings.hide || false,

      section1Hide: formSection1.hide || false,
      section2Hide: formSection2.hide || false,
      section3Hide: formSection3.hide || false,

      // header
      sectionHeaderMainHeadingPart1Secondary: formSectionHeader.secondary.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Secondary: formSectionHeader.secondary.headMainHeadingPart2 || '',

      sectionHeaderMainHeadingPart1Primary: formSectionHeader.primary.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Primary: formSectionHeader.primary.headMainHeadingPart2 || '',

      // section 1
      section1MainHeadingPart1Secondary: formSection1.secondary.sec1MainHeadingPart1 || '',
      section1MainHeadingPart2Secondary: formSection1.secondary.sec1MainHeadingPart2 || '',
      section1MainHeadingPart3Secondary: formSection1.secondary.sec1MainHeadingPart3 || '',
      section1Advantage1TitleSecondary: formSection1.secondary.sec1Advantage1Title || '',
      section1Advantage1TextSecondary: formSection1.secondary.sec1Advantage1Text || '',
      section1Advantage2TitleSecondary: formSection1.secondary.sec1Advantage2Title || '',
      section1Advantage2TextSecondary: formSection1.secondary.sec1Advantage2Text || '',
      section1Advantage3TitleSecondary: formSection1.secondary.sec1Advantage3Title || '',
      section1Advantage3TextSecondary: formSection1.secondary.sec1Advantage3Text || '',

      section1MainHeadingPart1Primary: formSection1.primary.sec1MainHeadingPart1 || '',
      section1MainHeadingPart2Primary: formSection1.primary.sec1MainHeadingPart2 || '',
      section1MainHeadingPart3Primary: formSection1.primary.sec1MainHeadingPart3 || '',
      section1Advantage1TitlePrimary: formSection1.primary.sec1Advantage1Title || '',
      section1Advantage1TextPrimary: formSection1.primary.sec1Advantage1Text || '',
      section1Advantage2TitlePrimary: formSection1.primary.sec1Advantage2Title || '',
      section1Advantage2TextPrimary: formSection1.primary.sec1Advantage2Text || '',
      section1Advantage3TitlePrimary: formSection1.primary.sec1Advantage3Title || '',
      section1Advantage3TextPrimary: formSection1.primary.sec1Advantage3Text || '',

      // section 2
      section2MainHeadingPart1Secondary: formSection2.secondary.sec2MainHeadingPart1 || '',
      section2MainHeadingPart2Secondary: formSection2.secondary.sec2MainHeadingPart2 || '',
      section2MainHeadingPart3Secondary: formSection2.secondary.sec2MainHeadingPart3 || '',
      section2Step1TitleSecondary: formSection2.secondary.sec2Step1Title || '',
      section2Step1TextSecondary: formSection2.secondary.sec2Step1Text || '',
      section2Step2TitleSecondary: formSection2.secondary.sec2Step2Title || '',
      section2Step2TextSecondary: formSection2.secondary.sec2Step2Text || '',
      section2Step3TitleSecondary: formSection2.secondary.sec2Step3Title || '',
      section2Step3TextSecondary: formSection2.secondary.sec2Step3Text || '',
      section2ButtonTextSecondary: formSection2.secondary.sec2ButtonText || '',
      section2LogoTextSecondary: formSection2.secondary.sec2LogoText || '',

      section2MainHeadingPart1Primary: formSection2.primary.sec2MainHeadingPart1 || '',
      section2MainHeadingPart2Primary: formSection2.primary.sec2MainHeadingPart2 || '',
      section2MainHeadingPart3Primary: formSection2.primary.sec2MainHeadingPart3 || '',
      section2Step1TitlePrimary: formSection2.primary.sec2Step1Title || '',
      section2Step1TextPrimary: formSection2.primary.sec2Step1Text || '',
      section2Step2TitlePrimary: formSection2.primary.sec2Step2Title || '',
      section2Step2TextPrimary: formSection2.primary.sec2Step2Text || '',
      section2Step3TitlePrimary: formSection2.primary.sec2Step3Title || '',
      section2Step3TextPrimary: formSection2.primary.sec2Step3Text || '',
      section2ButtonTextPrimary: formSection2.primary.sec2ButtonText || '',
      section2LogoTextPrimary: formSection2.primary.sec2LogoText || '',

      // section 3
      section3MainHeadingSecondary: formSection3.secondary.sec3MainHeading || '',
      section3Info1TitleSecondary: formSection3.secondary.sec3Info1Title || '',
      section3Info1TextSecondary: formSection3.secondary.sec3Info1Text || '',
      section3Info2TitleSecondary: formSection3.secondary.sec3Info2Title || '',
      section3Info2TextSecondary: formSection3.secondary.sec3Info2Text || '',
      section3Info3TitleSecondary: formSection3.secondary.sec3Info3Title || '',
      section3Info3TextSecondary: formSection3.secondary.sec3Info3Text || '',
      section3Info4TitleSecondary: formSection3.secondary.sec3Info4Title || '',
      section3Info4TextSecondary: formSection3.secondary.sec3Info4Text || '',

      section3MainHeadingPrimary: formSection3.primary.sec3MainHeading || '',
      section3Info1TitlePrimary: formSection3.primary.sec3Info1Title || '',
      section3Info1TextPrimary: formSection3.primary.sec3Info1Text || '',
      section3Info2TitlePrimary: formSection3.primary.sec3Info2Title || '',
      section3Info2TextPrimary: formSection3.primary.sec3Info2Text || '',
      section3Info3TitlePrimary: formSection3.primary.sec3Info3Title || '',
      section3Info3TextPrimary: formSection3.primary.sec3Info3Text || '',
      section3Info4TitlePrimary: formSection3.primary.sec3Info4Title || '',
      section3Info4TextPrimary: formSection3.primary.sec3Info4Text || '',

      // images
      sectionHeaderImageSecondaryDesktop: fpImages.sectionHeaderImageSecondaryDesktop || null,
      sectionHeaderImagePrimaryDesktop: fpImages.sectionHeaderImagePrimaryDesktop || null,
      sectionHeaderImageSecondaryTablet: fpImages.sectionHeaderImageSecondaryTablet || null,
      sectionHeaderImagePrimaryTablet: fpImages.sectionHeaderImagePrimaryTablet || null,
      sectionHeaderImageSecondaryMobile: fpImages.sectionHeaderImageSecondaryMobile || null,
      sectionHeaderImagePrimaryMobile: fpImages.sectionHeaderImagePrimaryMobile || null,

      section1ImageSecondary: fpImages.section1ImageSecondary || null,
      section2ImageSecondary: fpImages.section2ImageSecondary || null,

      section1ImagePrimary: fpImages.section1ImagePrimary || null,
      section2ImagePrimary: fpImages.section2ImagePrimary || null,

      countryCode: user?.countryCodes?.[0] || '',
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createFpDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateFpDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getFpDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialData) return;
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.fpHide || false,
    }));
    setFormSectionHeader((prevState) => ({
      ...prevState,
      secondary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Secondary || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Secondary || '',
      },
      primary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Primary || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Primary || '',
      },
    }));
    setFormSection1((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section1Hide || false,
      secondary: {
        sec1MainHeadingPart1: initialData[0]?.section1MainHeadingPart1Secondary || '',
        sec1MainHeadingPart2: initialData[0]?.section1MainHeadingPart2Secondary || '',
        sec1MainHeadingPart3: initialData[0]?.section1MainHeadingPart3Secondary || '',
        sec1Advantage1Title: initialData[0]?.section1Advantage1TitleSecondary || '',
        sec1Advantage1Text: initialData[0]?.section1Advantage1TextSecondary || '',
        sec1Advantage2Title: initialData[0]?.section1Advantage2TitleSecondary || '',
        sec1Advantage2Text: initialData[0]?.section1Advantage2TextSecondary || '',
        sec1Advantage3Title: initialData[0]?.section1Advantage3TitleSecondary || '',
        sec1Advantage3Text: initialData[0]?.section1Advantage3TextSecondary || '',
      },
      primary: {
        sec1MainHeadingPart1: initialData[0]?.section1MainHeadingPart1Primary || '',
        sec1MainHeadingPart2: initialData[0]?.section1MainHeadingPart2Primary || '',
        sec1MainHeadingPart3: initialData[0]?.section1MainHeadingPart3Primary || '',
        sec1Advantage1Title: initialData[0]?.section1Advantage1TitlePrimary || '',
        sec1Advantage1Text: initialData[0]?.section1Advantage1TextPrimary || '',
        sec1Advantage2Title: initialData[0]?.section1Advantage2TitlePrimary || '',
        sec1Advantage2Text: initialData[0]?.section1Advantage2TextPrimary || '',
        sec1Advantage3Title: initialData[0]?.section1Advantage3TitlePrimary || '',
        sec1Advantage3Text: initialData[0]?.section1Advantage3TextPrimary || '',
      },
    }));
    setFormSection2((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section2Hide || false,
      secondary: {
        sec2MainHeadingPart1: initialData[0]?.section2MainHeadingPart1Secondary || '',
        sec2MainHeadingPart2: initialData[0]?.section2MainHeadingPart2Secondary || '',
        sec2MainHeadingPart3: initialData[0]?.section2MainHeadingPart3Secondary || '',
        sec2Step1Title: initialData[0]?.section2Step1TitleSecondary || '',
        sec2Step1Text: initialData[0]?.section2Step1TextSecondary || '',
        sec2Step2Title: initialData[0]?.section2Step2TitleSecondary || '',
        sec2Step2Text: initialData[0]?.section2Step2TextSecondary || '',
        sec2Step3Title: initialData[0]?.section2Step3TitleSecondary || '',
        sec2Step3Text: initialData[0]?.section2Step3TextSecondary || '',
        sec2ButtonText: initialData[0]?.section2ButtonTextSecondary || '',
        sec2LogoText: initialData[0]?.section2LogoTextSecondary || '',
      },
      primary: {
        sec2MainHeadingPart1: initialData[0]?.section2MainHeadingPart1Primary || '',
        sec2MainHeadingPart2: initialData[0]?.section2MainHeadingPart2Primary || '',
        sec2MainHeadingPart3: initialData[0]?.section2MainHeadingPart3Primary || '',
        sec2Step1Title: initialData[0]?.section2Step1TitlePrimary || '',
        sec2Step1Text: initialData[0]?.section2Step1TextPrimary || '',
        sec2Step2Title: initialData[0]?.section2Step2TitlePrimary || '',
        sec2Step2Text: initialData[0]?.section2Step2TextPrimary || '',
        sec2Step3Title: initialData[0]?.section2Step3TitlePrimary || '',
        sec2Step3Text: initialData[0]?.section2Step3TextPrimary || '',
        sec2ButtonText: initialData[0]?.section2ButtonTextPrimary || '',
        sec2LogoText: initialData[0]?.section2LogoTextPrimary || '',
      },
    }));
    setFormSection3((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section3Hide || false,
      secondary: {
        sec3MainHeading: initialData[0]?.section3MainHeadingSecondary || '',
        sec3Info1Title: initialData[0]?.section3Info1TitleSecondary || '',
        sec3Info1Text: initialData[0]?.section3Info1TextSecondary || '',
        sec3Info2Title: initialData[0]?.section3Info2TitleSecondary || '',
        sec3Info2Text: initialData[0]?.section3Info2TextSecondary || '',
        sec3Info3Title: initialData[0]?.section3Info3TitleSecondary || '',
        sec3Info3Text: initialData[0]?.section3Info3TextSecondary || '',
        sec3Info4Title: initialData[0]?.section3Info4TitleSecondary || '',
        sec3Info4Text: initialData[0]?.section3Info4TextSecondary || '',
      },
      primary: {
        sec3MainHeading: initialData[0]?.section3MainHeadingPrimary || '',
        sec3Info1Title: initialData[0]?.section3Info1TitlePrimary || '',
        sec3Info1Text: initialData[0]?.section3Info1TextPrimary || '',
        sec3Info2Title: initialData[0]?.section3Info2TitlePrimary || '',
        sec3Info2Text: initialData[0]?.section3Info2TextPrimary || '',
        sec3Info3Title: initialData[0]?.section3Info3TitlePrimary || '',
        sec3Info3Text: initialData[0]?.section3Info3TextPrimary || '',
        sec3Info4Title: initialData[0]?.section3Info4TitlePrimary || '',
        sec3Info4Text: initialData[0]?.section3Info4TextPrimary || '',
      },
    }));

    setFpImages((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?._id || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?._id || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?._id || null,
      section1ImageSecondary: initialData[0]?.section1ImageSecondary?._id || null,
      section2ImageSecondary: initialData[0]?.section2ImageSecondary?._id || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?._id || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?._id || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?._id || null,
      section1ImagePrimary: initialData[0]?.section1ImagePrimary?._id || null,
      section2ImagePrimary: initialData[0]?.section2ImagePrimary?._id || null,
    }));
    setFpImagesPreview((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?.url || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?.url || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?.url || null,
      section1ImageSecondary: initialData[0]?.section1ImageSecondary?.url || null,
      section2ImageSecondary: initialData[0]?.section2ImageSecondary?.url || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?.url || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?.url || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?.url || null,
      section1ImagePrimary: initialData[0]?.section1ImagePrimary?.url || null,
      section2ImagePrimary: initialData[0]?.section2ImagePrimary?.url || null,
    }));
  }, [initialData]);

  useEffect(() => {
    handleFetchData();
  }, []);
  // HANDLE FETCH AND SET INITIAL DATA

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'fp_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  return (
    <div className="cms cms-for-companies">
      <h2 className="page-title">CMS For Partners</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* HEADER (Boost Your Client Base: partner with Fitpass) */}
            <Panel header="HEADER (Boost Your Client Base: partner with Fitpass)" key="1">
              <CmsForPartnersSectionHeader formSectionHeader={formSectionHeader} handleInputChangeFormSectionHeader={handleInputChangeFormSectionHeader} fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} formSectionHeaderErrors={formSectionHeaderErrors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* HEADER (Boost Your Client Base: partner with Fitpass) */}

            {/* SECTION 1 (Boost Revenue and Membership with Fitpass with 0 investment!) */}
            <Panel header="SECTION 1 (Boost Revenue and Membership with Fitpass with 0 investment!)" key="2">
              <CmsForPartnersSection1 formSection1={formSection1} handleInputChangeFormSection1={handleInputChangeFormSection1} fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} formSection1Errors={formSection1Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 1 (Boost Revenue and Membership with Fitpass with 0 investment!) */}

            {/* SECTION 2 (Not a local Fitpass partner yet? Here is how to join our network for free) */}
            <Panel header="SECTION 2 (Not a local Fitpass partner yet? Here is how to join our network for free)" key="3">
              <CmsForPartnersSection2 formSection2={formSection2} handleInputChangeFormSection2={handleInputChangeFormSection2} fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} formSection2Errors={formSection2Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 2 (Not a local Fitpass partner yet? Here is how to join our network for free) */}

            {/* SECTION 3 (Want more info) */}
            <Panel header="SECTION 3 (Want more info)" key="4">
              <CmsForPartnersSection3 formSection3={formSection3} handleInputChangeFormSection3={handleInputChangeFormSection3} formSection3Errors={formSection3Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 3 (Want more info) */}
          </Collapse>
          <CheckboxCms inputName="fp_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsForPartners;
