import React, { useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import { PERMISSIONS } from '../../../utils/permissions';

import { useAuth } from '../../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import { notification } from 'antd';
import CheckboxCms from '../../../components/checkbox-cms';

import CmsClubSection1 from './sections/CmsClubSection1';
import CmsClubHeader from './sections/CmsClubHeader';
import { cmsTranslations } from '../../../components/cmsTranslations';

const CmsClub = () => {
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  let countryCodeExtension = null;
  const getCountryExtensionFromCountryCode = (countryCode) => {
    switch (countryCode) {
      case '66a76a9f198a9e71fd572334':
        countryCodeExtension = 'sr';
        break;
      case '6740868b48686db16c24f818':
        countryCodeExtension = 'cg';
        break;
    }
  };
  const countryExtension = getCountryExtensionFromCountryCode(user?.countryCodes[0]) || null;
  const translations = cmsTranslations[countryCodeExtension];

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // GENERAL SETTINGS HANDLE
  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });

  // IMAGES HANDLE
  const [clImages, setClImages] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',

    section1ImageSecondary: '',

    section1ImagePrimary: '',
  });
  const [clImagesPreview, setClImagesPreview] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',

    section1ImageSecondary: '',

    section1ImagePrimary: '',
  });
  const [clImagesErrors, setClImagesErrors] = React.useState({
    sectionHeaderImageSecondaryDesktop: false,
    sectionHeaderImagePrimaryDesktop: false,
    sectionHeaderImageSecondaryTablet: false,
    sectionHeaderImagePrimaryTablet: false,
    sectionHeaderImageSecondaryMobile: false,
    sectionHeaderImagePrimaryMobile: false,

    section1ImageSecondary: false,

    section1ImagePrimary: false,
  });
  // IMAGES HANDLE

  // HEADER HANDLE
  const [formSectionHeader, setFormSectionHeader] = React.useState({
    secondary: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
      headText: '',
    },
    primary: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
      headText: '',
    },
  });
  const [formSectionHeaderErrors, setFormSectionHeaderErrors] = React.useState({
    secondary: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
      headText: false,
    },
    primary: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
      headText: false,
    },
  });
  const handleInputChangeFormSectionHeader = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    setFormSectionHeader((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSectionHeader = useCallback(() => {}, []);
  // HEADER HANDLE

  // SECTION 1 HANDLE
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    secondary: {
      sec1MainHeading: '',
      sec1ListItem1Title: '',
      sec1ListItem1Text: '',
      sec1ListItem2Title: '',
      sec1ListItem2Text: '',
      sec1ListItem3Title: '',
      sec1ListItem3Text: '',
      sec1ListItem4Title: '',
      sec1ListItem4Text: '',
    },
    primary: {
      sec1MainHeading: '',
      sec1ListItem1Title: '',
      sec1ListItem1Text: '',
      sec1ListItem2Title: '',
      sec1ListItem2Text: '',
      sec1ListItem3Title: '',
      sec1ListItem3Text: '',
      sec1ListItem4Title: '',
      sec1ListItem4Text: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    secondary: {
      sec1MainHeading: false,
      sec1ListItem1Title: false,
      sec1ListItem1Text: false,
      sec1ListItem2Title: false,
      sec1ListItem2Text: false,
      sec1ListItem3Title: false,
      sec1ListItem3Text: false,
      sec1ListItem4Title: false,
      sec1ListItem4Text: false,
    },
    primary: {
      sec1MainHeading: false,
      sec1ListItem1Title: false,
      sec1ListItem1Text: false,
      sec1ListItem2Title: false,
      sec1ListItem2Text: false,
      sec1ListItem3Title: false,
      sec1ListItem3Text: false,
      sec1ListItem4Title: false,
      sec1ListItem4Text: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSectionHeaderErrors = { ...formSectionHeaderErrors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.secondary).forEach((key) => {
      if (!formSection1.secondary[key]) {
        newFormSection1Errors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.secondary[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.primary).forEach((key) => {
      if (!formSection1.primary[key]) {
        newFormSection1Errors.primary[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.primary[key] = false;
      }
    });

    // Validate Section Header (EN)
    Object.keys(formSectionHeader.secondary).forEach((key) => {
      if (!formSectionHeader.secondary[key]) {
        newFormSectionHeaderErrors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.secondary[key] = false;
      }
    });

    // Validate Section Header (CG)
    Object.keys(formSectionHeader.primary).forEach((key) => {
      if (!formSectionHeader.primary[key]) {
        newFormSectionHeaderErrors.primary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.primary[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(clImages).forEach((key) => {
      if (clImages[key] === null || clImages[key] === '') {
        setClImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setClImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSectionHeaderErrors(newFormSectionHeaderErrors);
    console.log('formSection1Errors', formSection1Errors);
    console.log('formSectionHeaderErrors', formSectionHeaderErrors);
    console.log('form section1', formSection1);
    console.log('form section header', formSectionHeader);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      clHide: formGeneralSettings.hide || false,

      section1Hide: formSection1.hide || false,

      // header
      sectionHeaderMainHeadingPart1Secondary: formSectionHeader.secondary.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Secondary: formSectionHeader.secondary.headMainHeadingPart2 || '',
      sectionHeaderTextSecondary: formSectionHeader.secondary.headText || '',

      sectionHeaderMainHeadingPart1Primary: formSectionHeader.primary.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Primary: formSectionHeader.primary.headMainHeadingPart2 || '',
      sectionHeaderTextPrimary: formSectionHeader.primary.headText || '',

      // section 1
      section1MainHeadingSecondary: formSection1.secondary.sec1MainHeading || '',
      sec1ListItem1TitleSecondary: formSection1.secondary.sec1ListItem1Title || '',
      sec1ListItem1TextSecondary: formSection1.secondary.sec1ListItem1Text || '',
      sec1ListItem2TitleSecondary: formSection1.secondary.sec1ListItem2Title || '',
      sec1ListItem2TextSecondary: formSection1.secondary.sec1ListItem2Text || '',
      sec1ListItem3TitleSecondary: formSection1.secondary.sec1ListItem3Title || '',
      sec1ListItem3TextSecondary: formSection1.secondary.sec1ListItem3Text || '',
      sec1ListItem4TitleSecondary: formSection1.secondary.sec1ListItem4Title || '',
      sec1ListItem4TextSecondary: formSection1.secondary.sec1ListItem4Text || '',

      section1MainHeadingPrimary: formSection1.primary.sec1MainHeading || '',
      sec1ListItem1TitlePrimary: formSection1.primary.sec1ListItem1Title || '',
      sec1ListItem1TextPrimary: formSection1.primary.sec1ListItem1Text || '',
      sec1ListItem2TitlePrimary: formSection1.primary.sec1ListItem2Title || '',
      sec1ListItem2TextPrimary: formSection1.primary.sec1ListItem2Text || '',
      sec1ListItem3TitlePrimary: formSection1.primary.sec1ListItem3Title || '',
      sec1ListItem3TextPrimary: formSection1.primary.sec1ListItem3Text || '',
      sec1ListItem4TitlePrimary: formSection1.primary.sec1ListItem4Title || '',
      sec1ListItem4TextPrimary: formSection1.primary.sec1ListItem4Text || '',

      // images
      sectionHeaderImageSecondaryDesktop: clImages.sectionHeaderImageSecondaryDesktop || null,
      sectionHeaderImagePrimaryDesktop: clImages.sectionHeaderImagePrimaryDesktop || null,
      sectionHeaderImageSecondaryTablet: clImages.sectionHeaderImageSecondaryTablet || null,
      sectionHeaderImagePrimaryTablet: clImages.sectionHeaderImagePrimaryTablet || null,
      sectionHeaderImageSecondaryMobile: clImages.sectionHeaderImageSecondaryMobile || null,
      sectionHeaderImagePrimaryMobile: clImages.sectionHeaderImagePrimaryMobile || null,

      section1ImageSecondary: clImages.section1ImageSecondary || null,

      section1ImagePrimary: clImages.section1ImagePrimary || null,
      countryCode: user?.countryCodes?.[0] || '',
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createClDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateClDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getClDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialData) return;
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.clHide || false,
    }));
    setFormSectionHeader((prevState) => ({
      ...prevState,
      secondary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Secondary || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Secondary || '',
        headText: initialData[0]?.sectionHeaderTextSecondary || '',
      },
      primary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Primary || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Primary || '',
        headText: initialData[0]?.sectionHeaderTextPrimary || '',
      },
    }));
    setFormSection1((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section1Hide || false,
      secondary: {
        sec1MainHeading: initialData[0]?.section1MainHeadingSecondary || '',
        sec1ListItem1Title: initialData[0]?.sec1ListItem1TitleSecondary || '',
        sec1ListItem1Text: initialData[0]?.sec1ListItem1TextSecondary || '',
        sec1ListItem2Title: initialData[0]?.sec1ListItem2TitleSecondary || '',
        sec1ListItem2Text: initialData[0]?.sec1ListItem2TextSecondary || '',
        sec1ListItem3Title: initialData[0]?.sec1ListItem3TitleSecondary || '',
        sec1ListItem3Text: initialData[0]?.sec1ListItem3TextSecondary || '',
        sec1ListItem4Title: initialData[0]?.sec1ListItem4TitleSecondary || '',
        sec1ListItem4Text: initialData[0]?.sec1ListItem4TextSecondary || '',
      },
      primary: {
        sec1MainHeading: initialData[0]?.section1MainHeadingPrimary || '',
        sec1ListItem1Title: initialData[0]?.sec1ListItem1TitlePrimary || '',
        sec1ListItem1Text: initialData[0]?.sec1ListItem1TextPrimary || '',
        sec1ListItem2Title: initialData[0]?.sec1ListItem2TitlePrimary || '',
        sec1ListItem2Text: initialData[0]?.sec1ListItem2TextPrimary || '',
        sec1ListItem3Title: initialData[0]?.sec1ListItem3TitlePrimary || '',
        sec1ListItem3Text: initialData[0]?.sec1ListItem3TextPrimary || '',
        sec1ListItem4Title: initialData[0]?.sec1ListItem4TitlePrimary || '',
        sec1ListItem4Text: initialData[0]?.sec1ListItem4TextPrimary || '',
      },
    }));

    setClImages((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?._id || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?._id || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?._id || null,
      section1ImageSecondary: initialData[0]?.section1ImageSecondary?._id || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?._id || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?._id || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?._id || null,
      section1ImagePrimary: initialData[0]?.section1ImagePrimary?._id || null,
    }));
    setClImagesPreview((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?.url || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?.url || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?.url || null,
      section1ImageSecondary: initialData[0]?.section1ImageSecondary?.url || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?.url || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?.url || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?.url || null,
      section1ImagePrimary: initialData[0]?.section1ImagePrimary?.url || null,
    }));
  }, [initialData]);

  useEffect(() => {
    handleFetchData();
  }, []);
  // HANDLE FETCH AND SET INITIAL DATA

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'cl_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  console.log('formSectionHeader', formSectionHeader);
  console.log('formSection1', formSection1);
  console.log('clImages', clImages);

  return (
    <div className="cms cms-for-companies">
      <h2 className="page-title">CMS Fitpass Club</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* HEADER */}
            <Panel header="HEADER Club" key="1">
              <CmsClubHeader formSectionHeader={formSectionHeader} handleInputChangeFormSectionHeader={handleInputChangeFormSectionHeader} clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} formSectionHeaderErrors={formSectionHeaderErrors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* HEADER */}

            {/* SECTION 1 */}
            <Panel header="SECTION 1" key="2">
              <CmsClubSection1 handleInputChangeFormSection1={handleInputChangeFormSection1} formSection1={formSection1} setClImages={setClImages} clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} formSection1Errors={formSection1Errors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* SECTION 1 */}
          </Collapse>
          <CheckboxCms inputName="cl_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsClub;
