import React, { memo, useState } from 'react';
import { Tabs } from 'antd';
import PrimaryLangSlideData from './tabs/primary/PrimaryLangSlideData';
import SecondaryLangSlideData from './tabs/secondary/SecondaryLangSlideData';
import CheckboxCms from '../../../components/checkbox-cms';
import { useTranslation } from 'react-i18next';

const SlideModal = ({ translations, countryCodeExtension, handleInputChangeSlider, form, images, imagesPreview, setImages, imagesErrors, formErrors, handleSaveSlider, handleCloseSliderModal, isEditing }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const [activeTabKey, setActiveTabKey] = useState('1');

  const onChangeTab = (key) => {
    setActiveTabKey(key);
  };

  const sectionProps = {
    handleInputChangeSlider,
    form,
    images,
    imagesPreview,
    setImages,
    formErrors,
    imagesErrors,
    translations,
    activeTabKey,
  };

  return (
    <div className="cms cms-image-slider-modal">
      <div className="cms-slider-inner">
        <div className="cms" style={{ position: 'relative' }}>
          <img src="/assets/icons/times.svg" alt="close slider icon" onClick={handleCloseSliderModal} style={{ position: 'absolute', right: '10px', zIndex: '99999', cursor: 'pointer' }} />
          <Tabs defaultActiveKey="1" onChange={onChangeTab}>
            <TabPane key="1" tab="EN">
              <SecondaryLangSlideData {...sectionProps} />
            </TabPane>
            <TabPane key="2" tab={countryCodeExtension?.toUpperCase()}>
              <PrimaryLangSlideData {...sectionProps} />
            </TabPane>
          </Tabs>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <CheckboxCms inputName="hideSlide" isChecked={form.hideSlide} handleChange={handleInputChangeSlider} label={activeTabKey === '2' ? translations?.hideSlideOnPage : 'Hide this slide on page'} />
        </div>
        <button className="btn btn-primary pl-2 pr-2" style={{ marginBottom: '20px' }} onClick={() => handleSaveSlider(isEditing)}>
          Save changes
        </button>
      </div>
    </div>
  );
};
export default SlideModal;
