import React, { memo, useState } from 'react';
import { Tabs } from 'antd';
import SectionHeaderSecondary from '../tabs/secondary/SectionHeaderSecondary';
import SectionHeaderPrimary from '../tabs/primary/SectionHeaderPrimary';

const CmsAboutUsHeader = ({ countryCodeExtension, translations, handleInputChangeFormSectionHeader, formSectionHeader, auImages, auImagesPreview, setAuImages, auImagesErrors, formSectionHeaderErrors }) => {
  const { TabPane } = Tabs;

  const [activeTabKey, setActiveTabKey] = useState('1');

  const onChangeTabSectionHeader = (key) => {
    setActiveTabKey(key);
  };

  const sectionProps = {
    handleInputChangeFormSectionHeader,
    formSectionHeader,
    auImages,
    auImagesPreview,
    setAuImages,
    onChangeTabSectionHeader,
    auImagesErrors,
    formSectionHeaderErrors,
    translations,
    activeTabKey,
  };
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSectionHeader}>
        <TabPane key="1" tab="EN">
          <SectionHeaderSecondary {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab={countryCodeExtension?.toUpperCase()}>
          <SectionHeaderPrimary {...sectionProps} />
        </TabPane>
      </Tabs>
    </>
  );
};
export default memo(CmsAboutUsHeader);
