import React, { useState, useEffect } from 'react';
import Loading from '../../../../../components/loading/Loading';
import SidebarModal from '../../../../../components/sidebarModal/SidebarModal';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export const UpdateFormAllSettings = ({ updateProps, loading, updateModalFormRef, updateFormFields, editBtnLoader, handleOnFinish, setToggleUpdate, allDisciplines, setSelectedDisciplinesIds, selectedDisciplinesIds, setSelectedVenueAttributes, allAttributes, venueDisciplines, setVenueDisciplines, selectedVenueAttributes }) => {
  const { t } = useTranslation();
  const handleChange = (value) => {
    const removedIds = venueDisciplines.filter((id) => !value.includes(id));
    const removedDiscipline = allDisciplines.find((discipline) => removedIds[removedIds.length - 1] === discipline._id)?.id;
    const allAttributesToRemove = allAttributes
      .filter((item) => item.isGlobal !== true)
      .filter((attribute) => attribute.disciplineId === removedDiscipline)
      ?.map((attribute) => attribute._id);
    setSelectedVenueAttributes((prev) => prev.filter((id) => !allAttributesToRemove.includes(id)));
    setVenueDisciplines(value);
  };

  const [isStudent, setIsStudent] = useState(false);
  const onChange = (value) => {
    setIsStudent(value.target.checked);
  };

  const [isFavoriteVenue, setIsFavoriteVenue] = useState(false);
  const onChangeFav = (value) => {
    setIsFavoriteVenue(value.target.checked);
  };

  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title={t('editVenue')}>
      {!loading ? (
        <Form
          name="update"
          ref={updateModalFormRef}
          wrapperCol={{ span: 24 }}
          // initialValues={{
          //   disciplines: selectedDisciplinesIds, // Set initial value for disciplines
          // }}
          onFinish={(data) => handleOnFinish({ name: 'update', data: data })}
          fields={updateFormFields}
        >
          <Form.Item
            name="name"
            label={t('venueName')}
            rules={[
              {
                required: true,
                message: t('pleaseInputVenueName'),
              },
            ]}
          >
            <TextArea placeholder={t('venueName')} style={{ resize: 'none' }} rows={4} />
          </Form.Item>

          <Form.Item
            name="lat"
            label={`${t('lat')}:`}
            rules={[
              {
                required: true,
                message: t('pleaseInputVenueLatitude'),
              },
            ]}
          >
            <Input placeholder={t('lat')} type="number" />
          </Form.Item>

          <Form.Item
            name="lng"
            label={`${t('long')}:`}
            rules={[
              {
                required: true,
                message: t('pleaseInputVenueLongitude'),
              },
            ]}
          >
            <Input placeholder={t('long')} type="number" />
          </Form.Item>

          <Form.Item name="phones" label={t('phones')}>
            <Select mode="tags"></Select>
          </Form.Item>

          <Form.Item label={t('workHours')}>
            <Form.Item name={['workHours', 'monday']} label={t('monday')} rules={[{ required: true, message: t('pleaseInputMondayHours') }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'tuesday']} label={t('tuesday')} rules={[{ required: true, message: t('pleaseInputTuesdayHours') }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'wednesday']} label={t('wednesday')} rules={[{ required: true, message: t('pleaseInputWednesdayHours') }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'thursday']} label={t('thursday')} rules={[{ required: true, message: t('pleaseInputThursdayHours') }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'friday']} label={t('friday')} rules={[{ required: true, message: t('pleaseInputFridayHours') }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'saturday']} label={t('saturday')} rules={[{ required: true, message: t('pleaseInpuySaturdayHours') }]}>
              <Input placeholder="11:00-21:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'sunday']} label={t('sunday')} rules={[{ required: true, message: t('pleaseInpuySundayHours') }]}>
              <Input placeholder="11:00-19:00" />
            </Form.Item>
          </Form.Item>

          <Form.Item name="disciplines" label={t('disciplines')}>
            <Select
              mode="multiple"
              allowClear
              placeholder={t('disciplines')}
              style={{ width: '100%' }}
              defaultValue={updateProps?.data?.disciplines?.map((discipline) => discipline._id) || []}
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) => {
                {
                  const isMatch = (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
                  return isMatch;
                }
              }}
              onChange={handleChange}
            >
              {allDisciplines.map((item, index) => (
                <Option key={index} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="attributes" label={t('attributes')}>
            <Select
              mode="multiple"
              allowClear
              placeholder={t('attributes')}
              style={{ width: '100%' }}
              onChange={(value) => setSelectedVenueAttributes(value)}
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) => {
                {
                  const isMatch = (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
                  return isMatch;
                }
              }}
            >
              {allAttributes &&
                allAttributes.map((item, index) => (
                  <Option key={index} value={item._id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item name={['seo', 'title']} label={t('seoTitle')}>
            <TextArea style={{ resize: 'none' }} rows={4} />
          </Form.Item>
          <Form.Item name={['seoEn', 'title']} label={`${t('seoTitle')} En:`}>
            <TextArea style={{ resize: 'none' }} rows={4} />
          </Form.Item>

          <Form.Item name={['seo', 'description']} label={t('seoDescription')}>
            <TextArea rows={8} style={{ resize: 'none' }} />
          </Form.Item>
          <Form.Item name={['seoEn', 'description']} label={`${t('seoDescription')} En:`}>
            <TextArea rows={8} style={{ resize: 'none' }} />
          </Form.Item>

          <Form.Item name="is_student" valuePropName="checked" className="initial-height">
            <Checkbox checked={isStudent} defaultChecked={updateProps?.data?.is_student} onChange={onChange}>
              {t('isStudent')}
            </Checkbox>
          </Form.Item>

          <Form.Item name="isFavourite" valuePropName="checked" className="initial-height">
            <Checkbox checked={isFavoriteVenue} defaultChecked={updateProps?.data?.isFavourite} onChange={onChange}>
              {t('isFavorite')}
            </Checkbox>
          </Form.Item>

          <Form.Item className="ant-col-btn action-btns" wrapperCol={{ offset: 8 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                  <span>{t('cancel')}</span>
                </Button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                    <span>{t('editVenue')}</span>
                  </Button>
                ) : (
                  <Button type="button" className="btn btn-primary-outline btn-block">
                    <span>{t('editing')}...</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
