import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section3Secondary = ({ handleInputChangeFormSection3, formSection3, fcImages, fcImagesErrors, fcImagesPreview, setFcImages, formSection3Errors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*secondary_sec3MainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_sec3MainHeading">
            <b>Main heading:</b>
          </label>
          <input id="secondary_sec3MainHeading" type="text" placeholder="Main heading" name="secondary_sec3MainHeading" value={formSection3.secondary.sec3MainHeading} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.secondary.sec3MainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_sec3MainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Card 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Card 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Icon</span>
              <span className="ml-6">
                <TooltipDash text="Icon aspect ratio 1/1 (120x120)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section3Image1Secondary" />
            </div>
          </div>
          {/*secondary_sec3Card1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec3Card1Title">
              <b>Card 1 Title:</b>
            </label>
            <textarea id="secondary_sec3Card1Title" type="text" placeholder="Card 1 Title" name="secondary_sec3Card1Title" value={formSection3.secondary.sec3Card1Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.secondary.sec3Card1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec3Card1Title*/}
          {/*secondary_sec3Card1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec3Card1Text">
              <b>Card 1 Text:</b>
            </label>
            <textarea id="secondary_sec3Card1Text" type="text" placeholder="Card 1 Text" name="secondary_sec3Card1Text" value={formSection3.secondary.sec3Card1Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.secondary.sec3Card1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec3Card1Text*/}
        </div>
        {/*Card 1*/}
        {/*Card 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Card 2:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Icon</span>
              <span className="ml-6">
                <TooltipDash text="Icon aspect ratio 1/1 (120x120)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section3Image2Secondary" />
            </div>
          </div>
          {/*secondary_sec3Card2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec3Card2Title">
              <b>Card 2 Title:</b>
            </label>
            <textarea id="secondary_sec3Card2Title" type="text" placeholder="Card 2 Title" name="secondary_sec3Card2Title" value={formSection3.secondary.sec3Card2Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.secondary.sec3Card2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec3Card2Title*/}
          {/*secondary_sec3Card2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec3Card2Text">
              <b>Card 2 Text:</b>
            </label>
            <textarea id="secondary_sec3Card2Text" type="text" placeholder="Card 2 Text" name="secondary_sec3Card2Text" value={formSection3.secondary.sec3Card2Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.secondary.sec3Card2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec3Card2Text*/}
        </div>
        {/*Card 2*/}
        {/*Card 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Card 3:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Icon</span>
              <span className="ml-6">
                <TooltipDash text="Icon aspect ratio 1/1 (120x120)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section3Image3Secondary" />
            </div>
          </div>
          {/*secondary_sec3Card3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec3Card3Title">
              <b>Card 3 Title:</b>
            </label>
            <textarea id="secondary_sec3Card3Title" type="text" placeholder="Card 3 Title" name="secondary_sec3Card3Title" value={formSection3.secondary.sec3Card3Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.secondary.sec3Card3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec3Card3Title*/}
          {/*secondary_sec3Card3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec3Card3Text">
              <b>Card 3 Text:</b>
            </label>
            <textarea id="secondary_sec3Card3Text" type="text" placeholder="Card 3 Text" name="secondary_sec3Card3Text" value={formSection3.secondary.sec3Card3Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.secondary.sec3Card3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec3Card3Text*/}
        </div>
        {/*Card 3*/}
        {/*Card 4*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Card 4:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Icon</span>
              <span className="ml-6">
                <TooltipDash text="Icon aspect ratio 1/1 (120x120)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section3Image4Secondary" />
            </div>
          </div>
          {/*secondary_sec3Card4Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec3Card4Title">
              <b>Card 4 Title:</b>
            </label>
            <textarea id="secondary_sec3Card4Title" type="text" placeholder="Card 4 Title" name="secondary_sec3Card4Title" value={formSection3.secondary.sec3Card4Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.secondary.sec3Card4Title ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec3Card4Title*/}
          {/*secondary_sec3Card4Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec3Card4Text">
              <b>Card 4 Text:</b>
            </label>
            <textarea id="secondary_sec3Card4Text" type="text" placeholder="Card 4 Text" name="secondary_sec3Card4Text" value={formSection3.secondary.sec3Card4Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.secondary.sec3Card4Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec3Card4Text*/}
        </div>
        {/*Card 4*/}
      </div>
    </>
  );
};
export default Section3Secondary;
