import TooltipDash from '../helpers/TooltipDash';

const CheckboxCms = ({ isChecked, handleChange, label, tooltipText, inputName }) => {
  return (
    <div className="checkbox-flex">
      <input type="checkbox" id={inputName} checked={isChecked} name={inputName} onChange={handleChange} />
      <label for="section1_hide"> {label}</label>
      {tooltipText && <TooltipDash text={tooltipText} />}
    </div>
  );
};
export default CheckboxCms;
