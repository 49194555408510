import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1Primary = ({ translations, activeTabKey, handleInputChangeFormSection1, formSection1, fpImages, fpImagesErrors, fpImagesPreview, setFpImages, formSection1Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>{translations?.image}</span>
            <span className="ml-6">
              <TooltipDash text={`${translations?.imageProportions} 1/1.82 (638x1165)px`} acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload translations={translations} activeTabKey={activeTabKey} fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} toStoreImg="section1ImagePrimary" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_sec1MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec1MainHeadingPart1">
            <b>{translations?.mainHeadingLine} 1:</b>
          </label>
          <input id="primary_sec1MainHeadingPart1" type="text" placeholder={`${translations?.mainHeadingLine} 1`} name="primary_sec1MainHeadingPart1" value={formSection1.primary.sec1MainHeadingPart1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec1MainHeadingPart1*/}
        {/*primary_sec1MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec1MainHeadingPart2">
            <b>{translations?.mainHeadingLine} 2:</b>
          </label>
          <input id="primary_sec1MainHeadingPart2" type="text" placeholder={`${translations?.mainHeadingLine} 2`} name="primary_sec1MainHeadingPart2" value={formSection1.primary.sec1MainHeadingPart2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec1MainHeadingPart2*/}
        {/*primary_sec1MainHeadingPart3*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec1MainHeadingPart3">
            <b>{translations?.mainHeadingLine} 3:</b>
          </label>
          <input id="primary_sec1MainHeadingPart3" type="text" placeholder={`${translations?.mainHeadingLine} 3`} name="primary_sec1MainHeadingPart3" value={formSection1.primary.sec1MainHeadingPart3} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1MainHeadingPart3 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec1MainHeadingPart3*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Advantage 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.advantage} 1:</p>
          {/*primary_sec1Advantage1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Advantage1Title">
              <b>
                {translations?.advantage} 1 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec1Advantage1Title" type="text" placeholder={`${translations?.advantage} 1 ${translations?.title}`} name="primary_sec1Advantage1Title" value={formSection1.primary.sec1Advantage1Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Advantage1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Advantage1Title*/}
          {/*primary_sec1Advantage1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Advantage1Text">
              <b>
                {translations?.advantage} 1 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec1Advantage1Text" type="text" placeholder={`${translations?.advantage} 1 ${translations?.text}`} name="primary_sec1Advantage1Text" value={formSection1.primary.sec1Advantage1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Advantage1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec1Advantage1Text*/}
        </div>
        {/*Advantage 1*/}

        {/*Advantage 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.advantage} 2:</p>
          {/*primary_sec1Advantage2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Advantage2Title">
              <b>
                {translations?.advantage} 2 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec1Advantage2Title" type="text" placeholder={`${translations?.advantage} 2 ${translations?.title}`} name="primary_sec1Advantage2Title" value={formSection1.primary.sec1Advantage2Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Advantage2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Advantage2Title*/}
          {/*primary_sec1Advantage2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Advantage2Text">
              <b>
                {translations?.advantage} 2 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec1Advantage2Text" type="text" placeholder={`${translations?.advantage} 2 ${translations?.text}`} name="primary_sec1Advantage2Text" value={formSection1.primary.sec1Advantage2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Advantage2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec1Advantage2Text*/}
        </div>
        {/*Advantage 2*/}

        {/*Advantage 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.advantage} 3:</p>
          {/*primary_sec1Advantage3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Advantage3Title">
              <b>
                {translations?.advantage} 3 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec1Advantage3Title" type="text" placeholder={`${translations?.advantage} 3 ${translations?.title}`} name="primary_sec1Advantage3Title" value={formSection1.primary.sec1Advantage3Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Advantage3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec1Advantage3Title*/}
          {/*primary_sec1Advantage3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec1Advantage3Text">
              <b>
                {translations?.advantage} 3 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec1Advantage3Text" type="text" placeholder={`${translations?.advantage} 3 ${translations?.text}`} name="primary_sec1Advantage3Text" value={formSection1.primary.sec1Advantage3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.sec1Advantage3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec1Advantage3Text*/}
        </div>
        {/*Advantage 3*/}
      </div>
    </>
  );
};
export default Section1Primary;
