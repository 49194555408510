import React from 'react';
import { stateUS } from '../../../../../helpers/state';
import Loading from '../../../../../components/loading/Loading';
import SidebarModal from '../../../../../components/sidebarModal/SidebarModal';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

export const UpdateFormAllSettings = ({ updateProps, loadingEmployee, updateModalFormRef, updateFormFields, onChange, toggleNewPassword, roles, editBtnLoader, handleOnFinish, setToggleUpdate, countryCodeOptions }) => {
  const { t } = useTranslation();
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title={t('editUSer')}>
      {!loadingEmployee ? (
        <Form name="update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', data: data })} fields={updateFormFields}>
          <Form.Item
            name="firstName"
            label={`${t('firstName')}:`}
            rules={[
              {
                required: true,
                message: t('pleaseInputName'),
              },
            ]}
          >
            <Input placeholder={`${t('firstName')}`} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={`${t('lastName')}:`}
            rules={[
              {
                required: true,
                message: t('pleaseInputLastName'),
              },
            ]}
          >
            <Input placeholder={`${t('lastName')}`} />
          </Form.Item>

          <Form.Item name="email" label={`${t('email')}:`} rules={[{ required: true, message: t('pleaseInputEmail') }]}>
            <Input placeholder={`${t('email')}`} value="" />
          </Form.Item>
          <Form.Item name="countryCode" label={`${t('selectCountryCode')}`} rules={[{ required: true, message: t('pleaseInputCountryCode') }]}>
            <Select>
              <Option key={''}>{t('selectCountryCode')}</Option>

              {countryCodeOptions?.map((item) => {
                return (
                  <Option key={item._id} value={item._id}>
                    {item.countryName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {/* <Form.Item name='state' label={`${t('selectState')}:`} rules={[{ required: false, message: t('pleaseInputState') }]}>
            <Select>
              <Option value=''>{`${t('selectState')}`}</Option>

              {stateUS.map((item) => {
                return (
                  <Option key={item.name} value={item.abbreviation}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}

          {/* <Form.Item name="address" label={`${t('address')}:`} rules={[{ required: false, message: t('pleaseInputAddress') }]}>
            <Input placeholder={`${t('address')}`} />
          </Form.Item> */}

          {/* <Form.Item name="city" label={`${t('city')}:`} rules={[{ required: false, message: t('pleaseInputCity') }]}>
            <Input placeholder={`${t('city')}:`} />
          </Form.Item> */}

          {/* <Form.Item
            name="zip_code"
            label={`${t('zipCode')}:`}
            rules={[
              {
                required: false,
                message: t('pleaseInputZip'),
              },
            ]}
          >
            <Input placeholder={`${t('zipCode')}`} />
          </Form.Item> */}

          <Form.Item name="phone" label={`${t('phone')}:`} rules={[{ required: false, message: t('pleaseInputPhone') }]}>
            <Input placeholder={`${t('phone')}`} />
          </Form.Item>

          <Form.Item className="initial-height">
            <Checkbox onChange={onChange} checked={toggleNewPassword}>
              {t('createNewPassword')}
            </Checkbox>
          </Form.Item>

          {toggleNewPassword === true && (
            <Form.Item
              name="newPassword"
              label={`${t('password')}:`}
              initialValue={''}
              rules={[
                {
                  required: true,
                  message: t('plaseInputPassword'),
                },
              ]}
            >
              <Input.Password placeholder={`${t('password')}`} />
            </Form.Item>
          )}

          <Form.Item name="role" label={`${t('selectRoles')}:`} rules={[{ required: true, message: t('pleaseInputYourRole') }]}>
            <Select>
              <Option value="">{`${t('selectRoles')}`}</Option>

              {roles
                ?.filter((item) => item?.name !== 'Super Admin')
                .map((item) => {
                  return (
                    <Option key={item.name} value={item._id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item name="status" label={`${t('status')}:`} rules={[{ required: true, message: t('requiredField') }]}>
            {/* <Input placeholder={`${t('status')}`} /> */}
            <Select>
              {/* <Option value="">{`${t('status')}`}</Option> */}

              {['ACTIVE', 'INACTIVE'].map((item) => {
                return (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="updateUserId" style={{ display: 'none' }}>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item className="ant-col-btn">
            <Row gutter={16} className="row-direction">
              <Col span={12}>
                <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                  <span>{t('cancel')}</span>
                </Button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                    <span>{t('editUSer')}</span>
                  </Button>
                ) : (
                  <Button type="button" className="btn btn-primary-outline btn-block">
                    <span>{t('editing')}...</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
