import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1Primary = ({ translations, activeTabKey, handleInputChangeFormSection1, formSection1, enImages, enImagesPreview, setEnImages, formSection1Errors, enImagesErrors }) => {
  return (
    <div className="cms-form-flex">
      {/*Card 1:*/}
      <div className="cms-form-flex-col">
        <p className="card-top">{translations?.card} 1:</p>
        <div className="cms-form-head-inner">
          <div className="images-card-header">
            <span>{translations?.icon}</span>
            <span className="ml-6">
              <TooltipDash text={`${translations?.iconProportions} 1/1 (150x150)px`} acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload translations={translations} activeTabKey={activeTabKey} enImages={enImages} enImagesPreview={enImagesPreview} setEnImages={setEnImages} enImagesErrors={enImagesErrors} toStoreImg="section1Icon1Primary" cg={true} />
          </div>
        </div>
        {/*primary_title1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_title1">
            <b>{translations?.title}:</b>
          </label>
          <input id="primary_title1" type="text" placeholder={`${translations?.title}`} name="primary_title1" value={formSection1.primary.title1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.title1 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_title1*/}
        {/*primary_subTitle1Part1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_subTitle1Part1">
            <b>{translations?.subTitleLine} 1:</b>
          </label>
          <input id="primary_subTitle1Part1" type="text" placeholder={`${translations?.subTitleLine} 1`} name="primary_subTitle1Part1" value={formSection1.primary.subTitle1Part1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.subTitle1Part1 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_subTitle1Part1*/}
        {/*primary_subTitle1Part2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_subTitle1Part2">
            <b>{translations?.subTitleLine} 2:</b>
          </label>
          <input id="primary_subTitle1Part2" type="text" placeholder={`${translations?.subTitleLine} 2`} name="primary_subTitle1Part2" value={formSection1.primary.subTitle1Part2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.subTitle1Part2 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_subTitle1Part2*/}
        {/*primary_button1Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_button1Text">
            <b>{translations?.btnText}:</b>
          </label>
          <input id="primary_button1Text" type="text" placeholder={`${translations?.btnText}`} name="primary_button1Text" value={formSection1.primary.button1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.button1Text ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_button1Text*/}
        {/*primary_button1Url*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_buttonUrl1">
            <b>{translations?.btnUrl}:</b>
          </label>
          <input id="primary_button1Url" type="text" placeholder={`${translations?.btnUrl}`} name="primary_button1Url" value={formSection1.primary.button1Url} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.button1Url ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_button1Url*/}
      </div>
      {/*Card 1:*/}
      {/*Card 2:*/}
      <div className="cms-form-flex-col">
        <p className="card-top">{translations?.card} 2:</p>
        <div className="cms-form-head-inner">
          <div className="images-card-header">
            <span>{translations?.icon}</span>
            <span className="ml-6">
              <TooltipDash text={`${translations?.iconProportions} 1/1 (150x150)px`} acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload translations={translations} activeTabKey={activeTabKey} enImages={enImages} enImagesPreview={enImagesPreview} setEnImages={setEnImages} toStoreImg="section1Icon2Primary" cg={true} enImagesErrors={enImagesErrors} />
          </div>
        </div>
        {/*primary_title2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_title2">
            <b>{translations?.title}:</b>
          </label>
          <input id="primary_title2" type="text" placeholder={`${translations?.title}`} name="primary_title2" value={formSection1.primary.title2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.title2 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_title2*/}
        {/*primary_subTitle2Part1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_subTitle2Part1">
            <b>{translations?.subTitleLine} 1:</b>
          </label>
          <input id="primary_subTitle2Part1" type="text" placeholder={`${translations?.subTitleLine} 1`} name="primary_subTitle2Part1" value={formSection1.primary.subTitle2Part1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.subTitle2Part1 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_subTitle2Part1*/}
        {/*primary_subTitle2Part2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_subTitle2Part2">
            <b>{translations?.subTitleLine} 2:</b>
          </label>
          <input id="primary_subTitle2Part2" type="text" placeholder={`${translations?.subTitleLine} 2`} name="primary_subTitle2Part2" value={formSection1.primary.subTitle2Part2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.subTitle2Part2 ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_subTitle2Part2*/}
        {/*primary_button2Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_button2Text">
            <b>{translations?.btnText}:</b>
          </label>
          <input id="primary_button2Text" type="text" placeholder={`${translations?.btnText}`} name="primary_button2Text" value={formSection1.primary.button2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.button2Text ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_button2Text*/}
        {/*primary_button2Url*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_button2Url">
            <b>{translations?.btnUrl}:</b>
          </label>
          <input id="primary_button2Url" type="text" placeholder={`${translations?.btnUrl}`} name="primary_button2Url" value={formSection1.primary.button2Url} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.primary.button2Url ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_button2Url*/}
      </div>
      {/*Card 2:*/}
    </div>
  );
};
export default memo(Section1Primary);
