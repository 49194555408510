export const constructInitialFormSection1State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section1Hide || false,
    secondary: {
      ...prevState.Secondary,
      title1: data?.section1Card1TitleSecondary || '',
      title2: data?.section1Card2TitleSecondary || '',
      subTitle1Part1: data?.section1Card1SubtitlePart1Secondary || '',
      subTitle1Part2: data?.section1Card1SubtitlePart2Secondary || '',
      subTitle2Part1: data?.section1Card2SubtitlePart1Secondary || '',
      subTitle2Part2: data?.section1Card2SubtitlePart2Secondary || '',
      button1Text: data?.section1Card1ButtonTextSecondary || '',
      button2Text: data?.section1Card2ButtonTextSecondary || '',
      button1Url: data?.section1Card1ButtonUrlSecondary || '',
      button2Url: data?.section1Card2ButtonUrlSecondary || '',
    },
    primary: {
      ...prevState.Primary,
      title1: data?.section1Card1TitlePrimary || '',
      title2: data?.section1Card2TitlePrimary || '',
      subTitle1Part1: data?.section1Card1SubtitlePart1Primary || '',
      subTitle1Part2: data?.section1Card1SubtitlePart2Primary || '',
      subTitle2Part1: data?.section1Card2SubtitlePart1Primary || '',
      subTitle2Part2: data?.section1Card2SubtitlePart2Primary || '',
      button1Text: data?.section1Card1ButtonTextPrimary || '',
      button2Text: data?.section1Card2ButtonTextPrimary || '',
      button1Url: data?.section1Card1ButtonUrlPrimary || '',
      button2Url: data?.section1Card2ButtonUrlPrimary || '',
    },
  };
};

export const constructInitialFormSection2State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section2Hide || false,
    secondary: {
      ...prevState.Secondary,
      mainHeading: data?.section2MainHeadingSecondary || '',
      list1item1: data?.section2List1Item1Secondary || '',
      list1item2: data?.section2List1Item2Secondary || '',
      list1item3: data?.section2List1Item3Secondary || '',
      list1item4: data?.section2List1Item4Secondary || '',
    },
    primary: {
      ...prevState.Primary,
      mainHeading: data?.section2MainHeadingPrimary || '',
      list1item1: data?.section2List1Item1Primary || '',
      list1item2: data?.section2List1Item2Primary || '',
      list1item3: data?.section2List1Item3Primary || '',
      list1item4: data?.section2List1Item4Primary || '',
    },
  };
};

export const constructInitialEnImagesState = (data, prevState) => {
  return {
    ...prevState,
    section1Icon1Secondary: data?.section1Card1IconSecondary?._id || null,
    section1Icon2Secondary: data?.section1Card2IconSecondary?._id || null,
    section2Image1Secondary: data?.section2Image1Secondary?._id || null,

    section1Icon1Primary: data?.section1Card1IconPrimary?._id || null,
    section1Icon2Primary: data?.section1Card2IconPrimary?._id || null,
    section2Image1Primary: data?.section2Image1Primary?._id || null,
  };
};

export const constructInitialEnImagesPreviewState = (data, prevState) => {
  return {
    ...prevState,
    section1Icon1Secondary: data?.section1Card1IconSecondary?.url || null,
    section1Icon2Secondary: data?.section1Card2IconSecondary?.url || null,
    section2Image1Secondary: data?.section2Image1Secondary?.url || null,

    section1Icon1Primary: data?.section1Card1IconPrimary?.url || null,
    section1Icon2Primary: data?.section1Card2IconPrimary?.url || null,
    section2Image1Primary: data?.section2Image1Primary?.url || null,
  };
};
