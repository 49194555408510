import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1En = ({ handleInputChangeFormSection1, formSection1, auImages, auImagesErrors, auImagesPreview, setAuImages, formSection1Errors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*secondary_sec1MainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="secondary_sec1MainHeading">
            <b>Main heading:</b>
          </label>
          <input id="secondary_sec1MainHeading" type="text" placeholder="Main heading" name="secondary_sec1MainHeading" value={formSection1.secondary.sec1MainHeading} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1MainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*secondary_sec1MainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Row 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Row 1:</p>
          {/* <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Image</span>
              <span className="ml-6">
                <TooltipDash text="Icon aspect ratio 1/1 (150x150)" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="section1Image1En" />
            </div>
          </div> */}
          {/*secondary_sec1Row1VideoUrl*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="secondary_sec1Row1VideoUrl">
              <b>Row 1 Video Url:</b>
            </label>
            <input id="secondary_sec1Row1VideoUrl" type="text" placeholder="Row 1 Video Url" name="secondary_sec1Row1VideoUrl" value={formSection1.secondary.sec1Row1VideoUrl} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1Row1VideoUrl ? 'input-error-important' : ''}`} />
          </div>
          {/*secondary_sec1Row1VideoUrl*/}
          {/*secondary_sec1Row1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="secondary_sec1Row1Text">
              <b>Row 1 Text:</b>
            </label>
            <textarea id="secondary_sec1Row1Text" type="text" placeholder="Benefit 1 Text" name="secondary_sec1Row1Text" value={formSection1.secondary.sec1Row1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1Row1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec1Row1Text*/}
        </div>
        {/*Row 1:*/}
        {/*Row 2:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Row 2:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Row 2 Image</span>
              <span className="ml-6">
                <TooltipDash text="Image aspect ratio 1/1 (240x240)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="section1Image1Secondary" />
            </div>
          </div>
          {/*secondary_sec1Row2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1Row2Text">
              <b>Row 2 Text:</b>
            </label>
            <textarea id="secondary_sec1Row2Text" type="text" placeholder="Row 2 Text" name="secondary_sec1Row2Text" value={formSection1.secondary.sec1Row2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1Row2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec1Row2Text*/}
        </div>
        {/*Row 2:*/}
        {/*Row 3:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Row 3:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Row 3 Image</span>
              <span className="ml-6">
                <TooltipDash text="Image aspect ratio 1/1 (240x240)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} toStoreImg="section1Image2Secondary" />
            </div>
          </div>
          {/*secondary_sec1Row3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="secondary_sec1Row3Text">
              <b>Row 3 Text:</b>
            </label>
            <textarea id="secondary_sec1Row3Text" type="text" placeholder="Row 3 Text" name="secondary_sec1Row3Text" value={formSection1.secondary.sec1Row3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.secondary.sec1Row3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*secondary_sec1Row3Text*/}
        </div>
        {/*Row 3:*/}
      </div>
    </>
  );
};
export default Section1En;
