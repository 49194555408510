import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section3Cg = ({ activeTabKey, translations, formSection3, handleInputChangeFormSection3, setHpImages, hpImages, hpImagesPreview, formSection3Errors, hpImagesErrors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_sec3MainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec3MainHeading">
            <b>{translations?.mainHeading}:</b>
          </label>
          <input id="primary_sec3MainHeading" type="text" placeholder={`${translations?.mainHeading}`} name="primary_sec3MainHeading" value={formSection3.primary.sec3MainHeading} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3MainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec3MainHeading*/}
      </div>
      {/*Shared inputs*/}
      <section>
        <article>
          <div className="cms-form-flex">
            <div className="cms-form-flex-col">
              <p className="card-top">{translations?.card} 1:</p>
              <div className="cms-form-head-inner">
                <div className="images-card-header">
                  <span>{translations?.icon}</span>
                  <span className="ml-6">
                    <TooltipDash text={`${translations?.iconProportions} 1/1 (120x120)px`} acceptFormatText={true} />
                  </span>
                </div>
                <div className="upload-wrapper">
                  <ImageUpload activeTabKey={activeTabKey} translations={translations} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section3Icon1Primary" />
                </div>
              </div>
              {/*primary_sec3Card1Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card1Title">
                  <b>{translations?.title}:</b>
                </label>
                <textarea id="primary_sec3Card1Title" type="text" placeholder={`${translations?.title}`} name="primary_sec3Card1Title" value={formSection3.primary.sec3Card1Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card1Title ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card1Title*/}
              {/*primary_sec3Card1SubTitle*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card1SubTitle">
                  <b>{translations?.text}:</b>
                </label>
                <textarea id="primary_sec3Card1SubTitle" type="text" placeholder={`${translations?.text}`} name="primary_sec3Card1SubTitle" value={formSection3.primary.sec3Card1SubTitle} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card1SubTitle ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card1SubTitle*/}
            </div>
            <div className="cms-form-flex-col">
              <p className="card-top">{translations?.card} 2:</p>
              <div className="cms-form-head-inner">
                <div className="images-card-header">
                  <span>{translations?.icon}</span>
                  <span className="ml-6">
                    <TooltipDash text={`${translations?.iconProportions} 1/1 (120x120)px`} acceptFormatText={true} />
                  </span>
                </div>
                <div className="upload-wrapper">
                  <ImageUpload activeTabKey={activeTabKey} translations={translations} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section3Icon2Primary" />
                </div>
              </div>
              {/*primary_sec3Card2Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card2Title">
                  <b>{translations?.title}:</b>
                </label>
                <textarea id="primary_sec3Card2Title" type="text" placeholder={`${translations?.title}`} name="primary_sec3Card2Title" value={formSection3.primary.sec3Card2Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card2Title ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card2Title*/}
              {/*primary_sec3Card2SubTitle*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card2SubTitle">
                  <b>{translations?.text}:</b>
                </label>
                <textarea id="primary_sec3Card2SubTitle" type="text" placeholder={`${translations?.text}`} name="primary_sec3Card2SubTitle" value={formSection3.primary.sec3Card2SubTitle} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card2SubTitle ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card2SubTitle*/}
            </div>
            <div className="cms-form-flex-col">
              <p className="card-top">{translations?.card} 3:</p>
              <div className="cms-form-head-inner">
                <div className="images-card-header">
                  <span>{translations?.icon}</span>
                  <span className="ml-6">
                    <TooltipDash text={`${translations?.iconProportions} 1/1 (120x120)px`} acceptFormatText={true} />
                  </span>
                </div>
                <div className="upload-wrapper">
                  <ImageUpload activeTabKey={activeTabKey} translations={translations} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section3Icon3Primary" />
                </div>
              </div>
              {/*primary_sec3Card3Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card3Title">
                  <b>{translations?.title}:</b>
                </label>
                <textarea id="primary_sec3Card3Title" type="text" placeholder={`${translations?.title}`} name="primary_sec3Card3Title" value={formSection3.primary.sec3Card3Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card3Title ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card3Title*/}
              {/*primary_sec3Card3SubTitle*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card3SubTitle">
                  <b>{translations?.text}:</b>
                </label>
                <textarea id="primary_sec3Card3SubTitle" type="text" placeholder={`${translations?.text}`} name="primary_sec3Card3SubTitle" value={formSection3.primary.sec3Card3SubTitle} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card3SubTitle ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card3SubTitle*/}
            </div>
          </div>
        </article>
        <article>
          <div className="cms-form-flex">
            <div className="cms-form-flex-col">
              <p className="card-top">{translations?.card} 4:</p>
              <div className="cms-form-head-inner">
                <div className="images-card-header">
                  <span>{translations?.icon}</span>
                  <span className="ml-6">
                    <TooltipDash text={`${translations?.iconProportions} 1/1 (120x120)px`} acceptFormatText={true} />
                  </span>
                </div>
                <div className="upload-wrapper">
                  <ImageUpload activeTabKey={activeTabKey} translations={translations} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section3Icon4Primary" />
                </div>
              </div>
              {/*primary_sec3Card4Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card4Title">
                  <b>{translations?.title}:</b>
                </label>
                <textarea id="primary_sec3Card4Title" type="text" placeholder={`${translations?.title}`} name="primary_sec3Card4Title" value={formSection3.primary.sec3Card4Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card4Title ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card4Title*/}
              {/*primary_sec3Card4SubTitle*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card4SubTitle">
                  <b>{translations?.text}:</b>
                </label>
                <textarea id="primary_sec3Card4SubTitle" type="text" placeholder={`${translations?.text}`} name="primary_sec3Card4SubTitle" value={formSection3.primary.sec3Card4SubTitle} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card4SubTitle ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card4SubTitle*/}
            </div>
            <div className="cms-form-flex-col">
              <p className="card-top">{translations?.card} 5:</p>
              <div className="cms-form-head-inner">
                <div className="images-card-header">
                  <span>{translations?.icon}</span>
                  <span className="ml-6">
                    <TooltipDash text={`${translations?.iconProportions} 1/1 (120x120)px`} acceptFormatText={true} />
                  </span>
                </div>
                <div className="upload-wrapper">
                  <ImageUpload activeTabKey={activeTabKey} translations={translations} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section3Icon5Primary" />
                </div>
              </div>
              {/*primary_sec3Card5Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card5Title">
                  <b>{translations?.title}:</b>
                </label>
                <textarea id="primary_sec3Card5Title" type="text" placeholder={`${translations?.title}`} name="primary_sec3Card5Title" value={formSection3.primary.sec3Card5Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card5Title ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card5Title*/}
              {/*primary_sec3Card5SubTitle*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card5SubTitle">
                  <b>{translations?.text}:</b>
                </label>
                <textarea id="primary_sec3Card5SubTitle" type="text" placeholder={`${translations?.text}`} name="primary_sec3Card5SubTitle" value={formSection3.primary.sec3Card5SubTitle} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card5SubTitle ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card5SubTitle*/}
            </div>
            <div className="cms-form-flex-col">
              <p className="card-top">{translations?.card} 6:</p>
              <div className="cms-form-head-inner">
                <div className="images-card-header">
                  <span>{translations?.icon}</span>
                  <span className="ml-6">
                    <TooltipDash text={`${translations?.iconProportions} 1/1 (120x120)px`} acceptFormatText={true} />
                  </span>
                </div>
                <div className="upload-wrapper">
                  <ImageUpload activeTabKey={activeTabKey} translations={translations} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section3Icon6Primary" />
                </div>
              </div>
              {/*primary_sec3Card5Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card6Title">
                  <b>{translations?.title}:</b>
                </label>
                <textarea id="primary_sec3Card6Title" type="text" placeholder={`${translations?.title}`} name="primary_sec3Card6Title" value={formSection3.primary.sec3Card6Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card6Title ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card6Title*/}
              {/*primary_sec3Card6SubTitle*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="primary_sec3Card6SubTitle">
                  <b>{translations?.text}:</b>
                </label>
                <textarea id="primary_sec3Card6SubTitle" type="text" placeholder={`${translations?.text}`} name="primary_sec3Card6SubTitle" value={formSection3.primary.sec3Card6SubTitle} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card6SubTitle ? 'input-error-important' : ''}`} />
              </div>
              {/*primary_sec3Card6SubTitle*/}
            </div>
          </div>
        </article>
      </section>
    </>
  );
};
export default memo(Section3Cg);
