import { PERMISSIONS } from './permissions';
import { getGroceryPermissions } from '../middleware';
import { useTranslation } from 'react-i18next';

const groceryMenuItems = (props) => {
  const { groceryPermissions, handleLogout, onHide } = props;
  const { t } = useTranslation();

  const grocery = [
    {
      _id: '',
      label: t('data'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.venues}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/products.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readVenues) || getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
      onClick: function(value) {},
      children: [
        {
          _id: '',
          label: t('venues'),
          path: `/${PERMISSIONS.dashboard}/venues`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-facilities',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readVenues),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('attributes'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.attributes}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('globalAttributes'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.globalAttributes}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-global-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
      ],
    },
    {
      _id: '',
      label: t('requests'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.requests}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/details.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRequests),

      // visible: true,
      onClick: function(value) {},
      children: [
        {
          _id: '',
          label: t('editRequests'),
          path: `/${PERMISSIONS.dashboard}/requests`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-facilities',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRequests),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('nyamiRequests'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.nyamiRequests}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-facilities',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readNyamirequests),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('editHistory'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.editHistory}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRequests),
          onClick: () => {
            onHide(false);
          },
        },
      ],
    },
    // CMS Section
    {
      _id: '',
      label: 'CMS',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.cms}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/products.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
      onClick: function(value) {},
      children: [
        // {
        //   _id: '',
        //   label: t('hpSliders'),
        //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.slideshow}`,
        //   type: 'anchor',
        //   icon: '',
        //   expanded: false,
        //   protected: false,
        //   dataCy: 'sidebar-grocery-cms-sliders',
        //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
        //   onClick: () => {
        //     onHide(false);
        //   },
        // },
        {
          _id: '',
          label: t('hpSliders'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSSlider}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-sliders',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('homepage'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSHomepage}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-homepage',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('aboutUs'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSAboutUs}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-aboutus',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('forCompanies'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSForCompanies}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-for-companies',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('forPartners'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSForPartners}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-for-partners',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('exploreNetwork'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSExploreNetwork}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-explore-network',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('fitpassClub'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSClub}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-club',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('contact'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSContact}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-contact-page',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS),
          onClick: () => {
            onHide(false);
          },
        },
      ],
    },
    // CMS Section
    // {
    //   _id: '',
    //   label: t('gallery'),
    //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`,
    //   group: 'webshop',
    //   type: 'anchor',
    //   icon: '/assets/icons/gallery.svg',
    //   expanded: false,
    //   protected: false,
    //   dataCy: 'sidebar-grocery-gallery',
    //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readGallery),
    //   onClick: function() {
    //     onHide(false);
    //   },
    //   children: [],
    // },
    {
      _id: '',
      label: t('countryCodes'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.countryCodes}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/roles.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-country-codes',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCountryCodes),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    // CMS Section
    // {
    //   _id: '',
    //   label: 'Gallery',
    //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`,
    //   group: 'webshop',
    //   type: 'anchor',
    //   icon: '/assets/icons/gallery.svg',
    //   expanded: false,
    //   protected: false,
    //   dataCy: 'sidebar-grocery-gallery',
    //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readGallery),
    //   onClick: function() {
    //     onHide(false);
    //   },
    //   children: [],
    // },

    {
      _id: '',
      label: t('roles'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.roles}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/roles.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-roles',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: t('admins'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.admins}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/employees.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-admins',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAdmins),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: t('members'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.members}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/employees.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-members',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readMembers),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: t('activityLog'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.activity}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/activity.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-activity-log',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readActivity),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: t('profile'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.profile}`,
      group: 'account',
      type: 'anchor',
      icon: '/assets/icons/profile.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-profile',
      visible: true,
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: t('logout'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.login}`,
      group: 'account',
      type: 'button',
      icon: '/assets/icons/logout.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-logout',
      visible: true,
      onClick: () => {
        handleLogout();
      },
      children: [],
    },
  ];

  return grocery;
};

export { groceryMenuItems };
