import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section3Primary = ({ translations, activeTabKey, handleInputChangeFormSection3, formSection3, fcImages, fcImagesErrors, fcImagesPreview, setFcImages, formSection3Errors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*primary_sec3MainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="primary_sec3MainHeading">
            <b>{translations?.mainHeading}:</b>
          </label>
          <input id="primary_sec3MainHeading" type="text" placeholder="Glavni naslov" name="primary_sec3MainHeading" value={formSection3.primary.sec3MainHeading} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3MainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*primary_sec3MainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Card 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.card} 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>{translations?.icon}</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.iconProportions} 1/1 (120x120)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section3Image1Primary" />
            </div>
          </div>
          {/*primary_sec3Card1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Card1Title">
              <b>
                {translations?.card} 1 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec3Card1Title" type="text" placeholder={`${translations?.card} 1 ${translations?.title}`} name="primary_sec3Card1Title" value={formSection3.primary.sec3Card1Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec3Card1Title*/}
          {/*primary_sec3Card1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Card1Text">
              <b>
                {translations?.card} 1 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec3Card1Text" type="text" placeholder={`${translations?.card} 1 ${translations?.text}`} name="primary_sec3Card1Text" value={formSection3.primary.sec3Card1Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec3Card1Text*/}
        </div>
        {/*Card 1*/}
        {/*Card 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.card} 2:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>{translations?.icon}</span>
              <span className="ml-6">
                <TooltipDash text="Proporcije ikone 1/1 (120x120)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section3Image2Primary" />
            </div>
          </div>
          {/*primary_sec3Card2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Card2Title">
              <b>
                {translations?.card} 2 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec3Card2Title" type="text" placeholder={`${translations?.card} 2 ${translations?.title}`} name="primary_sec3Card2Title" value={formSection3.primary.sec3Card2Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec3Card2Title*/}
          {/*primary_sec3Card2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Card2Text">
              <b>
                {translations?.card} 2 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec3Card2Text" type="text" placeholder={`${translations?.card} 2 ${translations?.text}`} name="primary_sec3Card2Text" value={formSection3.primary.sec3Card2Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec3Card2Text*/}
        </div>
        {/*Card 2*/}
        {/*Card 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.card} 3:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>{translations?.icon}</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.iconProportions} 1/1 (120x120)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section3Image3Primary" />
            </div>
          </div>
          {/*primary_sec3Card3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Card3Title">
              <b>
                {translations?.card} 3 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec3Card3Title" type="text" placeholder={`${translations?.card} 3 ${translations?.title}`} name="primary_sec3Card3Title" value={formSection3.primary.sec3Card3Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec3Card3Title*/}
          {/*primary_sec3Card3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Card3Text">
              <b>
                {translations?.card} 3 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec3Card3Text" type="text" placeholder={`${translations?.card} 3 ${translations?.text}`} name="primary_sec3Card3Text" value={formSection3.primary.sec3Card3Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec3Card3Text*/}
        </div>
        {/*Card 3*/}
        {/*Card 4*/}
        <div className="cms-form-flex-col">
          <p className="card-top">{translations?.card} 4:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>{translations?.icon}</span>
              <span className="ml-6">
                <TooltipDash text={`${translations?.iconProportions} 1/1 (120x120)px`} acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload translations={translations} activeTabKey={activeTabKey} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section3Image4Primary" />
            </div>
          </div>
          {/*primary_sec3Card4Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Card4Title">
              <b>
                {translations?.card} 4 {translations?.title}:
              </b>
            </label>
            <textarea id="primary_sec3Card4Title" type="text" placeholder={`${translations?.card} 4 ${translations?.title}`} name="primary_sec3Card4Title" value={formSection3.primary.sec3Card4Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card4Title ? 'input-error-important' : ''}`} />
          </div>
          {/*primary_sec3Card4Title*/}
          {/*primary_sec3Card4Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="primary_sec3Card4Text">
              <b>
                {translations?.card} 4 {translations?.text}:
              </b>
            </label>
            <textarea id="primary_sec3Card4Text" type="text" placeholder={`${translations?.card} 4 ${translations?.text}`} name="primary_sec3Card4Text" value={formSection3.primary.sec3Card4Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.primary.sec3Card4Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*primary_sec3Card4Text*/}
        </div>
        {/*Card 4*/}
      </div>
    </>
  );
};
export default Section3Primary;
